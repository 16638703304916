import { Component, Input, Injectable } from  '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'core-modal-alert',
  templateUrl: './modal-alert.component.html'
})
@Injectable({
  providedIn: 'root'
})

export class CoreModalAlertComponent {
  @Input() message;
  @Input() title;
  @Input() ok;
  constructor(public activeModal: NgbActiveModal) {}
}
