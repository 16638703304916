import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  CoreAttachmentPipe,
  CoreChartBaseComponent,
  CoreChartGaugeComponent,
  CoreChartIndexComponent,
  CoreChartService,
  CoreChartTableComponent,
  CoreChartTrendComponent,
  CoreCurrencyPipe,
  CoreDatePipe,
  CoreEnumPipe,
  CoreFilterPipe,
  CoreLineBreakPipe,
  CoreHttpInterceptorAuth,
  CoreHttpInterceptorError,
  CoreHttpInterceptorPaginator,
  CoreLabelPipe,
  CoreLoaderDirective,
  CoreMessageService,
  CoreModalAlertComponent,
  CoreModalConfirmComponent,
  CoreChipComponent,
  CoreModalService,
  CoreMonthDatePickerComponent,
  CorePaginationNavComponent,
  CoreStorageService,
  CoreUploadComponent,
  CoreUtilsService,
  CoreWeekDatePickerComponent
} from './';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgChartsModule } from 'ng2-charts';
import { TranslateModule } from '@ngx-translate/core';

import { TrendModule } from 'ngx-trend';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreSafePipe } from "./pipe/safe.pipe";


@NgModule({
  providers: [
    CoreUtilsService,
    CoreModalService,
    CoreMessageService,
    CoreChartService,
    CoreStorageService,
    CurrencyPipe,
    CoreCurrencyPipe,
    CoreAttachmentPipe,
    CoreLabelPipe,
    CoreEnumPipe,
    CoreDatePipe,
    CoreFilterPipe,
    CoreSafePipe,
    CoreLineBreakPipe,
    { provide: HTTP_INTERCEPTORS, useClass: CoreHttpInterceptorError, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CoreHttpInterceptorPaginator, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CoreHttpInterceptorAuth, multi: true }
  ],
  declarations: [
    CorePaginationNavComponent,
    CoreModalAlertComponent,
    CoreModalConfirmComponent,
    CoreChartGaugeComponent,
    CoreChartIndexComponent,
    CoreChartTableComponent,
    CoreChartTrendComponent,
    CoreChartBaseComponent,
    CoreUploadComponent,
    CoreMonthDatePickerComponent,
    CoreWeekDatePickerComponent,
    CoreChipComponent,
    CoreCurrencyPipe,
    CoreLabelPipe,
    CoreEnumPipe,
    CoreDatePipe,
    CoreAttachmentPipe,
    CoreFilterPipe,
    CoreSafePipe,
    CoreLineBreakPipe,
    CoreLoaderDirective
  ],
  exports: [
    CorePaginationNavComponent,
    CoreModalAlertComponent,
    CoreModalConfirmComponent,
    CoreChartGaugeComponent,
    CoreChartIndexComponent,
    CoreChartTableComponent,
    CoreChartTrendComponent,
    CoreChartBaseComponent,
    CoreUploadComponent,
    CoreMonthDatePickerComponent,
    CoreWeekDatePickerComponent,
    CoreChipComponent,
    CoreCurrencyPipe,
    CoreLabelPipe,
    CoreEnumPipe,
    CoreDatePipe,
    CoreFilterPipe,
    CoreAttachmentPipe,
    CoreSafePipe,
    CoreLineBreakPipe,
    CoreLoaderDirective,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  imports: [
    NgbPaginationModule,
    NgProgressModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    CommonModule,
    NgxGaugeModule,
    NgChartsModule,
    NgbModule,
    TrendModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class CoreModule {

}
