import { Component, OnInit, Output, Input } from '@angular/core';

import { CoreMessageService, CoreStorage, CoreStorageService } from '../core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-header',
  templateUrl: './layout.header.component.html'
})
export class LayoutHeaderComponent implements OnInit {
  constructor(
    public message: CoreMessageService,
    public storage: CoreStorageService,
    private translate: TranslateService,
  ) {}

  public ngOnInit() {
    this.identity = this.storage.retrieve('identity') || {};
    if(this.identity && this.identity.user && this.identity.user.language ) {
      this.changeLanguage(this.identity.user.language)
    }
    console.log("ID", this.identity)
    if(this.identity && this.identity.role) {
      // this.guideLink = "/assets/i18n/guide/it/" + this.identity.role.replace("ROLE_", "").toLowerCase() + ".pdf";
    }

    this.message.get('menu.opened').subscribe(val => {
      this.opened = val
    })
  }

  guideLink = "";
  lang = ""

 identity
  opened: boolean = false;

  switchMenu() {
    this.opened = !this.opened;
    this.message.change('menu.opened', this.opened);
  }

  changeLanguage(lang) {
    this.lang = lang;
    this.message.change('language.changed', lang);
  }
}
