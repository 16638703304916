import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';


@Pipe({
 name: 'CoreLabelPipe'
})

@Injectable({
  providedIn: 'root'
})
export class CoreLabelPipe implements PipeTransform {
  transform(namespace: any, id: string, def?: string ): any {

    var list = [];
    if(environment.APP_CONFIG.labels && environment.APP_CONFIG.labels[namespace]) {
      list = environment.APP_CONFIG.labels[namespace];
    }
    else if(Array.isArray(namespace)){
      list = namespace;
    }


    if(list.length) {
      if(id) {
        for(var i = 0; i < list.length; i++) {
          if(list[i].id == id) {
            return list[i].label || list[i].name || list[i].title;
          }
        }
        return def || "";
      }
      else {
        if(def == "*") {
          return list;
        }
        else {
          return def || "";
        }
        // return list;
      }
    }
    return  def || "";
  }
}
