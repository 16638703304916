import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgProgress } from '@ngx-progressbar/core';

import {
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbDate
} from '@ng-bootstrap/ng-bootstrap';

import {
  chartPeriodArray,
  entityCategoryArray,
  StatisticsService
} from './';

import {
  CoreMessageService,
  CoreModalService,
  CoreStorage,
  CoreStorageService,
  CoreUtilsService,
  MomentDateFormatter
} from '../core';

import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isEqual,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
  isDate,
} from 'date-fns';

import { de, enGB, es, it, pl } from 'date-fns/locale';

import * as c3 from 'c3';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import * as moment from 'moment';
import { DeviceService } from '../device';

import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

import { AdviceService } from '../advice';
import { Subscription } from 'rxjs';

interface Range {
  start: Date;
  end: Date;
  label: string;
}

@Component({
  selector: 'statistics-view',
  templateUrl: './statistics-view.component.html',
  host: {
    '(document:mousedown)': 'handlePickerClosing($event)',
  },
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: MomentDateFormatter
    }
  ]
})
/**
 * DOC
 * Nomenclatura
 *  Periodo:
 *    Unità di durata che identitfica un grafico
 *    es: 'daily', 'weekly', 'monthly', 'annual', 'last24Hours', 'last7Days', 'last30Days', 'last12Months'
 *  Intervallo/Range:
 *    Intervallo di valori DA ... A ..., questo è strettamente collegato ad data d'inzio e periodo.
 *    Con range si riferisce spesso all'oggetto { start, end, label }
 *  Risoluzione:
 *    Unità minima di tempo visualizzata su grafico.
 *    es: ore, giorni, mesi, ecc...
 *    Questa è caratteristica di ogni periodo
 *  Aggregato:
 *    File che raccoglie l'informazione elaborata relativa ad un range di tempo.
 *    Mentre il file puntuale è formato da un oggetto contenete array, l'aggregato è formato da un array di oggetti
 *
 * Processo costruzione dati
*    La procedura dipende dal periodo che possiamo distinguere tra:
 *    - PUNTUALI:
 *      Che si riferiscono in maniera puntuale ad un singolo elemento (e file)
 *      es. 'daily', 'last24Hours', 'last30Days', 'last12Months'
 *    - RANGE
 *      Ri riferiscono ad un range di elementi che possono essere presenti in uno o più file
 *
 * Formato dati su S3
 *  Link doc: https://corleycloud.atlassian.net/l/c/CChcaZ17
 */
export class StatisticsViewComponent implements OnInit, OnDestroy {

  public data: any;

  public chartPeriod: Array<typeof chartPeriodArray[number]> = chartPeriodArray;
  public selectedChartPeriod: typeof chartPeriodArray[number];

  public isNoRangePeriod: boolean;
  public selectedChartRange: Range;
  public selectedChartRangeComparison: Range;

  public selectedGroup: any;
  public selectedRoom: any;
  public selectedAirMachine: any;

  public translations: any;

  public adviceLoading = false;
  public adviceLoaded = false;
  public adviceList: any[];

  public identity;

  @Input() private deviceId: any;

  private groupChartA: any; // Tempo attivazione zona per Heating/Cooling [istogramma]
  private groupChartB: any; // Tempo Home/Away/Vacanza/Off [torta]
  private groupChartC: any; // Graduatoria locale sfavorito con % di copertura tempo [torta]
  private groupChartD: any; // Temperatura esterna

  private roomChartA: any; // Temperatura minima/massima [linee]
  private roomChartB: any; // Umidità [linee]
  private roomChartC: any; // Tempo attivazione testina Heating/Cooling [istogramma]

  private airMachineChartA: any; // Tempo macchine attiva in Heating/Cooling [istogramma]
  private airMachineChartB: any; // Tempo in modalità Rinnovo/DEU/INT [istogramma]

  private params: any;

  @ViewChild('d') picker: any;
  @ViewChild('dc') pickerComparison: any;

  private colorPalette = [
    '#ABDEE6', '#CBAACB', '#FFCCB6', '#FCB9AA',
    '#F3B0C3', '#C6DBDA', '#FED7C3', '#F6EAC2',
    '#FF968A', '#FFAEA5', '#FFC5BF', '#A2E1DB',
    '#FFC8A2', '#8FCACA', '#CCE2CB', '#FFD8BE',
    '#B6CFB6', '#97C1A9', '#55CBCD',
    '#E8E8EB', '#CCCDC6', '#ACADA8', '#746D69',
  ];
  private coldColor = this.colorPalette[18];
  private hotColor = this.colorPalette[9];
  private greyColor = this.colorPalette[24];
  private comparisonDeltaBrightness = 50;

  isMobile = false; // flag per la gestione della vista mobile
  subscription: Subscription = new Subscription(); // registrazione delle subscription da cancellare al destroy

  constructor(
    private service: StatisticsService,
    private deviceService: DeviceService,
    private adviceService: AdviceService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: CoreStorageService,
    private progress: NgProgress,
    private modal: CoreModalService,
    private message: CoreMessageService,
    private utils: CoreUtilsService,
    private translate: TranslateService,
    public config: NgbDatepickerConfig
  ) {
    const yesterday = moment().subtract(1, 'days');
    config.maxDate = new NgbDate(
      yesterday.year(), yesterday.month() + 1, yesterday.date()
    );

    config.outsideDays = 'hidden';

    // Questa subscription è in ascolto a un evento presente in application.ts, che controlla i breakpoints e notifica il layout dell'app
    // In caso sia di tipo 'mobile', engono aggiornate le colonne della lista
    this.subscription.add(
      this.message.get('layout.changed').subscribe((m: any) => {
        this.isMobile = m === 'mobile';
      })
    );
  }

  public ngOnInit(): void {
    this.identity = this.storage.retrieve('identity') || {};
    this.fetchTranslations();
    this.translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.fetchTranslations();
      this.drawGroupCharts();
      this.drawRoomCharts();
      this.drawAirMachineCharts();
    });

    this.route.params.subscribe(params => {
      this.deviceId = params.id;
    });
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });

    this.initializeDeviceData()
      .then(() => {
        if (this.params.chart && (chartPeriodArray.indexOf(this.params.chart) !== -1)) {
          this.selectedChartPeriod = this.params.chart;
        } else {
          this.selectedChartPeriod = this.chartPeriod[0];
        }
        this.isNoRangePeriod = this.isNoRangePeriodFn(this.selectedChartPeriod);
      })
      .then(() => this.initChartRange(this.selectedChartPeriod, true))
      .then(() => {
        this.initAdvices();
        setTimeout(() => {
          this.drawGroupCharts();
          this.drawRoomCharts();
          this.drawAirMachineCharts();
        }, 500);
      });


    this.message.get('rangeSelection').subscribe(
      model => {
        if (!model) {
          return;
        }
        this.selectedChartRange = model;
        this.changeRange();
      }
    );

    this.message.get('rangeSelectionComparison').subscribe(
      model => {
        if (!model) {
          return;
        }

        this.selectedChartRangeComparison = model;
        this.changeRangeComparison();
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  removeComparison() {
    this.selectedChartRangeComparison = null;
    this.changeRangeComparison();
  }

  public formatDayRange(event) {
    const { year, month, day } = event;
    const date = moment({ year, month: (month - 1), day });
    console.log(event, date);
    this.selectedChartRange = {
      label: date.format('DD/MM/YYYY'),
      start: date.toDate(),
      end: null,
    };

    this.changeRange();
  }

  public formatDayRangeComparison(event) {
    const { year, month, day } = event;
    const date = moment({ year, month: (month - 1), day });
    this.selectedChartRangeComparison = {
      label: date.format('DD/MM/YYYY'),
      start: date.toDate(),
      end: null,
    };

    this.changeRangeComparison();
  }

  public selectGroup(): void {
    this.selectedRoom = (this.selectedGroup.rooms || [])[0];
    this.selectedAirMachine = (this.selectedGroup.airMachines || [])[0];
    this.drawGroupCharts();
    this.drawRoomCharts();
    this.drawAirMachineCharts();
  }

  public selectRoom(): void {
    this.drawRoomCharts();
  }

  public selectAirMachine(): void {
    this.drawAirMachineCharts();
  }

  public changePeriod(): void {
    this.isNoRangePeriod = this.isNoRangePeriodFn(this.selectedChartPeriod);
    this.initChartRange(this.selectedChartPeriod);
    this.drawGroupCharts();
    this.drawRoomCharts();
    this.drawAirMachineCharts();
  }

  public changeRange(): void {
    this.message.change('resetChartComparison', true);
    this.selectedChartRangeComparison = null;
    this.drawGroupCharts();
    this.drawRoomCharts();
    this.drawAirMachineCharts();
  }

  public changeRangeComparison(): void {
    if (this.theComparisonIsActive()) {
      this.decorateGroupChartsWithComparisonData();
      this.decorateRoomChartsWithComparisonData();
      this.decorateAirMachineChartsWithComparisonData();
    } else {
      this.unloadChartsComparisonData();
    }
  }

  public export(category: typeof entityCategoryArray[number]): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      showLabels: true,
      decimalseparator: '.',
    };

    const rangeLabel = this.selectedChartRange ? `-${this.selectedChartRange.label}` : '';

    switch (category) {
      case 'groups':
        const dataGroupChartA = this.normalizeDataFromChart(this.groupChartA);
        const dataGroupChartB = this.normalizeDataFromChart(this.groupChartB, false);
        const dataGroupChartC = this.normalizeDataFromChart(this.groupChartC, false);
        const dataGroupChartD = this.normalizeDataFromChart(this.groupChartD);

        new AngularCsv(dataGroupChartA,
          `${this.selectedGroup.name}-${this.selectedChartPeriod}${rangeLabel}-active-H/A-C_not active`,
          Object.assign(options, { headers: Object.keys(dataGroupChartA[0]) }));

        new AngularCsv(dataGroupChartD,
          `${this.selectedGroup.name}-${this.selectedChartPeriod}${rangeLabel}-external_temperature`,
          Object.assign(options, { headers: Object.keys(dataGroupChartD[0]) }));

        if (!this.theComparisonIsActive()) {
          new AngularCsv(dataGroupChartB,
            `${this.selectedGroup.name}-${this.selectedChartPeriod}${rangeLabel}-mode`, Object.assign(options,
              { headers: Object.keys(dataGroupChartB[0]) }));

          new AngularCsv(dataGroupChartC,
            `${this.selectedGroup.name}-${this.selectedChartPeriod}${rangeLabel}-area_rank`,
            Object.assign(options, { headers: Object.keys(dataGroupChartC[0]) }));
        }

        break;
      case 'rooms':
        const dataRoomChartA = this.normalizeDataFromChart(this.roomChartA);
        const dataRoomChartB = this.normalizeDataFromChart(this.roomChartB);
        const dataRoomChartC = this.normalizeDataFromChart(this.roomChartC);

        new AngularCsv(dataRoomChartA,
          `${this.selectedRoom.name}-${this.selectedChartPeriod}${rangeLabel}-temperatures`,
          Object.assign(options, { headers: Object.keys(dataRoomChartA[0]) }));
        new AngularCsv(dataRoomChartB,
          `${this.selectedRoom.name}-${this.selectedChartPeriod}${rangeLabel}-humidity`,
          Object.assign(options, { headers: Object.keys(dataRoomChartB[0]) }));
        new AngularCsv(dataRoomChartC,
          `${this.selectedRoom.name}-${this.selectedChartPeriod}${rangeLabel}-active-H_A-C_not active`,
          Object.assign(options, { headers: Object.keys(dataRoomChartC[0]) }));
        break;
      case 'air-machines':
        const dataAirMachineChartA = this.normalizeDataFromChart(this.airMachineChartA);
        const dataAirMachineChartB = this.normalizeDataFromChart(this.airMachineChartB);
        new AngularCsv(dataAirMachineChartA,
          `${this.selectedAirMachine.name}-${this.selectedChartPeriod}${rangeLabel}-heating-cooling_mode`,
          Object.assign(options, { headers: Object.keys(dataAirMachineChartA[0]) }));
        new AngularCsv(dataAirMachineChartB,
          `${this.selectedAirMachine.name}-${this.selectedChartPeriod}${rangeLabel}-rinnovo_deumidificazione_integrazione_standby`,
          Object.assign(options, { headers: Object.keys(dataAirMachineChartB[0]) }));
        break;
    }
  }

  public goToTips(): void {
    this.router.navigate(['main/dashboard'], { queryParams: { show: 'advice' } });
  }

  private drawGroupCharts(): void {
    if (this.selectedGroup) {
      this.fetchChartData(this.selectedChartRange, this.selectedGroup.key, 'groups')
        .then(data => {
          const dataChartA = this.utils.pick(['x', 'heating', 'cooling', 'off'], data);
          const dataChartB = this.utils.pick(['x', 'home', 'away', 'vacanza', 'modeOff'], data);
          const dataChartD = this.utils.pick(['x', 'externalTemp'], data);

          const areaRankData = {};
          for (const areaId in data.areaRank) {
            const label = this.searchRoomNameById(this.data, areaId);
            areaRankData[label] = data.areaRank[areaId];
            areaRankData['x'] = data.x;
          }
          const dataChartC = areaRankData;

          this.generateGroupChartA(dataChartA);
          this.generateGroupChartB(dataChartB);
          this.generateGroupChartC(dataChartC);
          this.generateGroupChartD(dataChartD);

          if (this.theComparisonIsActive()) {
            // Il timeout serve ad essere sicuri che il grafico abbia già disegnato dei dati e dunque esista un asse x
            setTimeout(() => {
              this.decorateGroupChartsWithComparisonData();
            }, 1500);
          }
        });
    }
  }

  private drawRoomCharts(): void {
    console.log(this.selectedRoom);

    if (this.selectedRoom) {
      this.fetchChartData(this.selectedChartRange, this.selectedRoom.key, 'rooms')
        .then(data => this.normalizeIfArray(data))
        .then(data => {
          let chartAData = ['x', 'x2', 'tempMin', 'tempMax', 'tempAverage', 'setpoint'];
          if ('ROLE_PARENT' === this.identity.role || 'ROLE_USER' === this.identity.role) {
            chartAData = ['x', 'x2', 'tempAverage'];
          }
          const dataChartA = this.utils.pick(chartAData, data);
          const dataChartB = this.utils.pick(['x', 'humidity'], data);
          const dataChartC = this.utils.pick(['x', 'heating', 'cooling', 'off'], data);

          this.generateRoomChartA(dataChartA);
          this.generateRoomChartB(dataChartB);
          this.generateRoomChartC(dataChartC);

          if (this.theComparisonIsActive()) {
            // Il timeout serve ad essere sicuri che il grafico abbia già disegnato dei dati e dunque esista un asse x
            setTimeout(() => {
              this.decorateRoomChartsWithComparisonData();
            }, 1500);
          }
        });
    }
  }

  private drawAirMachineCharts(): void {
    if (this.selectedAirMachine) {
      this.fetchChartData(this.selectedChartRange, this.selectedAirMachine.key, 'air-machines')
        .then(data => this.normalizeIfArray(data))
        .then(data => {
          const dataChartA = this.utils.pick(['x', 'heating', 'cooling'], data);
          const dataChartB = this.utils.pick(['x', 'rinnovo', 'deu', 'int', 'off'], data);

          this.generateAirMachineChartA(dataChartA);
          this.generateAirMachineChartB(dataChartB);

          if (this.theComparisonIsActive()) {
            // Il timeout serve ad essere sicuri che il grafico abbia già disegnato dei dati e dunque esista un asse x
            setTimeout(() => {
              this.decorateAirMachineChartsWithComparisonData();
            }, 1500);
          }
        });
    }
  }

  private decorateGroupChartsWithComparisonData(): void {
    if (this.selectedGroup) {
      this.fetchChartData(this.selectedChartRangeComparison, this.selectedGroup.key, 'groups')
        .then(data => {
          let dataChartA = this.utils.pick(['x', 'heating', 'cooling', 'off'], data);
          dataChartA = this.utils.renameObjKeys(['x', 'heating', 'cooling', 'off'],
            ['comp-x', 'comp-heating', 'comp-cooling', 'comp-off'], dataChartA);
          let dataChartD = this.utils.pick(['x', 'externalTemp'], data);
          dataChartD = this.utils.renameObjKeys(['x', 'externalTemp'], ['comp-x', 'comp-externalTemp'], dataChartD);

          const progressiveLoadingFnArrDataChartA = this.generateProgressiveLoadingDataFn(this.groupChartA, dataChartA, 0);
          this.utils.promiseSerial(progressiveLoadingFnArrDataChartA);
          const progressiveLoadingFnArrDataChartD = this.generateProgressiveLoadingDataFn(this.groupChartD, dataChartD, 0);
          this.utils.promiseSerial(progressiveLoadingFnArrDataChartD);

          this.groupChartA.data.names({
            'heating': `${this.translations['statistics.chart-heating']} ${this.selectedChartRange.label}`,
            'cooling': `${this.translations['statistics.chart-cooling']} ${this.selectedChartRange.label}`,
            'off': `${this.translations['statistics.chart-off']} ${this.selectedChartRange.label}`,
            'comp-heating': `${this.translations['statistics.chart-heating']} ${this.selectedChartRangeComparison.label}`,
            'comp-cooling': `${this.translations['statistics.chart-cooling']} ${this.selectedChartRangeComparison.label}`,
            'comp-off': `${this.translations['statistics.chart-off']} ${this.selectedChartRangeComparison.label}`,
          });

          this.groupChartD.data.names({
            'externalTemp': `${this.translations['statistics.chart-externalTemp']} ${this.selectedChartRange.label}`,
            'comp-externalTemp': `${this.translations['statistics.chart-externalTemp']} ${this.selectedChartRangeComparison.label}`,
          });
        });
    }
  }

  private decorateRoomChartsWithComparisonData(): void {
    if (this.selectedRoom) {
      this.fetchChartData(this.selectedChartRangeComparison, this.selectedRoom.key, 'rooms')
        .then(data => {
          let dataChartA = this.utils.pick(['x', 'tempAverage'], data);
          dataChartA = this.utils.renameObjKeys(
            ['x', 'tempAverage'], ['comp-x', 'comp-tempAverage'], dataChartA);
          let dataChartB = this.utils.pick(['x', 'humidity'], data);
          dataChartB = this.utils.renameObjKeys(['x', 'humidity'], ['comp-x', 'comp-humidity'], dataChartB);
          let dataChartC = this.utils.pick(['x', 'heating', 'cooling', 'off'], data);
          dataChartC = this.utils.renameObjKeys(['x', 'heating', 'cooling', 'off'],
          ['comp-x', 'comp-heating', 'comp-cooling', 'comp-off'], dataChartC);

          const progressiveLoadingFnArrDataChartA = this.generateProgressiveLoadingDataFn(this.roomChartA, dataChartA, 0);
          this.utils.promiseSerial(progressiveLoadingFnArrDataChartA);
          const progressiveLoadingFnArrDataChartB = this.generateProgressiveLoadingDataFn(this.roomChartB, dataChartB, 0);
          this.utils.promiseSerial(progressiveLoadingFnArrDataChartB);
          const progressiveLoadingFnArrDataChartC = this.generateProgressiveLoadingDataFn(this.roomChartC, dataChartC, 0);
          this.utils.promiseSerial(progressiveLoadingFnArrDataChartC);

          this.roomChartA.data.names({
            'tempMin': `${this.translations['statistics.chart-tempMin']} ${this.selectedChartRange.label}`,
            'tempMax': `${this.translations['statistics.chart-tempMax']} ${this.selectedChartRange.label}`,
            'tempAverage': `${this.translations['statistics.chart-tempAverage']} ${this.selectedChartRange.label}`,
            'setpoint': `${this.translations['statistics.chart-setpoint']} ${this.selectedChartRange.label}`,
            'comp-tempMin': `${this.translations['statistics.chart-tempMin']} ${this.selectedChartRangeComparison.label}`,
            'comp-tempMax': `${this.translations['statistics.chart-tempMax']} ${this.selectedChartRangeComparison.label}`,
            'comp-tempAverage': `${this.translations['statistics.chart-tempAverage']} ${this.selectedChartRangeComparison.label}`,
            'comp-setpoint': `${this.translations['statistics.chart-setpoint']} ${this.selectedChartRangeComparison.label}`,
          });
          this.roomChartA.hide(['tempMin', 'tempMax', 'comp-tempMin', 'comp-tempMax']);


          console.log(this.roomChartA);

          this.roomChartB.data.names({
            'humidity': `${this.translations['statistics.chart-humidity']} ${this.selectedChartRange.label}`,
            'comp-humidity': `${this.translations['statistics.chart-humidity']} ${this.selectedChartRangeComparison.label}`,
          });
          this.roomChartC.data.names({
            'heating': `${this.translations['statistics.chart-heating']} ${this.selectedChartRange.label}`,
            'cooling': `${this.translations['statistics.chart-cooling']} ${this.selectedChartRange.label}`,
            'off': `${this.translations['statistics.chart-off']} ${this.selectedChartRange.label}`,
            'comp-heating': `${this.translations['statistics.chart-heating']} ${this.selectedChartRangeComparison.label}`,
            'comp-cooling': `${this.translations['statistics.chart-cooling']} ${this.selectedChartRangeComparison.label}`,
            'comp-off': `${this.translations['statistics.chart-off']} ${this.selectedChartRangeComparison.label}`,
          });
        });
    }
  }

  private decorateAirMachineChartsWithComparisonData(): void {
    if (this.selectedAirMachine) {
      this.fetchChartData(this.selectedChartRangeComparison, this.selectedAirMachine.key, 'air-machines')
        .then(data => {
          let dataChartA = this.utils.pick(['x', 'heating', 'cooling'], data);
          dataChartA = this.utils.renameObjKeys(['x', 'heating', 'cooling'], ['comp-x', 'comp-heating', 'comp-cooling'], dataChartA);
          let dataChartB = this.utils.pick(['x', 'rinnovo', 'deu', 'int', 'off'], data);
          dataChartB = this.utils.renameObjKeys(['x', 'rinnovo', 'deu', 'int', 'off'], ['comp-x', 'comp-rinnovo', 'comp-deu', 'comp-int', 'comp-off'], dataChartB);

          const progressiveLoadingFnArrDataChartA = this.generateProgressiveLoadingDataFn(this.airMachineChartA, dataChartA);
          this.utils.promiseSerial(progressiveLoadingFnArrDataChartA);
          const progressiveLoadingFnArrDataChartB = this.generateProgressiveLoadingDataFn(this.airMachineChartB, dataChartB);
          this.utils.promiseSerial(progressiveLoadingFnArrDataChartB);

          this.airMachineChartA.data.names({
            'heating': `${this.translations['statistics.chart-heating']} ${this.selectedChartRange.label}`,
            'cooling': `${this.translations['statistics.chart-cooling']} ${this.selectedChartRange.label}`,
            'comp-heating': `${this.translations['statistics.chart-heating']} ${this.selectedChartRangeComparison.label}`,
            'comp-cooling': `${this.translations['statistics.chart-cooling']} ${this.selectedChartRangeComparison.label}`,
          });
          this.airMachineChartB.data.names({
            'rinnovo': `${this.translations['statistics.chart-rinnovo']} ${this.selectedChartRange.label}`,
            'deu': `${this.translations['statistics.chart-deu']} ${this.selectedChartRange.label}`,
            'int': `${this.translations['statistics.chart-int']} ${this.selectedChartRange.label}`,
            'off': `${this.translations['statistics.chart-off']} ${this.selectedChartRange.label}`,
            'comp-rinnovo': `${this.translations['statistics.chart-rinnovo']} ${this.selectedChartRangeComparison.label}`,
            'comp-deu': `${this.translations['statistics.chart-deu']} ${this.selectedChartRangeComparison.label}`,
            'comp-int': `${this.translations['statistics.chart-int']} ${this.selectedChartRangeComparison.label}`,
            'comp-off': `${this.translations['statistics.chart-off']} ${this.selectedChartRangeComparison.label}`,
          });
        });
    }
  }

  private unloadChartsComparisonData(): void {
    this.groupChartA.unload({ ids: ['comp-x', 'comp-heating', 'comp-cooling', 'comp-off'] });
    this.groupChartD.unload({ ids: ['comp-x', 'comp-externalTemp'] });
    this.roomChartA.unload({ ids: ['comp-x', 'comp-tempMin', 'comp-tempMax', 'comp-setpoint', 'comp-tempAverage'] });
    this.roomChartB.unload({ ids: ['comp-x', 'comp-humidity'] });
    this.roomChartC.unload({ ids: ['comp-x', 'comp-heating', 'comp-cooling', 'comp-off'] });
    this.airMachineChartA.unload({ ids: ['comp-x', 'comp-heating', 'comp-cooling'] });
    this.airMachineChartB.unload({ ids: ['comp-x', 'comp-rinnovo', 'comp-deu', 'comp-int', 'comp-off'] });

    this.roomChartA.show(['tempMin', 'tempMax']);

    this.groupChartA.data.names({
      'heating': this.translations['statistics.chart-heating'],
      'cooling': this.translations['statistics.chart-cooling'],
      'off': this.translations['statistics.chart-off'],
      'comp-heating': this.translations['statistics.chart-heating'],
      'comp-cooling': this.translations['statistics.chart-cooling'],
      'comp-off': this.translations['statistics.chart-off'],
    });
    this.groupChartD.data.names({
      'externalTemp': this.translations['statistics.chart-externalTemp'],
      'comp-externalTemp': this.translations['statistics.chart-externalTemp'],
    });
    this.roomChartA.data.names({
      'tempMin': this.translations['statistics.chart-tempMin'],
      'tempMax': this.translations['statistics.chart-tempMax'],
      'tempAverage': this.translations['statistics.chart-tempAverage'],
      'setpoint': this.translations['statistics.chart-setpoint'],
      'comp-tempMin': this.translations['statistics.chart-tempMin'],
      'comp-tempMax': this.translations['statistics.chart-tempMin'],
      'comp-tempAverage': this.translations['statistics.chart-tempAverage'],
      'comp-setpoint': this.translations['statistics.chart-setpoint'],
    });
    this.roomChartB.data.names({
      'humidity': this.translations['statistics.chart-humidity'],
      'comp-humidity': this.translations['statistics.chart-humidity'],
    });
    this.roomChartC.data.names({
      'heating': this.translations['statistics.chart-heating'],
      'cooling': this.translations['statistics.chart-cooling'],
      'off': this.translations['statistics.chart-off'],
      'comp-heating': this.translations['statistics.chart-heating'],
      'comp-cooling': this.translations['statistics.chart-cooling'],
      'comp-off': this.translations['statistics.chart-off'],
    });
    this.airMachineChartA.data.names({
      'heating': this.translations['statistics.chart-heating'],
      'cooling': this.translations['statistics.chart-cooling'],
      'comp-heating': this.translations['statistics.chart-heating'],
      'comp-cooling': this.translations['statistics.chart-cooling'],
    });
    this.airMachineChartB.data.names({
      'rinnovo': this.translations['statistics.chart-rinnovo'],
      'deu': this.translations['statistics.chart-deu'],
      'int': this.translations['statistics.chart-int'],
      'off': this.translations['statistics.chart-off'],
      'comp-rinnovo': this.translations['statistics.chart-rinnovo'],
      'comp-deu': this.translations['statistics.chart-deu'],
      'comp-int': this.translations['statistics.chart-int'],
      'comp-off': this.translations['statistics.chart-off'],
    });
  }

  private fetchChartData(selectedChartRange: Range, entityId: string, category: typeof entityCategoryArray[number]): Promise<any> {
    let range;
    if (this.isNoRangePeriod) {
      range = { start: new Date(), end: null };
    } else {
      range = this.utils.pick(['start', 'end'], selectedChartRange);
    }

    const deviceId = this.data.id;
    return this.service.fetchDataByPeriod(deviceId, entityId, category, this.selectedChartPeriod, range)
      .then(data => {
        data = this.normalizeIfArray(data);

        if (data.areaRank) {
          data.areaRank = this.handleAreaRank(data.areaRank);
          data.areaRank = this.normalizeIfArray(data.areaRank);
        }

        return data;
      });
  }

  private handleAreaRank(areaRank: any): any {
    if (Array.isArray(areaRank)) {
      const areaRankKeys = areaRank.reduce((acc, item) => {
        Object.keys(item || {}).map(el => {
          if (!acc.includes(el)) {
            acc.push(el);
          }
        });
        return acc;
      }, []);
      areaRank = areaRank.map(item => {
        const completeItem = {};
        areaRankKeys.map(key => completeItem[key] = ((item || {}).hasOwnProperty(key) ? item[key] : null));
        return completeItem;
      });
    }

    return areaRank;
  }

  private initializeDeviceData(): Promise<any> {
    return this.deviceService.get(this.deviceId)
      .then(data => ((data || {}).models || [])[0])
      .then(data => {
        const device = this.getDeviceFromModel(data);
        if (device) {
          this.data = device;

          this.selectedGroup = (this.data.groups || [])[0];
          this.selectedRoom = (this.selectedGroup.rooms || [])[0];
          this.selectedAirMachine = (this.selectedGroup.airMachines || [])[0];
        }
      });
  }

  private initChartRange(period: typeof chartPeriodArray[number], pageInit: boolean = false): void {
    // L'izializzazione da query param deve avvenire solo all'inizializzazione della pagina
    let todayObj;
    if (this.params.reference && pageInit) {
      todayObj = new Date(this.params.reference);
    } else {
      todayObj = new Date();
    }

    let selectedInitialDate;
    switch (period) {
      case 'daily':
        const referenceDay = subDays(todayObj, 1);
        selectedInitialDate = {
          label: format(referenceDay, 'dd/MM/yyyy'),
          start: referenceDay,
          end: null,
        };
        break;
      case 'weekly':
        const weekStart = startOfWeek(todayObj, { weekStartsOn: 1 });
        const referenceWeek = subWeeks(weekStart, 1);
        selectedInitialDate = {
          label: format(referenceWeek, 'dd/MM/yyyy'),
          start: referenceWeek,
          end: endOfWeek(referenceWeek, { weekStartsOn: 1 }),
        };
        break;
      case 'monthly':
        const monthStart = startOfMonth(todayObj);
        const referenceMonth = subMonths(monthStart, 1);
        selectedInitialDate = {
          label: format(referenceMonth, 'MMM yyyy', { locale: this.getDateFnsUserLocale() }),
          start: referenceMonth,
          end: endOfMonth(referenceMonth),
        };
        break;
      case 'annual':
        const yearStart = startOfYear(todayObj);
        const referenceYear = subYears(yearStart, 1);
        selectedInitialDate = {
          label: format(referenceYear, 'yyyy', { locale: this.getDateFnsUserLocale() }),
          start: referenceYear,
          end: endOfYear(referenceYear),
        };
        break;
      case 'last24Hours':
      case 'last7Days':
      case 'last7Days':
      case 'last30Days':
      case 'last12Months':
        selectedInitialDate = null;
        break;
    }

    this.selectedChartRange = selectedInitialDate;
    this.selectedChartRangeComparison = null;
  }

  private generateGroupChartA(data: any): void {
    this.groupChartA = c3.generate({
      bindto: '#group-chartA',
      data: {
        x: 'x',
        json: {},
        names: {
          'heating': this.translations['statistics.chart-heating'],
          'cooling': this.translations['statistics.chart-cooling'],
          'off': this.translations['statistics.chart-off'],
          'comp-heating': this.translations['statistics.chart-heating'],
          'comp-cooling': this.translations['statistics.chart-cooling'],
          'comp-off': this.translations['statistics.chart-off'],
        },
        colors: {
          'heating': '#e18877',
          'cooling': '#75a1bc',
          'off': '#bdbdb7',
          'comp-heating': this.utils.changeColorBrightness('#e18877', this.comparisonDeltaBrightness),
          'comp-cooling': this.utils.changeColorBrightness('#75a1bc', this.comparisonDeltaBrightness),
          'comp-off': this.utils.changeColorBrightness('#bdbdb7', this.comparisonDeltaBrightness),
        },
        type: 'bar',
        groups: [
          ['heating', 'cooling', 'off'],
          ['comp-heating', 'comp-cooling', 'comp-off'],
        ],
        hide: ['comp-x'],
        empty: { label: { text: this.translations['statistics.noDataAvailable'] } },
        order: null,
      },
      legend: {
        hide: ['comp-x'],
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: this.getTimeseriesTickFormat(this.selectedChartPeriod),
          },
        },
        y: {
          label: {
            text: this.translations['statistics.groupChartAyLabel'],
            position: 'outer-top',
          },
        },
      },
      tooltip: {
        format: {
          title: (d) => this.getTooltipByPeriod(this.selectedChartPeriod, d),
          name: (name, ratio, id, index) => {
            switch (id) {
              case 'heating':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-heatingTooltip'] : `${this.translations['statistics.chart-heatingTooltip']} ${this.selectedChartRange.label}`;
              case 'comp-heating':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-heatingTooltip'] : `${this.translations['statistics.chart-heatingTooltip']} ${this.selectedChartRangeComparison.label}`;
              case 'cooling':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-coolingTooltip'] : `${this.translations['statistics.chart-coolingTooltip']} ${this.selectedChartRange.label}`;
              case 'comp-cooling':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-coolingTooltip'] : `${this.translations['statistics.chart-coolingTooltip']} ${this.selectedChartRangeComparison.label}`;
              case 'off':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-offTooltip'] : `${this.translations['statistics.chart-offTooltip']} ${this.selectedChartRange.label}`;
              case 'comp-off':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-offTooltip'] : `${this.translations['statistics.chart-offTooltip']} ${this.selectedChartRangeComparison.label}`;
              default:
                return name;
            }
          },
          value: (value, ratio, id) => `${value}h`,
        },
      },
      subchart: {
        show: true,
        size: {
          height: 30,
        },
      },
    });

    const progressiveLoadingFnArr = this.generateProgressiveLoadingDataFn(this.groupChartA, data);
    this.utils.promiseSerial(progressiveLoadingFnArr);
  }

  private generateGroupChartB(data: any): void {
    this.groupChartB = c3.generate({
      bindto: '#group-chartB',
      data: {
        json: {},
        names: {
          home: this.translations['statistics.chart-home'],
          away: this.translations['statistics.chart-away'],
          vacanza: this.translations['statistics.chart-vacanza'],
          modeOff: this.translations['statistics.chart-modeOff'],
        },
        type: 'donut',
        empty: { label: { text: this.translations['statistics.noDataAvailable'], position: '' } },
        hide: ['x'],
      },
      color: {
        pattern: JSON.parse(JSON.stringify(this.utils.shuffleArray(this.colorPalette))),
      },
      donut: {
        title: this.translations['statistics.groupChartBTitle'],
      },
      legend: { item: { onclick: () => { } }, hide: ['x'], },
      tooltip: {
        format: {
          name: (name, ratio, id, index) => {
            switch (id) {
              case 'home':
                return this.translations['statistics.chart-home'];
              case 'away':
                return this.translations['statistics.chart-away'];
              case 'vacanza':
                return this.translations['statistics.chart-vacanza'];
              case 'modeOff':
                return this.translations['statistics.chart-off'];
              default:
                return name;
            }
          },
          value: (value, ratio, id) => `${Math.round(ratio * 1000) / 10}%`,
        },
      },
    });

    const progressiveLoadingFnArr = this.generateProgressiveLoadingDataFn(this.groupChartB, data);
    this.utils.promiseSerial(progressiveLoadingFnArr);
  }

  private generateGroupChartC(data: any): void {
    this.groupChartC = c3.generate({
      bindto: '#group-chartC',
      data: {
        json: {},
        type: 'donut',
        empty: { label: { text: this.translations['statistics.noDataAvailable'], position: 'bottom' } },
        hide: ['x'],
      },
      donut: {
        title: this.translations['statistics.groupChartCTitle'],
      },
      legend: { item: { onclick: () => { } }, hide: ['x'], },
      color: {
        pattern: JSON.parse(JSON.stringify(this.utils.shuffleArray(this.colorPalette)))
      },
      tooltip: {
        format: {
          name: (name, ratio, id, index) => `${(name[0]).toUpperCase() + name.slice(1)}`,
          value: (value, ratio, id) => `${this.avoidFloatBinaryIssue(value)}h`,
        },
      },
    });

    const progressiveLoadingFnArr = this.generateProgressiveLoadingDataFn(this.groupChartC, data);
    this.utils.promiseSerial(progressiveLoadingFnArr);
  }

  private generateGroupChartD(data: any): void {
    this.groupChartD = c3.generate({
      bindto: '#group-chartD',
      data: {
        x: 'x',
        json: {},
        names: {
          'externalTemp': this.translations['statistics.chart-externalTemp'],
          'comp-externalTemp': this.translations['statistics.chart-externalTemp'],
        },
        type: 'spline',
        empty: { label: { text: this.translations['statistics.noDataAvailable'] } },
        hide: ['comp-x'],
        colors: {
          'externalTemp': '#403f3b',
          'comp-externalTemp': this.utils.changeColorBrightness('#403f3b', this.comparisonDeltaBrightness),
        },
      },
      color: {
        pattern: JSON.parse(JSON.stringify(this.utils.shuffleArray(this.colorPalette)))
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: this.getTimeseriesTickFormat(this.selectedChartPeriod),
          },
        },
        y: {
          label: {
            text: this.translations['statistics.groupChartDyLabel'],
            position: 'outer-top',
          },
        },
      },
      legend: {
        hide: ['comp-x'],
      },
      tooltip: {
        format: {
          title: (d) => this.getTooltipByPeriod(this.selectedChartPeriod, d),
          name: (name, ratio, id, index) => {
            switch (id) {
              case 'externalTemp':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-externalTempTooltip'] : `${this.translations['statistics.chart-externalTempTooltip']} ${this.selectedChartRange.label}`;
              case 'comp-externalTemp':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-externalTempTooltip'] : `${this.translations['statistics.chart-externalTempTooltip']} ${this.selectedChartRangeComparison.label}`;
              default:
                return name;
            }
          },
          value: (value, ratio, id) => `${value}°C`,
        },
      },
      subchart: {
        show: true,
        size: {
          height: 30,
        },
      },
    });

    const progressiveLoadingFnArr = this.generateProgressiveLoadingDataFn(this.groupChartD, data);
    this.utils.promiseSerial(progressiveLoadingFnArr);
  }

  private generateRoomChartA(data: any): void {
    console.log(data);

    this.roomChartA = c3.generate({
      bindto: '#room-chartA',
      data: {
        x: 'x',
        json: {},
        names: {
          'tempMin': this.translations['statistics.chart-tempMin'],
          'tempMax': this.translations['statistics.chart-tempMax'],
          'tempAverage': this.translations['statistics.chart-tempAverage'],
          'setpoint': this.translations['statistics.chart-setpoint'],
          'comp-tempMin': this.translations['statistics.chart-tempMin'],
          'comp-tempMax': this.translations['statistics.chart-tempMax'],
          'comp-tempAverage': this.translations['statistics.chart-tempAverage'],
          'comp-setpoint': this.translations['statistics.chart-setpoint'],
        },
        colors: {
          'tempMin': '#777570',
          'tempMax': '#403f3b',
          'tempAverage': '#403f3b',
          'setpoint': '#c2b852',
          'comp-tempMin': this.utils.changeColorBrightness('#777570', this.comparisonDeltaBrightness),
          'comp-tempMax': this.utils.changeColorBrightness('#403f3b', this.comparisonDeltaBrightness),
          'comp-tempAverage': this.utils.changeColorBrightness('#777570', this.comparisonDeltaBrightness),
          'comp-setpoint': this.utils.changeColorBrightness('#c2b852', this.comparisonDeltaBrightness),
        },
        type: 'spline',
        types: {
          'setpoint': 'step',
          'comp-setpoint': 'step',
        },
        empty: { label: { text: this.translations['statistics.noDataAvailable'] } },
        hide: ['x2', 'comp-x'],
        classes: {
          'tempAverage': 'dashed',
          'comp-tempAverage': 'dashed',
        }
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: this.getTimeseriesTickFormat(this.selectedChartPeriod),
          },
        },
        y: {
          label: {
            text: this.translations['statistics.groupChartDyLabel'],
            position: 'outer-top',
          },
        },
      },
      legend: {
        hide: ['x2', 'comp-x'],
      },
      tooltip: {
        format: {
          title: (d) => this.getTooltipByPeriod(this.selectedChartPeriod, d),
          name: (name, ratio, id, index) => {
            switch (id) {
              case 'tempMin':
              case 'tempMax':
              case 'tempAverage':
              case 'setpoint':
                return !this.selectedChartRangeComparison ? name : `${name} ${this.selectedChartRange.label}`;
              case 'comp-tempMin':
              case 'comp-tempMax':
              case 'comp-tempAverage':
              case 'comp-setpoint':
                return !this.selectedChartRangeComparison ? name : `${name} ${this.selectedChartRangeComparison.label}`;
              default:
                return name;
            }
          },
          value: (value, ratio, id) => `${value}°C`,
        },
      },
      subchart: {
        show: true,
        size: {
          height: 30,
        },
      },
    });

    const progressiveLoadingFnArr = this.generateProgressiveLoadingDataFn(this.roomChartA, data);
    this.utils.promiseSerial(progressiveLoadingFnArr);
  }

  private generateRoomChartB(data: any): void {
    this.roomChartB = c3.generate({
      bindto: '#room-chartB',
      data: {
        x: 'x',
        json: {},
        names: {
          'humidity': this.translations['statistics.chart-humidity'],
          'comp-humidity': this.translations['statistics.chart-humidity'],
        },
        type: 'spline',
        empty: { label: { text: this.translations['statistics.noDataAvailable'] } },
        hide: ['comp-x'],
      },
      color: {
        'humidity': '#403f3b',
        'comp-humidity': this.utils.changeColorBrightness('#403f3b', this.comparisonDeltaBrightness),
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: this.getTimeseriesTickFormat(this.selectedChartPeriod),
          },
        },
        y: {
          label: {
            text: this.translations['statistics.roomChartByLabel'],
            position: 'outer-top',
          },
        },
      },
      legend: {
        hide: ['comp-x'],
      },
      tooltip: {
        format: {
          title: (d) => this.getTooltipByPeriod(this.selectedChartPeriod, d),
          name: (name, ratio, id, index) => `${(name[0]).toUpperCase() + name.slice(1)}`,
          value: (value, ratio, id) => `${value}%`,
        },
      },
      subchart: {
        show: true,
        size: {
          height: 30,
        },
      },
    });

    const progressiveLoadingFnArr = this.generateProgressiveLoadingDataFn(this.roomChartB, data);
    this.utils.promiseSerial(progressiveLoadingFnArr);
  }

  private generateRoomChartC(data: any): void {
    this.roomChartC = c3.generate({
      bindto: '#room-chartC',
      data: {
        x: 'x',
        json: {},
        names: {
          'heating': this.translations['statistics.chart-heating'],
          'cooling': this.translations['statistics.chart-cooling'],
          'off': this.translations['statistics.chart-off'],
          'comp-heating': this.translations['statistics.chart-heating'],
          'comp-cooling': this.translations['statistics.chart-cooling'],
          'comp-off': this.translations['statistics.chart-off'],
        },
        colors: {
          'heating': '#e18877',
          'cooling': '#75a1bc',
          'off': '#bdbdb7',
          'comp-heating': this.utils.changeColorBrightness('#e18877', this.comparisonDeltaBrightness),
          'comp-cooling': this.utils.changeColorBrightness('#75a1bc', this.comparisonDeltaBrightness),
          'comp-off': this.utils.changeColorBrightness('#bdbdb7', this.comparisonDeltaBrightness),
        },
        type: 'bar',
        groups: [
          ['heating', 'cooling', 'off'],
          ['comp-heating', 'comp-cooling', 'comp-off'],
        ],
        hide: ['comp-x', 'off'],
        empty: { label: { text: this.translations['statistics.noDataAvailable'] } },
        order: null,
      },
      legend: {
        hide: ['comp-x'],
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: this.getTimeseriesTickFormat(this.selectedChartPeriod),
          },
        },
        y: {
          label: {
            text: this.translations['statistics.groupChartAyLabel'],
            position: 'outer-top',
          },
        },
      },
      tooltip: {
        format: {
          title: (d) => this.getTooltipByPeriod(this.selectedChartPeriod, d),
          name: (name, ratio, id, index) => {
            switch (id) {
              case 'heating':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-heatingTooltip'] : `${this.translations['statistics.chart-heatingTooltip']} ${this.selectedChartRange.label}`;
              case 'comp-heating':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-heatingTooltip'] : `${this.translations['statistics.chart-heatingTooltip']} ${this.selectedChartRangeComparison.label}`;
              case 'cooling':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-coolingTooltip'] : `${this.translations['statistics.chart-coolingTooltip']} ${this.selectedChartRange.label}`;
              case 'comp-cooling':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-coolingTooltip'] : `${this.translations['statistics.chart-coolingTooltip']} ${this.selectedChartRangeComparison.label}`;
              case 'off':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-offTooltip'] : `${this.translations['statistics.chart-offTooltip']} ${this.selectedChartRange.label}`;
              case 'comp-off':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-offTooltip'] : `${this.translations['statistics.chart-offTooltip']} ${this.selectedChartRangeComparison.label}`;
              default:
                return name;
            }
          }, value: (value, ratio, id) => `${value}h`,
        },
      },
      subchart: {
        show: true,
        size: {
          height: 30,
        },
      },
    });

    const progressiveLoadingFnArr = this.generateProgressiveLoadingDataFn(this.roomChartC, data);
    this.utils.promiseSerial(progressiveLoadingFnArr);
  }

  private generateAirMachineChartA(data: any): void {
    this.airMachineChartA = c3.generate({
      bindto: '#airMachine-chartA',
      data: {
        x: 'x',
        json: {},
        names: {
          'heating': this.translations['statistics.chart-heatingAirMachine'],
          'cooling': this.translations['statistics.chart-coolingAirMachine'],
          'comp-heating': this.translations['statistics.chart-heatingAirMachine'],
          'comp-cooling': this.translations['statistics.chart-coolingAirMachine'],
        },
        colors: {
          'heating': this.utils.changeColorBrightness('#e18877', 40),
          'cooling': this.utils.changeColorBrightness('#75a1bc', 40),
          'comp-heating': this.utils.changeColorBrightness('#e18877', 75),
          'comp-cooling': this.utils.changeColorBrightness('#75a1bc', 75),
        },
        type: 'bar',
        groups: [
          ['heating', 'cooling'],
          ['comp-heating', 'comp-cooling'],
        ],
        hide: ['comp-x'],
        empty: { label: { text: this.translations['statistics.noDataAvailable'] } },
        order: null,
      },
      legend: {
        hide: ['comp-x'],
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: this.getTimeseriesTickFormat(this.selectedChartPeriod),
          },
        },
        y: {
          label: {
            text: this.translations['statistics.airMachineChartByLabel'],
            position: 'outer-top',
          },
        },
      },
      tooltip: {
        format: {
          title: (d) => this.getTooltipByPeriod(this.selectedChartPeriod, d),
          name: (name, ratio, id, index) => {
            switch (id) {
              case 'heating':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-heatingAirMachineTooltip'] : `${this.translations['statistics.chart-heatingAirMachineTooltip']} ${this.selectedChartRange.label}`;
              case 'comp-heating':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-heatingAirMachineTooltip'] : `${this.translations['statistics.chart-heatingAirMachineTooltip']} ${this.selectedChartRangeComparison.label}`;
              case 'cooling':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-coolingAirMachineTooltip'] : `${this.translations['statistics.chart-coolingAirMachineTooltip']} ${this.selectedChartRange.label}`;
              case 'comp-cooling':
                return !this.selectedChartRangeComparison ? this.translations['statistics.chart-coolingAirMachineTooltip'] : `${this.translations['statistics.chart-coolingAirMachineTooltip']} ${this.selectedChartRangeComparison.label}`;
              default:
                return name;
            }
          },
          value: (value, ratio, id) => `${value}h`,
        },
      },
      subchart: {
        show: true,
        size: {
          height: 30,
        },
      },
    });

    const progressiveLoadingFnArr = this.generateProgressiveLoadingDataFn(this.airMachineChartA, data);
    this.utils.promiseSerial(progressiveLoadingFnArr);
  }

  private generateAirMachineChartB(data: any): void {
    this.airMachineChartB = c3.generate({
      bindto: '#airMachine-chartB',
      data: {
        x: 'x',
        json: {},
        names: {
          'rinnovo': this.translations['statistics.chart-rinnovo'],
          'deu': this.translations['statistics.chart-deu'],
          'int': this.translations['statistics.chart-int'],
          'off': this.translations['statistics.chart-off'],
          'comp-rinnovo': this.translations['statistics.chart-rinnovo'],
          'comp-deu': this.translations['statistics.chart-deu'],
          'comp-int': this.translations['statistics.chart-int'],
          'comp-off': this.translations['statistics.chart-off'],
        },
        colors: {
          'rinnovo': '#c2b852',
          'deu': '#547f99',
          'int': '#75a1bc',
          'off': '#bdbdb7',
          'comp-rinnovo': this.utils.changeColorBrightness('#c2b852', this.comparisonDeltaBrightness),
          'comp-deu': this.utils.changeColorBrightness('#547f99', this.comparisonDeltaBrightness),
          'comp-int': this.utils.changeColorBrightness('#75a1bc', this.comparisonDeltaBrightness),
          'comp-off': this.utils.changeColorBrightness('#bdbdb7', this.comparisonDeltaBrightness),
        },
        type: 'bar',
        groups: [
          ['rinnovo', 'deu', 'int', 'off'],
          ['comp-rinnovo', 'comp-deu', 'comp-int', 'comp-off'],
        ],
        hide: ['comp-x', 'off'],
        empty: { label: { text: this.translations['statistics.noDataAvailable'] } },
        order: null,
      },
      color: {
        pattern: JSON.parse(JSON.stringify(this.utils.shuffleArray(this.colorPalette))),
      },
      legend: {
        hide: ['comp-x'],
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: this.getTimeseriesTickFormat(this.selectedChartPeriod),
          },
        },
        y: {
          label: {
            text: this.translations['statistics.airMachineChartAyLabel'],
            position: 'outer-top',
          },
        },
      },
      tooltip: {
        format: {
          title: (d) => this.getTooltipByPeriod(this.selectedChartPeriod, d),
          name: (name, ratio, id, index) => name,
          value: (value, ratio, id) => `${value}h`,
        },
      },
      subchart: {
        show: true,
        size: {
          height: 30,
        },
      },
    });

    const progressiveLoadingFnArr = this.generateProgressiveLoadingDataFn(this.airMachineChartB, data);
    this.utils.promiseSerial(progressiveLoadingFnArr);
  }

  private getDateFnsUserLocale(): any {
    const locales = { de, en: enGB, es, it, pl };
    const userLocale = ((this.identity || {} as any).user || {} as any).language || 'en';
    return locales[userLocale] || locales['en'];
  }

  private getMomentUserLocale(): any {
    return ((this.identity || {} as any).user || {} as any).language || 'en';
  }

  private generateProgressiveLoadingDataFn(chartObj: any, data: any, timeout: number = 500): Function[] {
    const keysArr = Object.keys(this.utils.omit(['x', 'x2'], data));
    const loadingFnArray = [];

    keysArr.map(key => {
      loadingFnArray.push(() => new Promise((resolve, reject) => {
        const fn = () => {
          // Setpoint è l'eccezione con risoluzione a mezz'ora nel grafico a risoluzione orria
          let dataset;
          if (key === 'setpoint' && ((this.selectedChartPeriod === 'daily') || (this.selectedChartPeriod === 'last24Hours'))) {
            dataset = { x: (data.x2 || data.x), [key]: data[key] };
          } else {
            // Questa condizione ignora la x di confronto (comp-x), questo perchè i grafici devono sovrappongono sulla stessa x
            dataset = this.utils.pick(['x', key], data);
          }
          chartObj.load({
            json: dataset,
          });
          resolve(null);
        };

        if (timeout) {
          setTimeout(() => fn(), timeout);
        } else {
          fn();
        }
      }));
    });

    return loadingFnArray;
  }

  private getTimeseriesTickFormat(period: typeof chartPeriodArray[number]): string {
    // La sintassi è quella di c3js che si rifà a mySql
    switch (period) {
      case 'daily':
      case 'last24Hours':
        return '%H:%M';
      case 'weekly':
      case 'monthly':
      case 'last7Days':
      case 'last30Days':
        return '%d/%m/%Y';
      case 'annual':
      case 'last12Months':
        return '%m/%Y';
    }
  }

  private getTooltipByPeriod(period: typeof chartPeriodArray[number], value: any): string {
    switch (period) {
      case 'daily':
      case 'last24Hours':
        return `Ore ${format(value, 'HH:mm', { locale: this.getDateFnsUserLocale() })}`;
      case 'weekly':
      case 'last7Days':
        return `${format(value, 'iiii dd/MM/yyyy', { locale: this.getDateFnsUserLocale() })}`;
      case 'monthly':
      case 'last30Days':
        return `${format(value, 'dd/MM/yyyy')}`;
      case 'annual':
      case 'last12Months':
        return `${format(value, 'MMMM yyyy', { locale: this.getDateFnsUserLocale() })}`;
    }
  }

  private fetchTranslations(): void {
    this.translate.get([
      'statistics.chart-heating', 'statistics.chart-cooling', 'statistics.chart-off', 'statistics.groupChartATooltipName', 'statistics.chart-home',
      'statistics.chart-away', 'statistics.chart-vacanza', 'statistics.chart-off', 'statistics.chartCTitle', 'statistics.chart-modeOff',
      'statistics.chart-tempMin', 'statistics.chart-tempMax', 'statistics.chart-humidity', 'COMMON.hr', 'statistics.chart-rinnovo',
      'statistics.chart-deu', 'statistics.chart-int', 'statistics.chart-int', 'statistics.noDataAvailable', 'statistics.chart-externalTemp',
      'statistics.chart-tempAverage', 'statistics.chart-setpoint', 'statistics.groupChartAyLabel', 'statistics.chart-heatingTooltip',
      'statistics.chart-coolingTooltip', 'statistics.chart-offTooltip', 'statistics.groupChartCTitle', 'statistics.groupChartBTitle',
      'statistic.groupChartCTitle', 'statistics.roomChartBTitle', 'statistics.chart-externalTempTooltip', 'statistics.groupChartDyLabel',
      'statistics.airMachineChartByLabel', 'statistics.roomChartByLabel', 'statistics.chart-heatingAirMachine', 'statistics.chart-coolingAirMachine',
      'statistics.chart-heatingAirMachineTooltip', 'statistics.chart-coolingAirMachineTooltip', 'statistics.airMachineChartAyLabel',
      'statistics.airMachineChartByLabel',
    ])
      .subscribe(res => {
        this.translations = res;
      });
  }

  private isNoRangePeriodFn(period): boolean {
    return (period === 'last24Hours') || (period === 'last7Days') || (period === 'last30Days') || (period === 'last12Months');
  }

  // Cerca il nome corrispondenrte al room id nel modello di dati
  private searchRoomNameById(data: any, id: string): string {
    const roomsArr = (data.groups || []).reduce((acc, item) => acc.concat(item.rooms), []).filter(i => !!i);
    const room = roomsArr.find(i => i.key === id);
    return (room || {}).name || id;
  }

  // Trasforma la struttura dell'aggregato (array di oggetti) in un singolo oggetto composto di array
  private normalizeIfArray(data: any): any {
    if (!Array.isArray(data)) {
      return data;
    }

    return data.reduce((obj, item) => {
      if (item) {
        item = this.utils.omit(['id'], item);
        item = this.utils.renameObjKeys(['date'], ['x'], item);

        Object
          .keys(item)
          .map(key => obj[key] = (obj[key] || []).concat(item[key]));
      }

      return obj;
    }, {});
  }

  private getDeviceFromModel(model: any): any {
    return ((model || {}).data || {}).reported;
  }

  private normalizeDataFromChart(chart: any, typeTimeseries: boolean = true): any[] {
    const data = chart.data();
    const labels = chart.data.names();
    const xDataset = (data.find(it => it.id === 'x') || {}).values || []; // Usato solo per grafici che non sono di tipo timeseries

    const datasets = data.reduce((acc, item) => {
      const dataName = item.id;
      const values = item.values;

      // Pulisco eventuali dati non desiderarti
      if ((dataName === 'x') || (dataName === 'x2')) {
        return acc;
      }

      // Il donut non è una timeserie perchè nonostante derivi da un dataset di quel tipo ne perde le caratteristiche quando
      // il dato viene preso dall'oggetto grafico
      if (typeTimeseries) {
        acc[dataName] = values.map(i => ({ x: i.x, [i.id]: i.value }));
      } else {
        acc[dataName] = values.map(i => ({ x: ((xDataset[i.index] || {}).value || i.index), [i.id]: i.value }));
      }
      return acc;
    }, {});

    let output = Object.keys(datasets).map(key => datasets[key]).reduce((normalizedDataset, dataset) => {
      const isInitialized = normalizedDataset.length;
      if (!isInitialized) {
        normalizedDataset = dataset;
      } else {
        // Controllo per ogni elemento se nel dataset normalizzato esiste già un oggetto corrispondendente a tal punto,
        // se si vi aggiungo il nuovo valore, altrimenti ne creo uno nuovo (ATTENZIONE, vedi commento sotto)
        dataset.map(item => {
          const indexInNormalizedDataset = normalizedDataset.findIndex(i => isEqual(i.x, item.x));
          if (indexInNormalizedDataset !== -1) {
            normalizedDataset[indexInNormalizedDataset] = Object.assign(normalizedDataset[indexInNormalizedDataset], item);
          } else {
            /**
             * ! EDIT !
             * Se non esiste già vuol dire che è un valore di un asse x secondario (es X2),
             * abbiamo stabilito di eliminarli perchè crea parecchie complicazioni
             */
            // normalizedDataset = normalizedDataset.concat(item); // TODO to improve
          }
        });
      }

      return normalizedDataset;
    }, []);

    output = output.sort(this.utils.sortByProperty('x'));
    output = output.map(i => {
      i.x = isDate(i.x) ? format(i.x, 'yyyy-MM-dd HH:mm') : format(new Date(i['x']), 'yyyy-MM-dd HH:mm');
      if (i['comp-x']) {
        i['comp-x'] = isDate(['comp-x']) ? format(i['comp-x'], 'yyyy-MM-dd HH:mm') : format(new Date(i['comp-x']), 'yyyy-MM-dd HH:mm');
      }
      // Trasforma le chiavi nell'equivalente delle label
      i = this.utils.renameObjKeys(Object.keys(i), Object.keys(i).map(key => (labels[key] || key)), i);

      return i;
    });

    // Riempio tutti i valori mancanti
    const outputKeys = output.reduce((acc, item) => {
      Object.keys(item).map(el => {
        if (!acc.includes(el)) {
          acc.push(el);
        }
      });
      return acc;
    }, []);
    output = output.map(item => {
      const completeItem = {};
      outputKeys.map(key => completeItem[key] = (item.hasOwnProperty(key) ? item[key] : null));
      return completeItem;
    });

    return output;
  }

  private initAdvices(): void {
    if (!this.data) {
      this.adviceLoaded = false;
      this.adviceLoading = false;
      return;
    }

    this.adviceLoaded = false;
    this.adviceLoading = true;
    this.adviceService.get(null, { orderBy: 'created_at', order: 'DESC', read: false, 'thing_id[]': this.data.id })
      .then(res => {
        this.adviceLoaded = true;
        this.adviceLoading = false;
        this.adviceList = res.models;
      })
      .catch(err => {
        this.adviceLoading = false;
      });
  }

  private avoidFloatBinaryIssue(n: number): number {
    return Math.round(n * 100) / 100;
  }

  private handlePickerClosing(event) {
    const isClickOnPicker = (((event || {}).target || {}).offsetParent || {}).localName === 'ngb-datepicker';
    const isNotClosePickerElement = (((event || {}).target || {}).classList || []).contains('not-close-datepicker');

    if (this.picker && !isClickOnPicker && !isNotClosePickerElement) {
      this.picker.close();
    }
    if (this.pickerComparison && !isClickOnPicker && !isNotClosePickerElement) {
      this.pickerComparison.close();
    }
  }

  private theComparisonIsActive(): boolean {
    return (!!this.selectedChartRangeComparison && !!this.selectedChartRangeComparison.start);
  }
}
