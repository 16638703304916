<div class="display-inline-block py-1 week-picker">
  <div class="input-group">
    <input class="form-control"
      type="text"
      name="chartRange"
      [(ngModel)]="inputDate"
      (dateSelect)="formatRangeWeek()"
      ngbDatepicker
      [markDisabled]="markDisabled"
      placeholder="{{ placeholder | translate }}"
      #ngbDatepicker="ngbDatepicker"/>
    <button
      class="datepicker-clearform"
      type="button"
      *ngIf="isComparison && !!inputDate.day"
      (click)="removeComparison()"
    >
      <i class="fa fa-times"></i>
    </button>
    <div class="input-group-append not-close-datepicker">
      <button
        class="btn btn-outline-secondary dropdown-toggle-split not-close-datepicker"
        (click)="toggleDatepicker(ngbDatepicker)"
        type="button"
      >
        <div class="calendar not-close-datepicker" aria-hidden="true"></div>
      </button>
    </div>
  </div>
</div>
