import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreHttpModelParser, CoreUtilsService } from './../core';
import 'rxjs/add/operator/map';
import { Event, EVENT_FILTERS } from 'app/event/event.type';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AdviceService extends CoreHttpModelParser {

  constructor(
    public http: HttpClient,
    public utils: CoreUtilsService
  ) { super(); }

  entityName = 'event';

  // overrides the method in CoreHttpModelParser
  // Necessario per permettere il filtraggio per valori booleani, ma non volendo toccare il core (
  // causando eventuali errori imprevisti in altre sezioni dell'app) sovrascrivp la funzione
  // per questo servizio soltanto
  public objToQuery(obj: {}, exclude?: String[]): any {
    obj = JSON.parse(JSON.stringify(obj || {}));
    if(exclude === undefined ) {
      exclude = ["_section", "_title", "_last"];
    }
    // console.log("OBJ", obj);
    var r = {};
    for(var k in obj) {
      if(exclude.indexOf(k) == -1) {
        if(k == "filters") {
          r['fields[]'] = [];
          r['values[]'] = [];
          for(var j in obj[k]) {
            r['fields[]'].push(j);
            r['values[]'].push(obj[k][j])
          }
        }
        else {
          if(!!obj[k] || typeof obj[k] === "boolean") {
            r[k] = obj[k]
          }
        }
      }
    }

    // console.log("TO QUERY", r);
    return r;
  }

  public get(id: any, params?: {}, query?: {}): any {
    let p = new Promise((resolve, reject) => {
      if (!id) {
        id = "";
      }
      else {
        id = "/" + id;
      }
      if (params && params['id']) {
        delete params['id'];
      }
      this.http.get(
        environment.APP_CONFIG.endpointV2 + this.entityName + id,
        {
          params: Object.assign({ 'type[]': 'ADVICE' }, this.objToQuery(params), this.objToQuery(query)),
          observe: 'response'
        }
      ).toPromise().then(res => {
        resolve(this.parse(res, Event));
      }, err => reject(err));
    });
    return p;
  }

  public post(data): any {
    let p = new Promise((resolve, reject) => {
      this.http.post(
        environment.APP_CONFIG.endpointV2 + this.entityName,
        this.utils.pick(EVENT_FILTERS['post'], data),
        {
          observe: 'response'
        }
      ).toPromise().then(res => {
        console.log(JSON.stringify(res));
        resolve(this.parse(res, Event));
      }, err => reject(err));
    });
    return p;
  }

  public patch(id, data: any): Promise<any> {
    let p = new Promise((resolve, reject) => {
      this.http.patch(
        environment.APP_CONFIG.endpointV2 + this.entityName + '/' + id,
        this.utils.pick(EVENT_FILTERS['patch'], data),
        { observe: 'response' }
      ).toPromise().then(res => {
        resolve(this.parse(res, Event));
      }, err => reject(err));
    });
    return p;
  }

  public delete(id): Promise<any> {
    return this.http.delete(
      environment.APP_CONFIG.endpointV2 + this.entityName + '/' + id,
      { observe: 'response' }
    ).toPromise();
  }

  readAdvice(ids: number[], status: boolean): Promise<any> {
    let p = new Promise((resolve, reject) => {
      this.http.post(
        environment.APP_CONFIG.endpointV2 + this.entityName + '/read',
        {
          ids: ids,
          read: status
        },
        { observe: 'response' }
      ).toPromise().then(res => {
        resolve(this.parse(res, Event));
      }, err => reject(err));
    });
    return p;
  }
}
