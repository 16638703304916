import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreHttpModelParser, CoreUtilsService } from './../core';
import 'rxjs/add/operator/map';
import {
  subDays,
  format,
  startOfDay,
  isWithinInterval,
  differenceInHours,
} from 'date-fns';
import { chartPeriodArray, entityCategoryArray } from './statistics.type';
import { CustomHttpParams } from './../core';
import { environment } from 'environments/environment';

interface Options {
  category: typeof entityCategoryArray[number];
  resolution: 'hour' | 'day' | 'month' | 'last24Hours' | 'last30Days' | 'last12Months';
}

@Injectable({
  providedIn: 'root'
})
export class StatisticsService extends CoreHttpModelParser {

  constructor(
    public http: HttpClient,
    public utils: CoreUtilsService
  ) { super(); }

  public fetchDataByPeriod(deviceId: string, entityId: string, category: typeof entityCategoryArray[number],
                           period: typeof chartPeriodArray[number], range: { start: Date, end?: Date }): Promise<any> {
    switch (period) {
      // PUNTUALI
      case 'last24Hours':
        return this.getFile(deviceId, entityId, {resolution: period, category})
          .then(data => {
            // I dati più vecchi di 3 ore (dall'ultimo record) diventano obsoleti
            if (this.isFreshData(data)) {
              return this.dataProcessing(data, period);
            } else {
              return [];
            }
          });
      case 'last30Days':
      case 'last12Months':
        return this.getFile(deviceId, entityId, {resolution: period, category})
          .then(data => this.dataProcessing(data, period));
      case 'daily':
        return this.getFile(deviceId, entityId, {resolution: 'hour', category}, range.start)
          .then(data => this.dataProcessing(data, period));
      // RANGE
      case 'weekly':
      case 'monthly':
      case 'annual':
        return this.fetchDataByRange(deviceId, entityId, category, period, range)
          .then(dataset => this.fetchDataFromDataset(dataset, range.start, range.end))
          .then(data => this.dataProcessing(data, period));
      case 'last7Days':
        range = { start: subDays(startOfDay(new Date()), 7), end: startOfDay(new Date()) };
        return this.fetchDataByRange(deviceId, entityId, category, period, range)
          .then(dataset => this.fetchDataFromDataset(dataset, range.start, range.end))
          .then(data => this.dataProcessing(data, period));
      default:
        return Promise.reject('Period not supported by method');
    }
  }

  public fetchDataByRange(
      deviceId: string, entityId: string, category: typeof entityCategoryArray[number],
      period: typeof chartPeriodArray[number], range: { start: Date, end?: Date }
    ): Promise<any> {

    const involvedYearsArr = this.getInvolvedYears(range.start, range.end);
    let promises;

    switch (period) {
      case 'weekly':
      case 'monthly':
      case 'last7Days':
        promises = involvedYearsArr.map(year => () => this.getFile(deviceId, entityId, { category, resolution: 'day' }, new Date(year, 1, 1)));
        return this.utils.promiseSerial(promises);
      case 'annual':
        promises = involvedYearsArr.map(year => () => this.getFile(deviceId, entityId, { category, resolution: 'month' }, new Date(year, 1, 1)));
        return this.utils.promiseSerial(promises);
      default:
        return Promise.reject('Period not supported by method');
    }
  }

  private getFile(deviceId: string, entityId: string, options: Options, startDate?: Date): Promise<any> {
    const fileIdentifier = this.createFileIdentifier(options, startDate);

    const url = `${environment.APP_CONFIG.statistics.bucketEndpoint}/${deviceId}/${options.category}/${entityId}_${fileIdentifier}.json`;

    return this.http.get(url, {
      params: new CustomHttpParams({ ignoreError: true }),
    }).toPromise().catch(err => []);
  }

  private createFileIdentifier(options: Options, date?: Date): string {
    switch (options.resolution) {
      case 'hour':
        return `${format(date, 'yyyy-MM-dd')}`;
      case 'day':
        return `day_${format(date, 'yyyy')}`;
      case 'month':
        return `month_${format(date, 'yyyy')}`;
      case 'last24Hours':
      case 'last30Days':
      case 'last12Months':
        return (options.resolution).toLowerCase();
    }
  }

  private getInvolvedYears(start: Date, end: Date): number[] {
    const startYear = Number(start.getFullYear());
    const endYear = end ? Number(end.getFullYear()) : startYear;

    if (startYear > endYear) {
      return [];
    }

    let yearsArr = [ startYear ];

    while (yearsArr.slice(-1).pop() !== endYear) {
      yearsArr = yearsArr.concat(Number(yearsArr.slice(-1)) + 1);
    }

    return yearsArr;
  }

  private fetchDataFromDataset(dataset: any[], start: Date, end: Date): any[] {
    return dataset
      .filter(item => isWithinInterval(new Date(item.date), { start, end }))
      .sort(this.utils.sortByProperty('date'));
  }

  private dataProcessing(data: any, period: typeof chartPeriodArray[number]): any {
    if (Array.isArray(data)) {
      data = data.map(x => this.dataProcessing(x, period));
    }

    if (data.x) {
      data.x = data.x.map(i => new Date(i));
    }
    if (data.x2) {
      data.x2 = data.x2.map(i => new Date(i));
    }
    if (data.date) {
      // Le date che non hanno il riferimento all'ora vanno considerate come assolute
      data.date = new Date(data.date.substr(0, 19));
    }

    return data;
  }

  private isFreshData(data: any): boolean {
    let lastRecordDateTime;
    if (data && data.x && Array.isArray(data.x)) {
      lastRecordDateTime = data.x[data.x.length - 1];
    }
    lastRecordDateTime = new Date(lastRecordDateTime);

    return differenceInHours(new Date(), lastRecordDateTime) < 3;
  }

}
