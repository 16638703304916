import { CoreStorageService } from './../core/storage/storage.service';
import { Component, OnInit, NgZone
 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from './../device';
// import { AppState } from '../app.service';


import { CoreStorage } from '../core'

@Component({
  selector: 'main-dashboard',
  templateUrl: './main-dashboard.component.html'
})


export class MainDashboardComponent implements OnInit {

  //example

  tableData = [["Area 1", "ok", 22], ["Area 2", "ok", 19], ["Extra EU", "ok", 21], ["Documenti", "no", 16.5], ["Opere", "ok", 28]];

  chartValues = [
    {data: [11, 8, 12, 10, 9], label: 'Min'},
    {data: [16, 14, 16, 14, 16], label: 'Max'}
  ];
  days = ['lunedì', 'martedì', 'mercoledì',' giovedì', 'venerdì', 'sabato', 'domenica','lunedì', 'martedì', 'mercoledì',' giovedì', 'venerdì', 'sabato',];
  chartLabels = this.days.slice(new Date().getDay() , new Date().getDay() + 5);
  chartValues2 = [20, 30, 10, 40]

  lineChartOptions = {
    legend: {
       labels: {
          fontColor: "black",
       },
       onClick: function() {}
    }
  }


  activationsLabels = [];
  activationsValues = [];
  activationsLoded = false;

  loaded = false;

  show: string = 'home';

  list = []
  center = {}
  zoomLevel = 0;
  baseZoom = 5;


 identity

  oems = [];

  constructor(
    private router: Router,
    private deviceService: DeviceService,
    private zone: NgZone,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private storage: CoreStorageService
    // private state: AppState
  ) { }

  ngOnInit(): void {
    this.identity = this.storage.retrieve('identity') || {};
    if(this.identity.role == 'ROLE_ADMIN' || this.identity.role == 'ROLE_OEM') {
      setTimeout(() => {  this.searchGeo(45, 7, 0) ;}, 500)
      this.getOem();
      this.getCount();
    }
    else {
      this.loaded = true;
    }

    this.route.queryParams.subscribe( params => {
      if (params.show) {
        this.show = params.show;
      }
    });
  }

  resetZoom() {
    this.zone.run(() => {

      this.center = {zoom: 6, lat: this.center['lat'], lon: this.center['lon']};
      this.zoomLevel = 0
    })
  }

  searchGeo(lat, lon, zoom?) {
    if(zoom == true && this.zoomLevel < 4) {
      this.zoomLevel++;
    }

    this.zone.run(() => {
      this.center = {lat: lat, lon: lon, zoom: this.baseZoom + (this.zoomLevel * 3)};
    });
    this.deviceService.geoStat(lat, lon, this.zoomLevel).then((list) => {
      this.list = list;
      this.loaded = true;
    })


  }

  searchUserF = "";
  searchUserDo = false;
  searchUser() {
    this.searchUserDo = false
    setTimeout(() => {this.searchUserDo = true }, 200)
  }

  searchMapF = "";
  searchMap() {
    this.deviceService.searchAddress([this.searchMapF]).then((response) => {
      this.searchGeo(response.body['results'][0].geometry.location.lat, response.body['results'][0].geometry.location.lng, false)
    })
  }

  getOem() {
    if(this.identity && this.identity.role == 'ROLE_ADMIN') {
      this.deviceService.oemStat().then((list) => {
        this.oems = list;
      })
    }
  }

  nullDevices = 0;
  totalDevices = 0;
  getCount() {
    this.deviceService.deviceStat().then((list) => {
      this.activationsValues[0] = {label: this.translate.instant('chart.activations'), data: []}
      this.activationsLabels= [];
      for(let i = 11; i >= 0; i--) {
        var d = new Date();
        d.setMonth(new Date().getMonth()-i);
        this.activationsLabels.push(d.toISOString().substring(0,7))
        this.activationsValues[0]['data'].push(0);
      }
      //console.log("act", this.activationsLabels)
      for(var j = 0; j < list.length; j++) {
        if(list[j].label === null) {
          this.nullDevices = list[j].num;
          this.totalDevices += list[j].num;
        }
        else {
          if(this.activationsLabels.indexOf(list[j].label) > -1) {

            this.activationsValues[0]['data'][this.activationsLabels.indexOf(list[j].label)] = list[j].num;
            this.totalDevices += list[j].num;
          }
        }

      }
      console.log(this.activationsLabels, this.activationsValues)
      this.activationsLoded = true;
    })
  }

}
