


<div class="block block-m-t" >

  <h4>{{ 'common-edit.title' | translate }}</h4>
  <p>
    * {{ 'common-edit.required' | translate }}
  </p>

  <form #objForm="ngForm" class="form-standard" [hidden]="!(new || obj.id)" (ngSubmit)="save()">
    <div [ngClass]="{'animated fadeOut': saveLoading}">

      <p class="tabs">
        <button type="button" *ngFor="let t of tabs; let i = index" class="btn btn-lg" [ngClass]="{'btn-info': tab == i, 'btn-link': i != tab}" (click)="tab = i">{{ 'tabs.' + tabs[i] | translate }}</button>
      </p>

        <div [hidden]="tab != 0" class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <div class="form-group">
                <label for="name">{{ 'common-edit.title2' | translate }} *</label>
                <input
                  type="text" class="form-control" id="title"
                  required
                  [(ngModel)]="obj.title" name="title" >
              </div>
            </div>
          </div>



          <div class="col-lg-12">
            <div class="form-group">
              <label for="status">{{ 'COMMON.status' | translate }} *</label>
              <div>
                <div class="btn-group" ngbRadioGroup name="status" [(ngModel)]="obj.status">
                  <label ngbButtonLabel class="btn-info" *ngFor="let s of 'communicationStatus' | CoreEnumPipe">
                    <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="lang">{{ 'common-edit.lingua' | translate }} *</label>
              <div>
                <div class="btn-group" ngbRadioGroup name="lang" [(ngModel)]="obj.lang">
                  <label ngbButtonLabel class="btn-info" *ngFor="let s of 'langs' | CoreEnumPipe">
                    <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                  </label>
                </div>
              </div>
            </div>
          </div>



        <!-- <div class="col-lg-12">
          <div class="form-group">
            <div class="form-group">
              <label for="name">Sottotitolo *</label>
              <input
                type="text" class="form-control" id="subtitle"
                required
                [(ngModel)]="obj.subtitle" name="subtitle" >
            </div>
          </div>
        </div> -->


        <div class="col-lg-12">
          <div class="form-group">
            <label for="name">{{ 'common-edit.text' | translate }} *</label>
            <editor [(ngModel)]="obj.text" name="text"></editor>
          </div>
        </div>

      </div>

      <div [hidden]="tab != 1" class="row">


          <div class="col-lg-6">

            <div class="form-group">
            <label for="name">{{ 'common-edit.uplaod-file' | translate }}</label>
             <!-- <input type="file" (change)="uploadChange($event)" /><br /> -->
             <core-upload #upload (change)="upload.upload()" [multiple]="false"  [files]="obj.attachments"></core-upload>

             <div class="input-group mtb-1" *ngFor="let file of obj.attachments; let i = index;">
                <input type="text" class="form-control" name="file-{{i}}" [(ngModel)]="obj.attachments[i].name">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2" (click)="removeAttachment(i)"><i class="fa fa-remove"></i></span>
                </div>
              </div>

           </div>
           <div class="form-group">
              <!-- @TODO controllare funzionamento  uploadFile && uploadStatus -->
              <!-- <button type="button" *ngIf="uploadFile && uploadStatus <= 0" class="btn btn-outline-info" (click)="uploadStart()">{{ 'common-edit.uplaod-file' | translate }}</button> -->
           </div>
          </div>
          <!-- <div class="col-lg-6 bg-light">
            <div class=" padding-1">
              <label for="name" [hidden]="uploadStatus <= 0">Progresso caricamento</label>
              <ngb-progressbar  [hidden]="uploadStatus <= 0" type="success"  [animated]="true" [value]="uploadStatus" [striped]="uploadStatus < 100"></ngb-progressbar>
              <p [hidden]="uploadStatus < 100"> Caricamento Completato</p>
            </div>
          </div> -->
      </div>




    </div>

    <div class="form-group mt-3">
      <button type="submit" class="btn btn-success btn-lg" [disabled]="!objForm.valid ||  !obj.status || !obj.lang" [ladda]="saveLoading">{{ 'COMMON.save' | translate }}</button>
      <span class="btn" [hidden]="!saved"><strong>{{ 'COMMON.saved' | translate }}</strong></span>
    </div>

  </form>
</div>
