
<div class="block block-m-t">

  <h1 [hidden]="mode == 'inline'">
    <!-- <span [hidden]="query._title">Elenco comunicazioni</span>
    <span [hidden]="!query._title">Elenco comunicazioni</span> -->
    {{ query._title || 'newsletter.list' | translate }}
    <span [hidden]="! page || page == 1"> - {{ 'COMMON.page' | translate }} {{ page }}</span>
    <span class="pull-right">
      <button class="btn btn-info" (click)="add()" >{{ 'COMMON.create' | translate }}</button>
    </span>
  </h1>



  <form #searchForm="ngForm" class="searchForm" (ngSubmit)="navigate(1)"  [hidden]="mode == 'inline' && !modeSelect && !forceForm" >
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" >
          <select class="form-control"
            id="not_sent"
            [(ngModel)]="query.not_sent"
            name="not_sent"
            style="margin-left:10px"
          >
            <!-- <option value="null">
              {{ 'COMMON.not_sentNull' | translate }}
            </option> -->

            <option value="true">
                {{ 'newsletter.readyToSend' | translate }}
            </option>
            <option value="false">
                {{ 'COMMON.all' | translate }}
            </option>

          </select>
        </div>
      </div>
      <div class="col-md-1 offset-md-7 text-right">
        <div class="form-group">
          <button class="btn btn-outline-info" type="submit"><i class="fa fa-search"></i></button>
        </div>
      </div>
    </div>
  </form>


  <div class="overflow-x-scroll" [coreLoader]="loading">

    <table class="table table-bordered">
      <thead class="">
        <tr class="bg-dark lead" [hidden]="mode == 'inline'">
          <th class="text-center " colspan="10">
            {{ 'newsletter.listTitle' | translate }}
          </th>
        </tr>
        <tr>
          <th class="text-center">
            {{ 'newsletter.name' | translate }}
          </th>
          <th class="text-center">
            {{ 'newsletter.subject' | translate }}
          </th>
          <th class="text-center">
            {{ 'COMMON.status' | translate }}
          </th>
          <th class="text-center">
          </th>
        </tr>
      </thead>
      <tbody *ngIf="loaded">
        <tr *ngFor="let row of list; let i = index" class="animated fadeIn" [ngClass]="{'refused': row.status == 'REFUSED', 'highlight': row.loadings && row.loadings.new}">
          <td class="text-center">
            <p class="lead">{{ row.name }}</p>
            {{ 'newsletterType' | CoreLabelPipe : row.model | translate}}
          </td>
          <td class="text-center">
            {{ row.subject }}
          </td>
          <td  class="text-center" >

            <core-chip size="small"
                      label="{{getBadge('label', row) | translate }}"
                      [color]="getBadge('color', row)">
            </core-chip><br>
            <small *ngIf="row.tested && row.send_at">{{'newsletter.sendOnDay' | translate}}: {{row.send_at | CoreDatePipe : 'dd/MM/yyyy HH:mm' }}</small>
          </td>
          <td class="text-center pb-0" *ngIf="row && row.loadings && ! modeSelect">
            <button type="button" class="btn btn-sm btn-outline-info" *ngIf="!row.sent_at"
                    (click)="edit(row, i)">{{ 'COMMON.edit' | translate }}</button>
            <button type="button" class="btn btn-sm btn-outline-danger" *ngIf="!row.sent_at"
                    (click)="delete(row, i)" [ladda]="row.loadings['delete']" data-spinner-color="#000" data-style="zoom-in"><i class="fa fa-remove"></i></button>
          </td>
          <td class="text-center pb-0" *ngIf="modeSelect">
            <button type="button" class="btn btn-sm btn-outline-info" (click)="select(row, i)">{{ 'COMMON.select' | translate }}</button>
          </td>

          <!-- <td>
            <button type="button" class="btn btn-sm btn-outline-info" routerLink="/newsletter/edit/{{ row.id }}">Gestisci</button>
          </td> -->
        </tr>
      </tbody>
    </table>

    <ng-container *ngIf="mode == 'inline'" >
      <p class="mb-0">
        <button type="button" class="btn btn-sm btn-info btn-block-inline" (click)="add(true)" [ngClass]="{'btn-block-inline-active': newInlineChild}"><span [hidden]="newInlineChildId">Crea</span><span [hidden]="!newInlineChildId">Modifica</span> <i [hidden]="!newInlineChild" class="fa fa-arrow-down animated fadeIn"></i></button>
        <button type="button" class="btn btn-sm animated fadeIn btn-block-inline" (click)="add(false)" [hidden]="!newInlineChild" ><i class="fa fa-close"></i></button>
      </p>
      <div class="block-inline" *ngIf="newInlineChild">
        <newsletter-edit  [mode]="mode" [paramsIn]="{id: newInlineChildId}" [objIn]="objIn"></newsletter-edit>
      </div>
    </ng-container>


    <p [hidden]="loaded && list.length || !loaded" class="text-center">
      {{ 'COMMON.nothing' | translate }}
    </p>

    <hr style="margin:50px 0"/>

  </div>
</div>
<core-pagination-nav [pageInfo]="pageInfo" [(page)]="page" (changed)="navigate($event)"  ></core-pagination-nav>
