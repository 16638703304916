import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreChartService {

  getColor(type: string): string {
    return environment.APP_CONFIG.colors[type] || environment.APP_CONFIG.colors['dark'];
  }

  getColorByValue(schema: any, value: number): string {
    for(var j in schema) {
      if(value >= schema[j] ) {
        return environment.APP_CONFIG.colors[j];
      }
    }
    return environment.APP_CONFIG.colors['dark'];
  }
  getColorNameByValue(schema: any, value: number): string {
    for(var j in schema) {
      if(value >= schema[j] ) {
        return j;
      }
    }
    return 'dark';
  }

  getShadedColor(color, percent) {  // deprecated. See below.
      var num = parseInt(color.slice(1),16), amt = Math.round(2.55 * percent), R = (num >> 16) + amt, G = (num >> 8 & 0x00FF) + amt, B = (num & 0x0000FF) + amt;
      return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (G<255?G<1?0:G:255)*0x100 + (B<255?B<1?0:B:255)).toString(16).slice(1);
  }

}
