import { Component, OnInit, Input } from '@angular/core';
import  { RouterLink } from '@angular/router'
import { NgClass, NgStyle} from '@angular/common';
import { CoreMessageService, CoreStorageService, CoreStorage } from "../core";

import {ChangeDetectorRef } from '@angular/core';



@Component({
  selector: 'app-left',
  templateUrl: './layout.left.component.html'
})

export class LayoutLeftComponent implements OnInit {
  identity
  constructor( public storage: CoreStorageService, private cdref: ChangeDetectorRef) {

  }

  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  ngOnInit() {
    this.identity = this.storage.retrieve('identity') || {};
  }

  @Input() section: string = ''

}
