
<div class="block block-m-t">

  <div  [hidden]="mode == 'inline'">
      <h4>{{ 'common-edit.title' | translate }}</h4>
      <p>
        * {{ 'common-edit.required' | translate }}
      </p>
  </div>


  <form #objForm="ngForm" class="form-standard" [hidden]="!(new || obj.id)" (ngSubmit)="save()">
    <div [ngClass]="{'animated fadeOut': saveLoading}">

      <p class="tabs">
        <button type="button" *ngFor="let t of tabs; let i = index" class="btn btn-lg" [ngClass]="{'btn-info': tab == i, 'btn-link': i != tab}" (click)="tab = i">{{ 'tabs.' + tabs[i] | translate }}</button>
      </p>

        <div [hidden]="tab != 0" class="row">
          <div  [ngClass]="{'col-lg-12': !obj.id, 'col-lg-9 col-md-6': obj.id}">
            <div class="col-lg-12">
              <div class="form-group">
                <div class="form-group">
                  <label for="title">{{'ticket.title' | translate}} *</label>
                  <input
                    type="text" class="form-control" id="title"
                    required
                    [(ngModel)]="obj.title" name="title" >
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="status">Status *</label>
                <div>
                  <div class="btn-group" ngbRadioGroup name="status" [(ngModel)]="obj.status">
                    <label ngbButtonLabel class="btn-info" *ngFor="let s of 'ticketStatus' | CoreEnumPipe">
                      <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="status">Area *</label>
                <div>
                  <div class="btn-group" ngbRadioGroup name="area" [(ngModel)]="obj.area">
                    <label ngbButtonLabel class="btn-info" *ngFor="let s of 'ticketArea' | CoreEnumPipe">
                      <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="status">{{'ticket.priority' | translate}} *</label>
                <div>
                  <div class="btn-group" ngbRadioGroup name="priotity" [(ngModel)]="obj.priority">
                    <label ngbButtonLabel class="btn-info" *ngFor="let s of 'ticketPriority' | CoreEnumPipe">
                      <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                    </label>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-lg-12">
              <div class="form-group">
                <label for="name">{{'ticket.original_request' | translate}}</label>
                <div>
                    <textarea [(ngModel)]="obj.text" class="form-control" rows="5" name="text"></textarea>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6" *ngIf="obj.id">

            <div class="card mt-4" >
              <div class="card-header">
                {{'ticket.user_request' | translate}}
              </div>
              <div class="card-body">
                <h5 class="card-title">{{ obj.user.lastname }} {{obj.user.firstname }}</h5>
                <p>
                  {{ obj.user.username }}
                </p>
                <p>
                  {{'ticket.richiesto' | translate}}: {{ obj.created_at | CoreDatePipe }}
                </p>
              </div>
            </div>
            <div class="card mt-4" >
              <div class="card-header">
                {{'ticket.user_risolutore' | translate}}
              </div>
              <div class="card-body" *ngIf="!obj.assigned_to_id">
                <h5 class="card-title text-danger">{{'ticket.not_assign' | translate}}</h5>
                <button class="btn btn-info" type="button" (click)="assign()" [ladda]="assignLoading">{{'ticket.assign' | translate}}</button>
              </div>
              <div class="card-body" *ngIf="obj.assigned_to_id">
                <h5 class="card-title text-danger">{{ obj.assigned_to.lastname }} {{obj.assigned_to.firstname }}</h5>
                <p>
                  {{ obj.assigned_to.username }}
                </p>
              </div>
            </div>


            <!-- <div class="well">
              <h4>Utente richiedente</h4>
              <p>
                <strong>Tizio Caio</strong>
              </p>
              <p>
                asdasd@grege.it
              </p>
              <p>
                Richiesto il: {{ obj.created_at | CoreDatePipe }}
              </p>
            </div> -->
          </div>
          <div class="col-lg-12">
            <div class="form-group mt-3">
              <button type="submit" class="btn btn-success btn-lg" [disabled]="!objForm.valid || !obj.type " [ladda]="saveLoading">{{ 'COMMON.save' | translate }}</button>
              <span class="btn" [hidden]="!saved"><strong>{{ 'COMMON.saved' | translate }}</strong></span>
            </div>
          </div>
        </div>

        <div [hidden]="tab != 1" class="row">
          <div class="col-lg-12">

            <div class="row">
              <div class="col-lg-7">
                <div class="card">
                  <div class="card-header">
                    {{'ticket.original_request_of' | translate}} {{ obj.user.lastname }} {{obj.user.firstname }}
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">{{obj.title}}</h5>
                    <p class="card-text" [innerHTML]="obj.text"></p>
                    <p>
                      <small>{{ obj.created_at | CoreDatePipe : 'full'}}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>


              <div class="row animated fadeIn" *ngFor="let note of obj.notes">
                <div class="col-lg-7" [ngClass]="{'offset-lg-5': note.user_id == obj.assigned_to_id}">
                  <div class="card mt-3">
                    <div class="card-header">
                      <span [hidden]="note.user_id != obj.user_id">{{'ticket.richiedente' | translate}}</span>
                      <span [hidden]="note.user_id == obj.user_id">{{'ticket.risolutore' | translate}}</span>
                    </div>
                    <div class="card-body">
                      <div [innerHTML]="note.text"></div>
                      <p>
                        <small>{{ note.created_at | CoreDatePipe : 'full'}}</small>
                      </p>
                      <p *ngIf="note.status_to && note.status_to != note.status_from">
                        {{'ticket.request_change' | translate}} <strong>{{ 'ticketStatus' | CoreLabelPipe : note.status_from  | translate}}</strong> {{'ticket.to_status' | translate}} <strong>{{ 'ticketStatus' | CoreLabelPipe : note.status_to  | translate}}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>


            <div class="row" *ngIf="obj.assigned_to_id == identity.user.id">
              <div class="col-lg-7 offset-lg-5">
                <div class="card mt-3">
                  <div class="card-header">
                    {{'ticket.request_change' | translate}}
                  </div>
                  <div class="card-body">
                    <p>
{{'ticket.add_answer' | translate}}                    
</p>

                    <div class="form-group">
                      <label for="noteText">{{'ticket.text_note' | translate}}</label>
                      <textarea type="text" class="form-control" id="noteText" name="noteText" [(ngModel)]="note.text" required="required" rows="5"></textarea>
                    </div>

                    <div class="form-group">
                      <label for="noteStatus">Status *</label>
                      <div>
                        <div class="btn-group" ngbRadioGroup name="noteStatus" [(ngModel)]="note.status_to">
                          <label ngbButtonLabel class="btn-info" *ngFor="let s of 'ticketStatus' | CoreEnumPipe" [hidden]="obj.status == s.id">
                            <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <button type="button" (click)="addNote()" class="btn btn-success" [disabled]="!note.text" [ladda]="noteLoading">{{'ticket.add' | translate}}</button>

                    </div>

                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>


    </div>



  </form>

</div>
