import { BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CoreUtilsService {
  private staticEndpoint?: string = '';

    public clean(filters: string[], data) {
      data = JSON.parse(JSON.stringify(data));
      for(var k in data) {
        if(filters.indexOf(k) != -1) {
          delete data[k];
        }
      }
      return data;
    }

    public pick(filters: string[], data): any {
      var r = {};
      for(var k = 0; k < filters.length; k++) {
        if(data[filters[k]] !== undefined) {
          r[filters[k]] = data[filters[k]];
        }
      }
      return r;
    }

    public omit(keys: string[], obj: any): any {
      let out = obj;
      (keys || []).map(key => {
        const { [key]: omitted, ...rest } = out;
        out = rest;
      });
      return out;
    }

    //replace an obj in a list of objects searching by id
    replace(list, obj) {
      if(obj && obj.id && list) {
        for(var i = 0; i < list.length; i++) {
          if(list[i].id == obj.id) {
            list[i] = obj;
          }
        }
      }
      return list;
    }

    public arrayLong(n: number): null[] {
      return Array(n).fill(null);
    }

    public renameObjKeys(keys: string[], newNames: string[], obj: any): any {
      keys.map((key, i) => {
        if (obj.hasOwnProperty(key) && key !== newNames[i]) {
          obj[newNames[i]] = obj[key];
          delete(obj[key]);
        }
      })

      return obj;
    }

  // Risolve le promise in maniera seriale e non parallela, accetta un array di funzioni che restituiscono promise
  // [ fn() => Promise, ... ]
  public promiseSerial(funcs: any): any {
    return funcs.reduce((promise, func) =>
        promise.then(result => func().then(Array.prototype.concat.bind(result))),
      Promise.resolve([]));
  }

  // Fisher-Yates Algorithm
  public shuffleArray(array: any[]): any[] {
    let m = array.length, t, i;
    // While there remain elements to shuffle…
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);
      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  }

  public changeColorBrightness(colorCode: string, amount: number): string {
    let usePound = false;

    if (colorCode[0] == "#") {
      colorCode = colorCode.slice(1);
      usePound = true;
    }

    let num = parseInt(colorCode, 16);

    let r = (num >> 16) + amount;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let b = ((num >> 8) & 0x00FF) + amount;
    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    let g = (num & 0x0000FF) + amount;
    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  // Da usare all'interno di un sort,
  // es. output.sort(this.utils.sortByProperty('x'))
  public sortByProperty(key: string, order?: 'asc' | 'desc'): any {
    return (a, b) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  public breakpointsAnalizer(
    observerResult: BreakpointState = { breakpoints: {}, matches: false }
  ) {
    // Questi valori si basano su quelli presenti in configurazione
    return {
      isPortrait: observerResult.breakpoints['(orientation: portrait)'],
      isLandscape: observerResult.breakpoints['(orientation: landscape)'],
      isXSmall: observerResult.breakpoints['(max-width: 599.99px)'],
      isSmall:
        observerResult.breakpoints[
          '(min-width: 600px) and (max-width: 959.99px)'
        ],
      isMedium:
        observerResult.breakpoints[
          '(min-width: 960px) and (max-width: 1279.99px)'
        ],
      isLarge:
        observerResult.breakpoints[
          '(min-width: 1280px) and (max-width: 1919.99px)'
        ],
      isXLarge: observerResult.breakpoints['(min-width: 1920px)']
    };
  }

  removeEmptyStrings(obj: any) {
    const newObj = {};
    Object.keys(obj).forEach(prop => {
      if (obj[prop] !== '' && obj[prop] !== undefined) {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  }

  removeEmptyProperties(obj: any) {
    const newObj = {};
    Object.keys(obj).forEach(prop => {
      if (obj[prop] !== '' && obj[prop] !== undefined && obj[prop] !== null) {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  }

  getStaticEndpoint(): string {
    return this.staticEndpoint;
  }

}
