
<div class="block block-m-t card">

  <div class="card-title" [hidden]="mode == 'inline'">
      <h4>{{ 'notification.title' | translate }}</h4>
  </div>

  <div class="card-subtitle mt-3" [hidden]="mode == 'inline'">
    <p class="text-muted">{{ 'notification.subtitle' | translate }}</p>
  </div>

  <hr/>


  <form #objForm="ngForm" class="card-body form-standard" (ngSubmit)="save()">
    <div [ngClass]="{'animated fadeOut': saveLoading}">

      <!-- <p class="tabs">
        <button type="button" *ngFor="let t of tabs; let i = index" class="btn btn-lg" [ngClass]="{'btn-info': tab == i, 'btn-link': i != tab}" (click)="tab = i">{{ 'tabs.' + tabs[i] | translate }}</button>
      </p> -->

      <div class="row">
        <div class="col-12">
          <h5>{{'notification.alarmSectionTitle' | translate}}</h5>
        </div>
        <div class="col-12">
          <p class="text-muted">{{'notification.alarmSectionDescription' | translate}}</p>
        </div>
      </div>
      <div class="row mb-4 pb-4">
        <div class="col-lg-6 mt-3">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="alarmPush" name="alarmPush" [(ngModel)]="obj.alarm_push">
            <label class="custom-control-label" for="alarmPush">{{'notification.alarmPush' | translate}}</label>
          </div>
        </div>
        <div class="col-lg-6 mt-3">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="alarmEmail" name="alarmEmail" [(ngModel)]="obj.alarm_email">
            <label class="custom-control-label" for="alarmEmail">{{'notification.alarmEmail' | translate}}</label>
          </div>
        </div>
      </div>

      <div class="row mt-4 pt-4">
        <div class="col-12">
          <h5>{{'notification.messageSectionTitle' | translate}}</h5>
        </div>
        <div class="col-12">
          <p class="text-muted">{{'notification.messageSectionDescription' | translate}}</p>
        </div>
      </div>
      <div class="row mb-4 pb-4">
        <div class="col-lg-6 mt-3">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="messagePush" name="messagePush" [(ngModel)]="obj.message_push">
            <label class="custom-control-label" for="messagePush">{{'notification.messagePush' | translate}}</label>
          </div>
        </div>
        <div class="col-lg-6 mt-3">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="messageEmail" name="messageEmail" [(ngModel)]="obj.message_email">
            <label class="custom-control-label" for="messageEmail">{{'notification.messageEmail' | translate}}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group mt-3">
      <button type="submit" class="btn btn-success btn-lg" [disabled]="!objForm.valid " [ladda]="saveLoading">{{ 'COMMON.save' | translate }}</button>
      <span class="btn" [hidden]="!saved"><strong>{{ 'COMMON.saved' | translate }}</strong></span>
    </div>

  </form>

</div>
