import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { UserService } from './';

@Component({
  selector: 'user-logout',
  template: '',
})


export class UserLogoutComponent implements OnInit {


  // set page (value) {
  //   this.search();
  // };

  constructor(
    // private service: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private service: UserService
  ) {}

  logout() : void {
    this.service.logout();
    setTimeout(() => {
      if(environment.APP_CONFIG.domainRedirect) {
        window.location.href = 'https://' + environment.APP_CONFIG.domainRedirect + '/#/user/logout';
      }
    })

  }

  ngOnInit(): void {
    this.logout();
  }

}
