
<div class="block block-m-t">

  <h1 [hidden]="mode == 'inline'">
    <!-- <span [hidden]="query._title">Elenco comunicazioni</span>
    <span [hidden]="!query._title">Elenco comunicazioni</span> -->
    {{ query._title || 'event.list' | translate }}
    <span [hidden]="! page || page == 1"> - {{ 'COMMON.page' | translate }} {{ page }}</span>

  </h1>



  <form #searchForm="ngForm" class="searchForm" (ngSubmit)="navigate(1)"  [hidden]="mode == 'inline' && !modeSelect && !forceForm" >
    <div class="row">
      <div class="col-lg-11">
        <div class="form-group" >
          <div class="btn-group" >
            <label for="thing_id" class="btn btn-default" style="min-width:auto;" >{{ 'device.title2' | translate }}</label>
            <input type="text"
              class="form-control"
              id="thing_id"
              style="height:34px"
              [(ngModel)]="query['thing_id[]']"
              name="thing_id">
          </div>

          <div class="btn-group" >
            <label class="btn btn-default">{{ 'COMMON.status' | translate }}</label>
            <div>
              <div class="btn-group" ngbRadioGroup name="type" [(ngModel)]="query.read">
                <label ngbButtonLabel class="btn-info">
                  <input ngbButton type="radio" [value]="true"> {{ 'event.read' | translate }}
                </label>
                <label ngbButtonLabel class="btn-info">
                  <input ngbButton type="radio" [value]="false"> {{ 'event.unread' | translate }}
                </label>
                <label ngbButtonLabel class="btn-info" [hidden]="undefined === query.read || null === query.read">
                  <input ngbButton type="radio" (click)="query.read = null"> <i class="fa fa-close"></i>
                </label>
              </div>
            </div>
          </div>

              <!--
              <div class="btn-group">
                <label for="degree" class="btn btn-default">Grado  </label>
                <select class="form-control" id="degree"
                   required
                   [(ngModel)]="query.degree_id" name="degree" >
                   <option [ngValue]="null">
                     Tutti
                   </option>
                   <option *ngFor="let c of data['degree']" [value]="c.id">
                     {{ c.name }}
                   </option>
                </select>
              </div>
              <div class="btn-group">
                <label for="degree" class="btn btn-default">Marchio  </label>
                <select class="form-control" id="brand"
                   required
                   [(ngModel)]="query.brand_id" name="brand" >
                   <option [ngValue]="null">
                     Tutti
                   </option>
                   <option *ngFor="let c of data['brand']" [value]="c.id">
                     {{ c.name }}
                   </option>
                </select>
              </div>

              <div class="btn-group" >
                <label class="btn btn-default">Tipo</label>
                <div>
                  <div class="btn-group" ngbRadioGroup name="type" [(ngModel)]="query.type">
                    <label ngbButtonLabel class="btn-info" *ngFor="let s of 'communicationType' | CoreLabelPipe : null : '*'">
                      <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                    </label>
                    <label ngbButtonLabel class="btn-info" [hidden]="!query.type">
                      <input ngbButton type="radio" (click)="query.type = ''"> <i class="fa fa-close"></i>
                    </label>
                  </div>
                </div>
              </div>
            -->

        </div>
      </div>
      <div class="col-lg-1 text-right">
        <div class="form-group">
          <button class="btn btn-outline-info" type="submit"><i class="fa fa-search"></i></button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="form-group" >
          <div class="form-group">
            <button class="btn btn-outline-info"
              type="button"
              (click)="setReadStatus(true)"
              [disabled]="isUpdateButtonDisabled">
              {{ 'event.setRead' | translate }}
            </button>
            <button class="btn btn-outline-info"
              type="button"
              (click)="setReadStatus(false)"
              [disabled]="isUpdateButtonDisabled">
              {{ 'event.setUnread' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>


  <div class="overflow-x-scroll" [coreLoader]="loading">

    <table class="table table-bordered">
      <thead class="">
        <tr class="bg-dark lead" [hidden]="mode == 'inline'">
          <th class="text-center " colspan="10">
            {{'event.title' | translate}}
          </th>
        </tr>

        <tr>
          <th class="text-center">
            <input type="checkbox" [(ngModel)]="selectAll" (change)="toggleAllSelections(selectAll)">
          </th>
          <th class="text-center">
            {{'device.title2' | translate}}
          </th>
          <th class="text-center">
            {{'COMMON.date' | translate}}
          </th>
          <th class="text-center">
            {{'COMMON.message' | translate}}
          </th>
          <th class="text-center">
            {{'COMMON.read' | translate}}
          </th>

        </tr>
      </thead>
      <tbody *ngIf="loaded">

        <tr *ngFor="let obj of list; let i = index" class="animated fadeIn">
          <td class="text-center">
            <input type="checkbox" [(ngModel)]="selectedValues[obj.id]" [checked]="selectedValues[obj.id]" (change)="toggleSelection(selectedValues[obj.id])">
          </td>
          <td class="text-center">
            {{ obj.thing_id }}
          </td>
          <td  class="text-center" >
              {{ obj.created_at | CoreDatePipe : 'full' }}
          </td>
          <td class="text-center">
            {{ obj.text }}
          </td>
          <td  class="text-center" >
          <i class="fa fa-check" *ngIf="obj.read"></i>
          </td>


          <!-- <td>
            <button type="button" class="btn btn-sm btn-outline-info" routerLink="/event/edit/{{ obj.id }}">Gestisci</button>
          </td> -->
        </tr>
      </tbody>
    </table>

    <ng-container *ngIf="mode == 'inline'" >
      <!-- <p class="mb-0">
        <button type="button" class="btn btn-sm btn-info btn-block-inline" (click)="add(true)" [ngClass]="{'btn-block-inline-active': newInlineChild}"><span [hidden]="newInlineChildId">{{'COMMON.create' | translate}}</span><span [hidden]="!newInlineChildId">{{'COMMON.edit2' | translate}}</span> <i [hidden]="!newInlineChild" class="fa fa-arrow-down animated fadeIn"></i></button>
        <button type="button" class="btn btn-sm animated fadeIn btn-block-inline" (click)="add(false)" [hidden]="!newInlineChild" ><i class="fa fa-close"></i></button>
      </p> -->
      <div class="block-inline" *ngIf="newInlineChild">
        <event-edit  [mode]="mode" [paramsIn]="{id: newInlineChildId}" [objIn]="objIn"></event-edit>
      </div>
    </ng-container>


    <p [hidden]="loaded && list.length || !loaded" class="text-center">
      {{'COMMON.nothing' | translate}}
    </p>

    <hr style="margin:50px 0"/>

  </div>
</div>
<core-pagination-nav [pageInfo]="pageInfo" [(page)]="page" (changed)="navigate($event)"  ></core-pagination-nav>
