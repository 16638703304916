import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { CoreStorageService } from '../core';
import { UserService } from './';
// import { AppState } from '../app.service';

// import 'rxjs/add/operator/switchMap';
@Component({
  selector: 'user-login',
  templateUrl: './user-login.component.html',
  // providers: [
  //   UserService
  // ]
})
export class UserLoginComponent implements OnInit {
  // set page (value) {
  //   this.search();
  // };
  constructor(
    // private service: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private service: UserService,
    public storage: CoreStorageService,
  ) {
  }
  username = "";
  password = "";
  loginLoading = false;
  error: boolean = false;
  previousUrl = "";



  login(): void {
    this.loginLoading = true;
    this.error = false;

    this.service.login(this.username, this.password).then((data) => {
      if (data.body.user.role) {
        this.service.loadData(data.body.user.role).then((data2) => {

          setTimeout(() => {
            this.loginLoading = false;
            // if(this.storage.retrieve("url")) {
            //   this.router.navigateByUrl(this.storage.retrieve("url"));
            //   this.storage.store("url", "");
            // }
            // else {
            // data.body.user.organization.tag = "prova";
            const redirectHash = this.route.snapshot.queryParamMap.get('r');
            /**
             * Se esiste un link di bring back dopo il login faccio il redirect
             * N.B. Do per assodato che se è presente allora mi trovo già nel sottodominio giusto
             */

            if (redirectHash) {
              this.router.navigate([atob(redirectHash)]);
            } else if (environment.APP_CONFIG.domain && data.body.user && data.body.user.organization && data.body.user.organization.tag) {
              var path = "/#/user/list/1";
              if(data.body.user.role == 'ROLE_USER' || data.body.user.role == 'ROLE_PARENT') {
                path = '/#/user/edit/' + data.body.user.id
              }
              document.cookie = "token=" + data.body.access_token + ";path=/;domain=" + environment.APP_CONFIG.domain;
              document.cookie = "url=" + path + ";path=/;domain=" + environment.APP_CONFIG.domain;
              setTimeout(() => {
                console.log("USER", data.body.user);
                this.storage.clear();
                this.storage.store("identity", { role: 'ROLE_GUEST'});

                window.location.href = 'https://' + data.body.user.organization.tag + "." + environment.APP_CONFIG.domainRedirect + "/#/user/redirect";
                // window.location.href = "http://localhost:3000/#/user/redirect";

              }, 250)
            }
            else {
              this.router.navigate(['/main/dashboard/']);
            }

            // }
          }, 100);
        });
      }
      else {
        this.loginLoading = false; this.error = true;
      }
    }, () => { this.loginLoading = false; this.error = true; })

  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      //this.search(params.page);
    });
    // this.route.paramMap.subscribe( params => {
    //   // this.params = this.service.copyParams(params);
    //   console.log("parammap", params.params);
    //   this.params = params.params
    // });

  }

}