// import { Bootstrap }    from '@angular/platform-browser-dynamic';
import {Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
// import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CoreModalService } from '../';
import { Router } from '@angular/router';


// bootstrap(AppModalService);
@Injectable(
)
export class CoreHttpInterceptorError implements HttpInterceptor {

  constructor(private modalService: CoreModalService, public router: Router) {}

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).do((event: HttpEvent<any>) => {
       if (event instanceof HttpResponse) {
         // do stuff with response if you want
       }
     }, (err: any) => {
       if (err instanceof HttpErrorResponse && this.shouldBeHandled(req) && err.status) {

         var errorMessage = 'ERROR.generic';
         if (err.status === 404) {
           var errorMessage = 'ERROR.404';
         }
         else if (err.status === 409) {
           var errorMessage = 'ERROR.409';
         }
         else if (err.status === 401) {
           var errorMessage = 'ERROR.401';
           this.router.navigate(['/user/login/']);
         }
         else if (err.status === 403) {
           var errorMessage = "ERROR.403";
           this.router.navigate(['/user/login/']);

         }
         else if (err.status === 406) {
           var errorMessage = "ERROR.406";
         }

         if(err.url.indexOf(".amazonaws.com") > -1) {
           errorMessage = "";
         }
         if(errorMessage) {
           this.modalService.alert({viewVars: { message: errorMessage }});
         }
        //  console.log("ERR, err", err);
           // redirect to the login route
           // or show a modal
       }
     });
  }

  private shouldBeHandled(req: HttpRequest<any>): boolean {
    const shouldBeIgnored = req.params['customParams'] && req.params['customParams']['ignoreError'];
    return !shouldBeIgnored;
  }
}
