import { CoreHttpModelBase } from './../core';

export class Task extends CoreHttpModelBase {
    id: number
    message: string
    type: string
    created_at: string
    edited_at: string
    name?: any;
    year?: any;
    brand_id?: any;
    area_id?: any;
    degree_id?: any;
    note?: any;
    loadings: any = {}
}

export const TASK_FILTERS = {
  post: [],
  patch: [ ]
}
