import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'core-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class CoreChipComponent implements OnInit {
  @Input() label: string;
  @Input() count?: number
  @Input() color: 'green' | 'red' | 'orange' | 'grey' | 'yellow';
  @Input() size: 'small' | 'standard' = 'standard';
  @Input() disclosable?: boolean = false;
  @Input() selected?: boolean = false;
  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
