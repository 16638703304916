import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreHttpModelParser, CoreUtilsService } from './../core';
import 'rxjs/add/operator/map';
import { Device, DEVICE_FILTERS } from './';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends CoreHttpModelParser {

  constructor(
    public http: HttpClient,
    public utils: CoreUtilsService
  ) { super(); }

  entityName = 'thing';

  public get(id: any, params?: {}, query?: {}): any {
    params = {};
    let p = new Promise((resolve, reject) => {
      if(!id) {
        id = "";
      }
      else {
        id = "/" + id;
      }
      if(params && params['id']) {
        delete params['id'];
      }
      this.http.get(environment.APP_CONFIG.endpointDevice + this.entityName + id, { params: Object.assign(this.objToQuery(params), this.objToQuery(query)), observe: 'response' }).toPromise().then(res => {
        resolve(this.parse(res, Device));
      }, err => reject(err));
    });
    return p;
  }


public initDownload(id) {

  return this.http.post("https://log.sc365.enetec.info/v1/" + 'log/' + id, {}).toPromise()


}
public download(url, ) {

  return this.http.get(url,  {observe: 'response', responseType: 'blob'}).toPromise()


}



  public geoStat(lat, lon, zoom): any {


    return this.http.get(environment.APP_CONFIG.endpointDevice + 'geo', { params: {lat: lat, lon:lon, zoom: zoom} }).toPromise()
    /*
    var p = new Promise((resolve, reject) => {
      var list = [];


    var k = 14 * (parseInt(zoom) + 1)

      for(var i = 0; i < k; i++) {
        list.push({
          lat: lat + (((Math.random() * (1 - -1) + -1).toFixed(2)) * (3.2-parseInt(zoom))),
          lon: lon + (((Math.random() * (1 - -1) + -1).toFixed(2)) * (3.2-parseInt(zoom))),
          num: (Math.floor(Math.random() * 5) + 1) * (3-parseInt(zoom)) || 1
        })
      }



      resolve(list);


    });
    return p;*/
  }


  public oemStat():any {

    return this.http.get(environment.APP_CONFIG.endpointDevice + 'oem').toPromise()
    /*var p = new Promise((resolve, reject) => {
      resolve([
          {
            id: 1,
            name: "Oem 1",
            num: Math.floor(Math.random() * 150) + 30
          },
          {
            id: 2,
            name: "Oem 2",
            num: Math.floor(Math.random() * 150) + 30
          },
          {
            id: 3,
            name: "Oem 3",
            num: Math.floor(Math.random() * 150) + 30
          },
          {
            id: 4,
            name: "Oem 4",
            num: Math.floor(Math.random() * 150) + 30
          },
          {
            id: 5,
            name: "Oem 5",
            num: Math.floor(Math.random() * 150) + 30
          },
          {
            id: 6,
            name: "Oem 6",
            num: Math.floor(Math.random() * 150) + 30
          }
      ])
    });

    return p;*/
  }


  public deviceStat():any {


    return this.http.get(environment.APP_CONFIG.endpointDevice + 'activation').toPromise()
    /*
    var p = new Promise((resolve, reject) => {
      var list = [];
      var months = ["2019-01-01", "2018-12-01", "2018-11-01", "2018-10-01", "2018-09-01", "2018-08-01", "2018-07-01", "2018-06-01", "2018-05-01", "2018-04-01", "2018-03-01", "2018-02-01"]

      for (var k = 0; k < 12; k++){
        list.push({
          label: months[11-k],
          num: Math.floor(Math.random() * 80) + (10 * k)
        })
      }

      resolve(list);

    });

    return p;*/
  }



  public post(data): any {
    let p = new Promise((resolve, reject) => {
        this.http.post(environment.APP_CONFIG.endpointDevice + this.entityName,  this.utils.pick(DEVICE_FILTERS['post'], data), {observe: 'response'}).toPromise().then(res => {
          console.log(JSON.stringify(res));
          resolve(this.parse(res, Device));
        }, err => reject(err));
    });
    return p;
  }
  public addsupport(id:any, user_id:any): any {
    var d = new Date();
    d.setMonth(d.getMonth()+1);
    return this.http.post(environment.APP_CONFIG.endpointAcl + "acl-user", {
      thing_id: id,
      user_id: String(user_id),
      ttl: d.getTime() / 1000} , {observe: 'response'}).toPromise()
  }

  public patch(id, data: any): Promise<any> {
    let p = new Promise((resolve, reject) => {
        this.http.patch(environment.APP_CONFIG.endpointDevice + this.entityName + '/' + id, this.utils.pick(DEVICE_FILTERS['patch'], data), {observe: 'response'}).toPromise().then(res => {
          resolve(this.parse(res, Device));
        }, err => reject(err));
    });
    return p;
    // return this.http.patch(environment.APP_CONFIG.endpointDevice + this.entityName + '/' + id, this.utils.pick(DEVICE_FILTERS['patch'], data), {observe: 'response'}).toPromise();
  }

  public delete(id): Promise<any> {
    return this.http.delete(environment.APP_CONFIG.endpointDevice + this.entityName + '/' + id, {observe: 'response'}).toPromise();
  }

  public searchAddress(address) {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?key=${environment.APP_CONFIG.gMapsKey}&address=` + address, {observe: 'response'}).toPromise();
  }

  public getTelemetry(SN: string, id: string, group?, range?): any {
    let p = new Promise((resolve, reject) => {
      let params = {limit: "24",  order: "desc", thing_id: id, sensor: SN};
      if(range) {
        params['created'] = range;
      }
      if(group) {
        params['group'] = group;
      }
      this.http.get(environment.APP_CONFIG.endpointTelemetry + 'telemetry', { observe: 'response', params: params }).toPromise().then(res => {
        resolve(res);
      }, err => reject(err));
    });
    return p;
  }
  public getUsers(SN: string): any {
    let p = new Promise((resolve, reject) => {
      this.http.get(environment.APP_CONFIG.endpointAcl + 'thing/' + SN).toPromise().then(res => {
        resolve(res);
      }, err => reject(err));
    });
    return p;
  }

  // public get(): Promise<any> {
  //    return this.http.get('https://academy.universe.corley.it/v1/course', { observe: 'response' }).toPromise();
  // }

}
