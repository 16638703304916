<div class="modal-header">
   <h4 class="modal-title">{{  title || 'MODAL.confirmTitle' | translate }}</h4>
 </div>
 <div class="modal-body" *ngIf="message">
   <p>{{ message | translate  }}</p>
 </div>
 <div class="modal-footer">
   <button type="button" class="btn btn-outline-info" (click)="activeModal.close(true)">{{ ok || 'MODAL.confirmOk' | translate  }}</button>
   <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">{{ cancel || 'MODAL.confirmCancel' | translate  }}</button>
 </div>
