<div ngbDropdown #calendarPanel="ngbDropdown" class="display-inline-block py-1">
  <div class="input-group">
    <input
      class="form-control"
      type="text"
      name="data"
      [(ngModel)]="dataTxt"
      placeholder="{{ placeholder | translate }}"
      #item
      (input)="change(item.value)"
    />
    <button
      class="datepicker-clearform"
      type="button"
      *ngIf="isComparison && !!dataTxt"
      (click)="removeComparison()"
    >
      <i class="fa fa-times"></i>
    </button>
    <div class="input-group-append">
      <button class="btn btn-outline-secondary dropdown-toggle-split" ngbDropdownToggle>
        <div class="calendar" aria-hidden="true"></div>
      </button>
    </div>
  </div>
  <div ngbDropdownMenu class="dropdown-menu" >
    <div class="row">
      <button class="btn btn-link col-4" (click)="addYear($event,-1)">
        <span class="ngb-dp-navigation-arrow left"></span>
      </button>

      <button *ngIf="!displayYear" class="btn btn-link col-4" (click)="showYear($event,true)">{{data.year}}</button>
      <button *ngIf="displayYear" class="btn btn-link col-4" (click)="showYear($event,false)">{{incr+1}}-{{incr+10}}</button>

      <button class="btn btn-link col-4" (click)="addYear($event,+1)" [disabled]="data.year===lastAvailableYear">
        <span class="ngb-dp-navigation-arrow right"></span>
      </button>

      <div *ngFor="let month of months; let i = index; let first = first; let last = last"
        class="col-4"
        (click)="((displayYear || isYear) && i+incr<lastAvailableYear) || (!(displayYear || isYear) && (data.year<lastAvailableYear || (data.year==lastAvailableYear && i+1<lastAvailableMonth))) ? selectYearMonth($event, i) : $event.stopPropagation()"
      >
        <div  class="card-body"
          [ngClass]="{
            'select': !displayYear && !isYear ? model?.start && (i+1) == (data.month + 1) : model?.start && (i+incr) == data.year,
            'suggested': isSuggested(i),
            'outrange': !(
              (
                (displayYear || isYear) &&
                i+incr<lastAvailableYear
              ) ||
              (
                !(displayYear || isYear) &&
                (
                  data.year<lastAvailableYear ||
                  (data.year==lastAvailableYear && i+1<lastAvailableMonth)
                )
              )
            )
          }"
        >
          <span *ngIf="!displayYear && !isYear">{{ "MONTHS." + month | translate}}</span>
          <span *ngIf="displayYear || isYear">{{i+incr}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
