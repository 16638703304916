<div class="row" >
  <div class="col-lg-12">


    <div class="row" *ngIf="identity && identity.user">
      <div class="col-lg-4">
        <div class="dash-box dash-box-6"  (click)="show = 'profile'" [ngClass]="{'dash-box-selected': show == 'profile'}">
          <p class="ic" [hidden]="show == 'profile'"><img src="/assets/portal/profilo.svg" alt="" ></p>
          <p class="ic" [hidden]="show != 'profile'"><img src="/assets/portal/white/profilo.svg" alt="" ></p>
          <p>{{'profile.title' | translate}}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="dash-box dash-box-7"  (click)="show = 'events'"  [ngClass]="{'dash-box-selected': show == 'events'}">
          <p class="ic" [hidden]="show == 'events'"><img src="/assets/portal/notifiche.svg" alt="" ></p>
          <p class="ic" [hidden]="show != 'events'"><img src="/assets/portal/white/notifiche.svg" alt="" ></p>
          <p>{{'main.title_notify' | translate}}</p>
        </div>
      </div>
      <div class="col-lg-4"  *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_OEM'">
        <div class="dash-box dash-box-5"   (click)="show = 'support1'" *ngIf="identity.role == 'ROLE_ADMIN'" [ngClass]="{'dash-box-selected': show == 'support1'}">
          <p class="ic" [hidden]="show == 'support1'"><img src="/assets/portal/supporto.svg"  alt="" ></p>
          <p class="ic" [hidden]="show != 'support1'"><img src="/assets/portal/white/supporto.svg" alt="" ></p>
          <p>{{'COMMON.support' | translate}}</p>
        </div>
        <div class="dash-box dash-box-5"   (click)="show = 'support2'" *ngIf="identity.role == 'ROLE_OEM'" [ngClass]="{'dash-box-selected': show == 'support2'}">
          <p class="ic" [hidden]="show == 'support2'"><img src="/assets/portal/supporto.svg"  alt="" ></p>
          <p class="ic" [hidden]="show != 'support2'"><img src="/assets/portal/white/supporto.svg" alt="" ></p>
          <p>{{'COMMON.support' | translate}}</p>
        </div>
      </div>
      <div class="col-lg-4" *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_OEM'" (click)="show = 'search'" >
        <div class="dash-box dash-box-5"  [ngClass]="{'dash-box-selected': show == 'search'}">
          <p class="ic" [hidden]="show == 'search'"><img src="/assets/portal/lente.svg" alt="" ></p>
          <p class="ic" [hidden]="show != 'search'"><img src="/assets/portal/white/lente.svg" alt="" ></p>
          <p>{{'device.find' | translate}}</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="dash-box dash-box-6" (click)="show = 'devices'"   [ngClass]="{'dash-box-selected': show == 'devices'}">
          <p class="ic" [hidden]="show == 'devices'"><img src="/assets/portal/lista.svg" alt="" ></p>
          <p class="ic" [hidden]="show != 'devices'"><img src="/assets/portal/white/lista.svg" alt="" ></p>
          <p>{{'main.list' | translate}}</p>
        </div>
      </div>
      <div class="col-lg-4" *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_OEM'">
        <div class="dash-box dash-box-7"(click)="show = 'users'"  [ngClass]="{'dash-box-selected': show == 'users'}">
          <p class="ic" [hidden]="show == 'users'"><img src="/assets/portal/utente.svg" alt="" ></p>
          <p class="ic" [hidden]="show != 'users'"><img src="/assets/portal/white/utente.svg" alt="" ></p>
          <p>{{'user.list2' | translate}}</p>
        </div>
      </div>

<!--      DEV-->
<!--      <div class="col-lg-4">-->
<!--        <div class="dash-box dash-box-7"  (click)="show = 'advice'"  [ngClass]="{'dash-box-selected': show == 'advice'}">-->
<!--          <p class="ic" [hidden]="show == 'advice'"><img src="/assets/portal/consigli.svg" alt="" ></p>-->
<!--          <p class="ic" [hidden]="show != 'advice'"><img src="/assets/portal/white/consigli.svg" alt="" ></p>-->
<!--          <p>{{'main.advice' | translate}}</p>-->
<!--        </div>-->
<!--      </div>-->
    </div>

  </div>
</div>
<div class="row" *ngIf="loaded" >

  <div class="col-lg-12" *ngIf="show == 'profile'"  >
    <!-- routerLink="/user/edit/{{ identity.user.id }}"-->
    <user-edit [paramsIn]="{id: identity.user.id}" ></user-edit>
  </div>

  <div class="col-lg-12" *ngIf="show == 'events'"  >
    <!-- routerLink="/user/edit/{{ identity.user.id }}"-->
    <event-list  forceForm = "true" [queryIn]="{'orderBy': 'created_at', 'order': 'DESC'}" mode="inline"></event-list>
  </div>

  <div class="col-lg-12" *ngIf="show == 'support1'"  >
    <!-- routerLink="/user/edit/{{ identity.user.id }}"-->
    <ticketmaster-list  forceForm = "true" [queryIn]="{page: 1, 'orderBy': 'created_at', 'order': 'DESC'}" mode="inline"></ticketmaster-list>
  </div>

  <div class="col-lg-12" *ngIf="show == 'support2'"  >
    <!-- routerLink="/user/edit/{{ identity.user.id }}"-->
    <ticket-list  forceForm = "true" [queryIn]="{page: 1, 'orderBy': 'created_at', 'order': 'DESC'}" mode="inline"></ticket-list>
  </div>

  <div class="col-lg-12" *ngIf="show == 'devices'"  >
    <!-- routerLink="/user/edit/{{ identity.user.id }}"-->
    <device-list  forceForm = "true" [queryIn]="{page: 1, _title: 'I tuoi dispositivi', _section: 'device.mine', orderBy:'created_at', order:'DESC'}"  mode="inline"></device-list>
  </div>

  <div class="col-lg-12" *ngIf="show == 'users'"  >
    <!-- routerLink="/user/edit/{{ identity.user.id }}"-->
    <user-list  forceForm = "true" mode="inline" [queryIn]="{organization_id: null, role: null, orderBy: 'signupDate', order: 'DESC'}"></user-list>
  </div>

  <div class="col-lg-12" *ngIf="show == 'advice'"  >
    <!-- routerLink="/user/edit/{{ identity.user.id }}"-->
    <advice-list forceForm = "true" [queryIn]="{'orderBy': 'created_at', 'order': 'DESC'}" mode="inline"></advice-list>
  </div>



  <div class="col-lg-12" *ngIf="(identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_OEM') && show == 'search'"  >
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group" >
           <div class="btn-group" >
            <label for="thing_id" class="btn btn-default" style="min-width:auto;" >{{'user.title2' | translate}}</label>
            <input type="text" class="form-control" id="thing_id" style="height:34px" [(ngModel)]="searchUserF" name="thing_id">
            <button class="btn btn-outline-info" type="button"  style="margin-left:15px;"(click)="searchUser()"><i class="fa fa-search"></i></button>

          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group text-right" >
           <div class="btn-group" >
            <label for="thing_id" class="btn btn-default" style="min-width:auto;" >{{'main.address_map' | translate}}</label>
            <input type="text" class="form-control" id="thing_id" style="height:34px" [(ngModel)]="searchMapF" name="thing_id">
            <button class="btn btn-outline-info" type="button"  style="margin-left:15px;"(click)="searchMap()"><i class="fa fa-search"></i></button>

          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="searchUserDo">
      <div class="col-lg-12">
        <user-list [queryIn]="{page: 1, lastname: searchUserF}" mode="inline"></user-list>
      </div>
    </div>
  </div>

  <div class="col-lg-6" *ngIf="(identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_OEM') && show == 'home'" [ngClass]="{'col-lg-9': identity.role == 'ROLE_OEM'}" >
    <div class="block dashboard-bar" *ngIf="activationsLoded">
      <core-chart-base
        [title]="'main.chart1' | translate"
        [colors]="['success']"
        [labels]="activationsLabels"
        [values]="activationsValues"
        [legend] = "false"
        [type] = "'bar'"
      ></core-chart-base>
    </div>
  </div>

  <!-- <div class="col-lg-3" *ngIf="(identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_OEM') && show == 'home'" >
    <div class="block dashboard-bar" *ngIf="activationsLoded" >
      <core-chart-gauge
      [title]="'main.chart2' | translate"
        label="{{ 'chart.on' | translate }} {{ totalDevices }}"
        [append]="''"
        [thick]="10"
        [value]="nullDevices"
        [max]="totalDevices"
        [type]="'full'"
        [color]="'danger'"
      ></core-chart-gauge>
    </div>
  </div> -->

  <div class="col-lg-3" *ngIf="oems.length && show == 'home'">
    <div class="block">
        <div class="chart-heading">{{'COMMON.label-oem' | translate}}</div>
      <table class="table table-sm table-bordered table-striped">
        <thead>
          <tr class="text-center">
            <th>OEM</th>
            <th>{{'device.title' | translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let o of oems"  class="text-center">
            <td>{{ o.name }}</td>
            <td>{{ o.num }}</td>
          </tr>
      </table>
    </div>
  </div>


  <div class="col-lg-12" *ngIf="(identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_OEM') && center && center.lat && center.lon && show == 'search'">

    <div class="block">
      <!-- <agm-map [latitude]="center.lat" [longitude]="center.lon" [zoom]="center.zoom"  style="height:600px" [scrollwheel]="false">
        <agm-marker
           *ngFor="let obj of list"
           [latitude]="obj.lat"
           [longitude]="obj.lon"
           label="{{ '' + obj.num }}"
           [markerDraggable]="false"
           (markerClick)="searchGeo(obj.lat, obj.lon, true)">

       </agm-marker>
      </agm-map> -->
    </div>
    <div class="row">
      <div class="col-lg-12">
      <button class="btn btn-default pull-right"  (click)="resetZoom()">Reset zoom</button>
      </div>
    </div>
  </div>




<!--
  <div class="col-lg-6">
    <div class="block">
      <core-chart-base
        [title]="'Andamento progressivo'"
        [colors]="['info', 'success']"
        [labels]="chartLabels"
        [values]="chartValues"
        [legend] = "false"
      ></core-chart-base>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="block">
      <core-chart-base
        [title]="'Andamento progressivo'"
        [colors]="['danger', 'primary']"
        [labels]="chartLabels"
        [values]="chartValues"
        [legend] = "false"
        [type] = "'bar'"
      ></core-chart-base>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="block">
      <core-chart-base
        [title]="'Ripartizione giornaliera'"
        [colors]="['danger', 'primary']"
        [labels]="chartLabels"
        [values]="chartValues"
        [legend]="false"
        [type]="'radar'"
      ></core-chart-base>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="block ">
      <core-chart-base
        [title]="'Ripartizione Aree'"
        [labels]="['Casa', 'Ufficio', 'Mare', 'Montagna']"
        [colors]="['danger', 'primary', 'success', 'warning']"
        [values]="chartValues2"
        [type] = "'doughnut'"
      ></core-chart-base>
    </div>
  </div>
  <div class="col-lg-3">
    <div class="block">
      <core-chart-gauge
        [title]="'Altro Grafico'"
        [label]="'Test'"
        [append]="'%'"
        [value]="29.9"
        [max]="60"
        [type]="'semi'"
        [color]="'warning'"
        [schema]="{'danger': 30, 'success': 0}"
      ></core-chart-gauge>
    </div>
  </div>
  <div class="col-lg-3">
    <div class="block">
      <core-chart-gauge
        [title]="'Altro Grafico'"
        [label]="'Test'"
        [append]="'%'"
        [value]="52"
        [thick]="10"
        [max]="100"
        [type]="'arch'"
        [color]="'success'"
      ></core-chart-gauge>
    </div>
  </div>
  <div class="col-lg-3">
    <div class="block bg-danger">
      <core-chart-gauge
        [title]="'Altro Grafico'"
        [label]="'Test'"
        [append]="'%'"
        [value]="29.9"
        [max]="100"
        [thick]="10"
        [type]="'full'"
        [color]="'light'"
      ></core-chart-gauge>
    </div>
  </div>
  <div class="col-lg-3">
    <div class="block">
      <core-chart-gauge
        [title]="'Altro Grafico'"
        [label]="'Test'"
        [append]="'$'"
        [thick]="10"
        [value]="129"
        [max]="150"
        [type]="'full'"
        [color]="'dark'"
      ></core-chart-gauge>
    </div>
  </div>

  <div class="col-lg-4">
    <div class="block chart-inline">
        <core-chart-index [title]="'Indice avanzamento'" color='warning' value="77" max="100" [description]="'Questo è un valore importato direttamente'" [schema]="{'primary': 30, 'success': 0}"  ></core-chart-index>
        <core-chart-index [title]="'Indice redirezione'" color='info' value="44" max="100" [description]="'Altro esempio di barra di valore'" ></core-chart-index>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="block chart-inline" >
      <core-chart-trend title="Andamento critico" [value]="[1,2,3,4,5,6,7,8.2,5,4,40,2]" [schema]="{'warning': 30, 'success': 0}" color="info"></core-chart-trend>
      <core-chart-trend [title]="'Andamento errori'" [value]="[0,3,10,0,0]" color="danger"></core-chart-trend>
      <core-chart-trend [title]="'Vendite ultimi 7 gg'" [value]="[0,3,10,0,0]" color="success"></core-chart-trend>
      <core-chart-trend [title]="'Bounce visitatori'" [value]="[50,0, 50,0,50]" color="dark"></core-chart-trend>
      <core-chart-trend [title]="'Utilizzo sistema'" [value]="[1,10,5,4,2,1]" color="info"></core-chart-trend>
    </div>
  </div>
  -->

</div>
