import { Injectable } from '@angular/core';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class CoreStorageService {

  constructor(
    private storage: LocalStorageService
  ) {
  }

  staticData = {};

  public store ( key:string, value:any, noStatic?: boolean ):void {
    this.storage.store(key, value);
    if(noStatic !== true) {
      this.staticData[key] = value;
    }
  }

  public retrieve(key: string, fromStatic?: boolean) {
    if (fromStatic === true && this.staticData[key]) {
      return this.staticData[key];
    } else {
      return this.storage.retrieve(key);
    }
  }

  public clear(key?: string): void {
    this.storage.clear(key);
  }

  public observe(key?: string): Observable<any> {
    return this.storage.observe(key);
  }

}

export function CoreStorage(key?: string, d?: any) {
  return LocalStorage(key, d);
}
