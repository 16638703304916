import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart } from '@angular/router';
import { NgProgress } from '@ngx-progressbar/core';

import { AdviceService } from './';
import { Event } from 'app/event/event.type';

import { CoreStorageService, CoreModalService, CoreMessageService, CoreUtilsService } from '../core'

@Component({
  selector: 'advice-list',
  templateUrl: './advice-list.component.html',
})


export class AdviceListComponent implements OnInit {

  constructor(
    private service: AdviceService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: CoreStorageService,
    private progress: NgProgress,
    private modal: CoreModalService,
    private message: CoreMessageService,
    private utils: CoreUtilsService,

  ) { }

  @Input() list : Event[] = []; // list of entites (by remote service or by parent in inline mode)
  @Input() queryIn : any; // query params (for remote service in inline mode)
  @Input() objIn: any = {}; // object passed to child edit component
  @Input() mode = ""; // 'inline' when used by parent
  @Input() modeSelect = false; // true when the inline mode is used to select element
  @Input() forceForm = false; // used to show the search form (usually in inline views)
  pageInfo: any = {}; // pagination
  params: any = {};
  query: any = {degree_id: null, brand_id: null};
  page: 0;
  newInlineChild: boolean = false; // controls to show inline child edit view
  newInlineChildId: number = 0;

  loaded: boolean = false;
  loading: Promise<any>
  data: any = {}; // data from core storage

  acl: "view" | "edit" = "edit";
  identity: any = {}
  selectAll: boolean = false;
  selectedValues: any = {};
  isUpdateButtonDisabled: boolean = true;


  ngOnInit(): void {


    this.identity = this.storage.retrieve('identity') || {};

    // query by parent component
    if(this.queryIn) {
      this.query = this.queryIn;
      this.search();
    }
    // watch queris in normal mode
    else if(this.mode != "inline"){
      this.route.queryParams.subscribe( queryParams => {
        this.query = Object.assign(this.query, queryParams);
        this.page = queryParams.page || 1;
        this.search();
      });
    }
    else {
      this.loaded = true;
    }

    //advices to interact with child and parent
    this.message.get("advice.new").subscribe(m => { this.newInlineChild = false; this.list.push(m); })
    this.message.get("advice.changed").subscribe(m => { this.newInlineChild = false; this.list = this.utils.replace(this.list, m) })

    // retrieve data for storage
    this.data = this.storage.retrieve("data");

  }

  // uses service to get remote entitites
  search(): void {
    this.selectAll = false;

    this.progress.start();
    this.loading = this.service.get(false, this.params, this.query).then(res => {
      this.list = res.models;
      this.selectAll = (this.list || []).map(el => this.selectedValues[el.id]).reduce(
        (acc: boolean, status: boolean): boolean => acc && status,
        true
      )
      setTimeout(() => {
        this.pageInfo = res.pagination;
        this.loaded = true;
        this.progress.complete();
      }, 250)
    }, err => {
      this.loaded = true; this.progress.complete();
    });
  }

  // merge params and navigate to new path (or call "search" method if in inline mode)
  navigate(page): void {
    if(page) {
      this.progress.start();
      this.params['page'] = page;
      this.query['page'] = page;
      var path = this.route.routeConfig.path;
      var params = this.service.objToQuery(this.params);
      for(var k in params) {
        path = path.replace(":" + k, params[k]);
      }
      // go to path o call seach in inline mode
      if(this.mode == 'inline') {
        this.search();
      }
      else {
        this.router.navigate([path], {queryParams: this.query, replaceUrl: true });
      }
    }
  }

  // go to new element route or opena a mask for inline adds
  add(open?: boolean) {
    if(this.mode == 'inline') {
      this.newInlineChildId = 0;
      this.newInlineChild = open
    }
    else {
      this.router.navigate(['advice/edit/0']);
    }
  }

  // go to edit form for element or open a mask for inline editing
  edit(advice: Event):void {
    if(this.mode == 'inline') {
      this.newInlineChildId = advice.id;
      this.newInlineChild = true;
    }
    else {
      this.router.navigate(['advice/edit/' + advice.id]);
    }
  }

  // notify selection in inline mode
  select(advice: Event):void {
    this.message.change('advice.selected', advice);
  }

  // modal confirmation
  delete(advice, index) {
    this.modal.confirm().then((confirmed) => {
      if(confirmed) {
        advice.loadings['delete'] = true;
        setTimeout(() => {
          this.service.delete(advice.id).then(() => {
            advice.loadings['delete'] = false;
            this.list.splice(index, 1);
          }, function() {
            advice.loadings['delete'] = false;
          });
        }, 500);
      }
    }, (dismiss) => { });
  }

  toggleAllSelections(selectAll: boolean): void {
    this.isUpdateButtonDisabled = !selectAll;
    this.list.map(
      event => {
        this.selectedValues[event.id] = selectAll;
      }
    )
  };

  toggleSelection(value: boolean): void {
    if (!value) {
      this.selectAll = false;
    } else {
      const keys = Object.keys(this.selectedValues);
      if (keys.length == this.list.length) {
        this.selectAll = keys.map(key => this.selectedValues[key]).reduce(
          (acc: boolean, status: boolean): boolean => acc && status,
          true
        )
      }
    }

    this.isUpdateButtonDisabled = this.disableUpdateButton();
  }

  disableUpdateButton(): boolean {
    for (let i in this.selectedValues) {
      if (!!this.selectedValues[i]) {
        return false;
      }
    }

    return true;
  }

  setReadStatus(read: boolean): void {
    let ids: number[] = [];
    for (let i in this.selectedValues) {
      if (this.selectedValues[i]) {
        ids.push(parseInt(i));
      }
    }
    this.service.readAdvice(ids, read).then(
      res => {
        this.list.map(
          (element: Event) => {
            if (ids.indexOf(element.id) !== -1) {
              element.read = read;
            }
          }
        );
        setTimeout(() => {
          this.loaded = true;
          this.progress.complete();
          this.selectedValues = {};
          this.selectAll = false;
          this.isUpdateButtonDisabled = true;
        }, 250)
      }, err => {
        this.loaded = true;
        this.progress.complete();
        this.selectedValues = {};
        this.selectAll = false;
        this.isUpdateButtonDisabled = true;
      }
    );
  }
}
