
<div class="block block-m-t">

  <h1 [hidden]="mode == 'inline'">
    <!-- <span [hidden]="query._title">Elenco comunicazioni</span>
    <span [hidden]="!query._title">Elenco comunicazioni</span> -->
    {{ query._title || 'content.list' | translate }}
    <span [hidden]="! page || page == 1"> - {{ 'COMMON.page' | translate }} {{ page }}</span>
    <span class="pull-right" *ngIf="acl == 'edit'">
      <button class="btn btn-info" (click)="add()" >{{ 'COMMON.create' | translate }}</button>
    </span>
  </h1>



  <form #searchForm="ngForm" class="searchForm" (ngSubmit)="navigate(1)"  [hidden]="mode == 'inline' && !modeSelect && !forceForm" >
    <div class="row">
      <div class="col-lg-11">
        <div class="form-group" >
              <!-- <div class="btn-group" >
                <label for="id" class="btn btn-default" >Nome</label>
                <input type="text" class="form-control" id="firstname" style="height:34px" [(ngModel)]="query.firstname" name="firstname">
              </div> -->
              <div class="btn-group">
                <label for="q" class="btn btn-default">{{ 'COMMON.search' | translate }}  </label>
                <div>
                  <input type="text" class="form-control" id="q" name="q" [(ngModel)]="query.q"  />
                </div>
              </div>


            <div class="btn-group"  *ngIf="acl == 'edit'">
              <label class="btn btn-default">{{ 'content.status' | translate }}</label>
              <div>
                <div class="btn-group" ngbRadioGroup name="status" [(ngModel)]="query.status">
                  <label ngbButtonLabel class="btn-info" *ngFor="let s of 'contentStatus' | CoreLabelPipe : null : '*'">
                    <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                  </label>
                  <label ngbButtonLabel class="btn-info btn-auto" [hidden]="!query.status">
                    <input ngbButton type="radio" (click)="query.status = ''"> <i class="fa fa-close"></i>
                  </label>
                </div>
              </div>
            </div>

            <!-- <div class="btn-group" >
              <label class="btn btn-default">Tipo</label>
              <div>
                <div class="btn-group" ngbRadioGroup name="type" [(ngModel)]="query.type">
                  <label ngbButtonLabel class="btn-info" *ngFor="let s of 'contentType' | CoreLabelPipe : null : '*'">
                    <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                  </label>
                  <label ngbButtonLabel class="btn-info btn-auto" [hidden]="!query.type">
                    <input ngbButton type="radio" (click)="query.type = ''"> <i class="fa fa-close"></i>
                  </label>
                </div>
              </div>
            </div> -->

        </div>
      </div>
      <div class="col-lg-1 text-right">
        <div class="form-group">
          <button class="btn btn-outline-info" type="submit"><i class="fa fa-search"></i></button>
        </div>
      </div>
    </div>
  </form>


  <div class="overflow-x-scroll" [coreLoader]="loading">

    <table class="table table-bordered">
      <thead class="">
        <tr class="bg-dark lead" [hidden]="mode == 'inline'">
          <th class="text-center " colspan="10">
            {{ 'content.list' | translate }}
          </th>
        </tr>

        <tr>
          <th class="text-center">
            {{ 'COMMON.title2' | translate }}
          </th>
          <th class="text-center">
            {{ 'COMMON.create2' | translate }}
          </th>
          <th class="text-center">
            {{ 'COMMON.status' | translate }}
          </th>
          <th class="text-center" *ngIf="query.type == 'FAQ'">
            TAG
          </th>
          <th class="text-center" *ngIf="acl == 'edit'">

          </th>
        </tr>
      </thead>
      <tbody *ngIf="loaded">
        <tr *ngFor="let obj of list; let i = index" class="animated fadeIn" [ngClass]="{'refused': obj.status == 'REFUSED', 'highlight': obj.loadings && obj.loadings.new}">
          <td class="text-center">
            {{ obj.title }}
          </td>
          <td  class="text-center" >
              {{ obj.created_at | CoreDatePipe }}
          </td>
          <td class="text-center" [ngClass]="{'text-warning': obj.status == 'DRAFT'}">
          {{ 'contentStatus' | CoreLabelPipe : obj.status  | translate }}
        </td>
          <td  class="text-center" *ngIf="query.type == 'FAQ'">
              {{ 'contentTag' | CoreLabelPipe : obj.tag | translate }}
          </td>

          <td class="text-center pb-0" *ngIf="obj && obj.loadings && ! modeSelect && acl=='edit'">
            <button type="button" class="btn btn-sm btn-outline-info"  (click)="edit(obj, i)">{{ 'COMMON.edit' | translate }}</button>
            <button type="button" class="btn btn-sm btn-outline-danger"  (click)="delete(obj, i)" [ladda]="obj.loadings['delete']" data-spinner-color="#000" data-style="zoom-in"><i class="fa fa-remove"></i></button>
          </td>
          <td class="text-center pb-0" *ngIf="modeSelect && acl == 'edit' ">
            <button type="button" class="btn btn-sm btn-outline-info"  (click)="select(obj, i)">{{ 'COMMON.select' | translate }}</button>
          </td>

          <!-- <td>
            <button type="button" class="btn btn-sm btn-outline-info" routerLink="/content/edit/{{ obj.id }}">Gestisci</button>
          </td> -->
        </tr>
      </tbody>
    </table>

    <ng-container *ngIf="mode == 'inline'" >
      <p class="mb-0">
        <button type="button" class="btn btn-sm btn-info btn-block-inline" (click)="add(true)" [ngClass]="{'btn-block-inline-active': newInlineChild}"><span [hidden]="newInlineChildId">{{ 'COMMON.create' | translate }}</span><span [hidden]="!newInlineChildId">{{ 'COMMON.edit2' | translate }}</span> <i [hidden]="!newInlineChild" class="fa fa-arrow-down animated fadeIn"></i></button>
        <button type="button" class="btn btn-sm animated fadeIn btn-block-inline" (click)="add(false)" [hidden]="!newInlineChild" ><i class="fa fa-close"></i></button>
      </p>
      <div class="block-inline" *ngIf="newInlineChild">
        <content-edit  [mode]="mode" [paramsIn]="{id: newInlineChildId}" [objIn]="objIn"></content-edit>
      </div>
    </ng-container>


    <p [hidden]="loaded && list.length || !loaded" class="text-center">
      {{ 'content.nothing' | translate }}
    </p>

    <hr style="margin:50px 0"/>

  </div>
</div>
<core-pagination-nav [pageInfo]="pageInfo" [(page)]="page" (changed)="navigate($event)"  ></core-pagination-nav>
