import { CoreHttpModelBase } from './../core';

export class Ticket extends CoreHttpModelBase {
    id: number
    title: string
    text: string
    area: number
    status: string
    priority: number
    user_id: string
    oem_id: number
    type:string
    notes: any[] = []
    assigned_to_id: string
    assigned_to: any
    user: any
    created_at: string
    edited_at: string
    loadings: any = {}
}

export const TICKET_FILTERS = {
  post: [ "title", "text", "area", "status", "priority", "user_id", "type", "assigned_to_id"],
  patch: [ "title", "text", "area", "status", "priority",  "type", "assigned_to_id"]
}
