import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService, Notification } from './';
import { CoreStorageService, CoreMessageService } from '../core'


@Component({
  selector: 'notification-edit',
  templateUrl: './notification-edit.component.html'
})
export class NotificationEditComponent implements OnInit {

  constructor(
    private service: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private message: CoreMessageService,
    private storage: CoreStorageService,
    // private state: AppState
  ) { }

  obj: Notification;
  params: {} = {};
  @Input() mode = ''; // 'inline' when used as child view
  //@Input() queryIn: {} //
  @Input() paramsIn: {} // params inherited by parent in inline view
  @Input() objIn: {} = {} // obj inherited by parent in inline view
  query: {} = {};
  new = false; // true for creation of new entity, false instead
  saveLoading = false;
  saved = false;
  data: {}; // data retrieved by core storage

  tabs = ["notification.tab1"]; //list of tabs
  tab = 0; // tab index


  organizations = [];
  identity: any = {}

  ngOnInit(): void {
    this.identity = this.storage.retrieve('identity') || {};

    this.obj = new Notification().applyModel({
      'alarm_push': false,
      'alarm_email': false,
      'message_push': false,
      'message_email': false
    })

    // create a new entity with default data
    this.service.get().then(notification => {
      this.obj = notification;
    })

  }


  // create or pdate an entioty
  save(): void {
    this.saveLoading = true;
    // patch (UPDATE)
    this.service.patch(this.obj).then(notification => {
      setTimeout(() => {
        this.saveLoading = false
        this.saved = true;
        // trig event in inline mode to notify parents
        if (this.mode == 'inline') {
          notification.loadings['new'] = true;
          this.message.change('notification.changed', notification);
        }
        else {
          setTimeout(() => {
            this.saved = false;
          }, 2000);
        }
      }, 1000);

    }, (err) => {
      console.log(err);

      this.saveLoading = false
    })
  }

}
