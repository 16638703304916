import { Injectable } from '@angular/core'
import { HttpResponse, HttpParams } from '@angular/common/http';
import { Params } from '@angular/router'

export class CoreHttpModelBase {

  public applyModel(props): any {
    for(var k in props) {
      this[k] = props[k];
    }
    return this;
  }

}



export class CoreHttpModelParser {

  public getModels(resp: HttpResponse<any>, model: any): any  {
    var r = [];
    if(resp.body instanceof Array) {
      for(var i in resp.body) {
        r.push(new model().applyModel(resp.body[i]));
      }
    }
    else if(resp.body instanceof Object)  {
      r.push(new model().applyModel(resp.body));
    }
    return r;
  }

  public getPagination(resp: HttpResponse<any>): any {
    var pagination = {
      total: 0,
      pages: 0,
      count: 0,
      limit: 20,
      from: 0,
      next: "",
      page: 1,
      to: 0
    };
    // console.log("X", resp.headers);
    if(resp.headers.get('x-count')) {
      pagination['total'] = parseInt(resp.headers.get('x-total'));
      pagination['page'] = parseInt(resp.headers.get('x-page'));
      pagination['count'] = parseInt(resp.headers.get('x-count'));
      pagination['limit'] = parseInt(resp.headers.get('x-limit'));
      pagination['from'] = parseInt(resp.headers.get('x-from'));
      pagination['to'] = parseInt(resp.headers.get('x-to'));
      pagination['pages'] = 0;
      //  {
      //
      //   'page': parseInt(resp.headers.get('x-page')),
      //   'count': parseInt(resp.headers.get('x-count')),
      //   'limit': parseInt(resp.headers.get('x-limit')),
      //   'from': parseInt(resp.headers.get('x-from')),
      //   'to': parseInt(resp.headers.get('x-to')),
      //   'pages': 0
      // }
    }
    // support for next id for dynamodb queries (Served in from header)
    // console.log("X", resp.headers.get());
    if(resp.headers.get('x-next')) {
      pagination['next'] = resp.headers.get('x-next');
    }

    if(pagination.total > 0 && pagination.limit > 0) {
      pagination.pages = ((pagination.total - (pagination.total % pagination.limit) ) / pagination.limit);
      if(pagination.total % pagination.limit) {
        pagination.pages++;
      }
    }

    return pagination;

  }

  public parse(resp: HttpResponse<any>, model: any): any {
    return {
      models: this.getModels(resp, model),
      pagination: this.getPagination(resp),
      source: resp
    }
  }


  public queryToObj(queryParams, exclude?: String[]): any {
    queryParams = JSON.parse(JSON.stringify(queryParams));
    if(exclude === undefined ) {
      exclude = ["_section", "_title", "_last"];
    }
    var r = {

    };
    for(var k in queryParams) {
      if(k == "fields[]") {
        r['filters'] = {};
        for(var j = 0; j < queryParams[k].length; j++) {
          r['filters'][queryParams[k][j]] = queryParams['values[]'][j]
        }
      }
      else if(k != "values[]" && (exclude.indexOf(k) === -1 && k[0] != "_")) {
        r[k] = queryParams[k];
      }
    }
    return r;
  }

  public objToQuery(obj: {}, exclude?: String[]): any {
    obj = JSON.parse(JSON.stringify(obj || {}));
    if(exclude === undefined ) {
      exclude = ["_section", "_title", "_last"];
    }
    // console.log("OBJ", obj);
    var r = {};
    for(var k in obj) {
      if(exclude.indexOf(k) == -1) {
        if(k == "filters") {
          r['fields[]'] = [];
          r['values[]'] = [];
          for(var j in obj[k]) {
            r['fields[]'].push(j);
            r['values[]'].push(obj[k][j])
          }
        }
        else {
          if(obj[k]) {
            r[k] = obj[k]
          }
        }
      }
    }

    // console.log("TO QUERY", r);
    return r;
  }

  //transform query object into fields[] and query[] params
  public useFields(query): any{
    var exists = false;
    var ret = {"fields[]": [], "values[]": []};
    var exclude=["orderBy", "order", "page", "limit"];
    for(var k in query) {
      if(k[0] != "_") {
        if(exclude.indexOf(k) == -1 && query[k] !== null && query[k] !== 'null') {
          exists = true;
          ret['fields[]'].push(k);
          ret['values[]'].push(query[k]);

        }
        else {
          ret[k] = query[k];
        }
      }
    }
    if(!exists) {
      delete ret['fields[]'];
      delete ret['values[]'];
    }
    return ret;
  }

  public getParams(params): any {
    let httpParams= [];
    Object.keys(params).forEach(function (key) {
      if(params[key] instanceof Array) {
        for(var k = 0; k <params[key].length; k++) {
          httpParams.push(key + "=" + params[key][k]);
        }
      }
      else {
        httpParams.push(key + "=" + params[key]);
      }
        //  httpParams = httpParams.append(key, params[key]);
    });
    return httpParams.join("&");
    // return "page=3&fields%5B%5D=status&fields%5B%5D=courseId&values%5B%5D=AVAILABLE&values%5B%5D=22&limit=2";
    // return httpParams;
  }

  // public copyParams(params: Params) {
  //     return Params as {};
  //
  // }
}

/**
 * Permette di passare parametri custom nelle richieste http
 * Usato negli interceptor per lo skip dell'aggiunta del token di autenticazione
 * o per ignorare la gestione di default di quell'errore
 *
 * Se ci fosse necessità di usare gli HttpParams è possibile passarli come secondo parametro
 *
 * es.
 * this.http.get('https://api.com', {
 *   params: new CustomHttpParams({ ignoreAuthentication: true, ignoreError: true }, { ... })
 * })
 */
export class CustomHttpParams extends HttpParams {

  constructor(
    public customParams?: { ignoreAuthentication?: boolean, forceAuthentication?: boolean, ignoreError?: boolean, ignoreDecoration?: boolean },
    public params?: { [ param: string ]: string | string[] }
  ) {
    super({ fromObject: params });  // Passes through the HttpParams
  }

}
