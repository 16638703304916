import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class CoreMessageService {

  private messages = {};

  constructor() { }

  change(name:string, value: any) {
    this.init(name, value);
    this.messages[name].next(value);
  }

  get(name: string) {
    return this.messages[name] || this.init(name);
  }

  init(name: string, def?: any) {

    if(!this.messages[name]) {
      this.messages[name] = new BehaviorSubject<any>(def || "");
      this.messages[name].asObservable();
    }
    return this.messages[name];
  }

}
