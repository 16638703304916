<div class="block block-m-t">

  <div [hidden]="mode == 'inline'">
    <h4>Compila il modulo</h4>
    <p>
      * campo obbligatorio
    </p>
  </div>

  <form [formGroup]="objForm" class="form-standard" (ngSubmit)="save()">
    <div [ngClass]="{'animated fadeOut': saveLoading}">

      <p class="tabs">
        <button type="button" *ngFor="let t of tabs; let i = index" class="btn btn-lg"
                [ngClass]="{'btn-info': tab == i, 'btn-link': i != tab}" (click)="tab = i">
          {{ t | translate }}
        </button>
      </p>

      <!--  INIZIO SEZIONE DESTINATARI  -->
      <div [hidden]="tab != 0" class="row">
        <div class="col-12">
          <div class="form-group">
            <div class="form-group">
              <label for="name">{{ 'newsletter.nameTitle' | translate }} *</label>
              <input
                required
                id="name"
                type="text"
                class="form-control"
                formControlName="name"
                name="name">
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>{{ 'newsletter.chooseModel' | translate }} *</label>
            <div>
              <div class="btn-group" ngbRadioGroup name="type" formControlName="model">
                <label ngbButtonLabel class="btn-info" *ngFor="let t of 'newsletterType' | CoreEnumPipe">
                  <input ngbButton type="radio" [value]="t.id"> {{ t.label | translate }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="custom-divider"></div> -->

        <!-- <div class="col-12">
          <div class="form-group">
            <label>{{ 'newsletter.chooseStatus' | translate }}</label>
            <div>
              <div class="btn-group">
                <label ngbButtonLabel class="btn-info" *ngFor="let status of 'userStatus' | CoreEnumPipe"
                       [ngClass]="{'active': objForm && objForm.get('statuses').value && objForm.get('statuses').value.includes(status.id)}">
                  <input [value]="status.id" (click)="setStatusesValue(status.id)" type="radio">
                  {{ status.label | translate }}
                </label>
              </div>

            </div>
            <small>{{ 'newsletter.chooseStatusDesc' | translate}}</small>
          </div>
        </div> -->

        <div class="custom-divider"></div>

        <div class="col-12" *ngIf="objForm.controls['roles']">
          <div class="form-group">
            <label>{{ 'newsletter.chooseRole' | translate }}</label>
            <div>
              <div class="btn-group button-mobile">
                <label ngbButtonLabel class="btn-info" *ngFor="let role of 'roles' | CoreEnumPipe"
                       [ngClass]="{'active': objForm.get('roles').value && objForm.get('roles').value.includes(role.id)}">
                  <input [value]="role.id" (click)="setRolesValue(role.id)" type="radio">
                  {{ role.label | translate }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="custom-divider"></div>

        <div class="col-12">
          <div class="form-group">
              <label for="organization_ids">{{ 'newsletter.chooseOrganization' | translate }}</label>
              <ng-multiselect-dropdown
                placeholder="{{ 'COMMON.select' | translate }}"
                [settings]="organizationSettings"
                [data]="organizations"
                [(ngModel)]="selectedOrganizations"
                [ngModelOptions]="{standalone: true}"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="onDeSelect($event)"
                name="organization_ids"
              >
              </ng-multiselect-dropdown>
          </div>
        </div>


        <div class="custom-divider"></div>

        <div class="col-12 my-2">
          <div class="form-group">
            <label>{{'newsletter.usersCount' | translate}}: <b>{{usersCount}}</b></label>
          </div>
        </div>

      </div>
      <!--  FINE SEZIONE DESTINATARI  -->

      <!--  INIZIO SEZIONE CONTENUTI  -->
      <div [hidden]="tab != 1" class="row">
        <!-- LEFT -->
        <div class="col-lg-6">
          <h2 class="mb-3 mt-0" *ngIf="'EMAIL' !== objForm.value['model']">{{ 'newsletter.bodyTitle' | translate }}</h2>

          <div class="form-group">
            <div class="form-group">
              <label for="name">{{ 'newsletter.subject' | translate }} *</label>
              <input
                required
                class="form-control"
                name="subject"
                formControlName="subject"
              />
            </div>
          </div>

          <div class="custom-divider"></div>

          <div class="form-group">
            <div class="form-group">
              <label>{{ 'newsletter.body' | translate }}</label>

              <!--
                enable when new features are released, APIKEY required
              <editor
                *ngIf="'EMAIL' === objForm.value['model']"
                [init]="{ plugins: 'lists link image table code help wordcount' }"

                formControlName="body"
              ></editor>
              -->

              <textarea
                class="form-control"
                rows="10"
                name="body"
                formControlName="body"
                (input)="applyBodyPipe()"
              ></textarea>

            </div>
          </div>

          <div class="custom-divider"></div>

          <div class="form-group" *ngIf="'EMAIL' === objForm.value['model']">
            <div class="form-group">
              <label>{{ 'newsletter.ctaTitle' | translate }}</label>
              <input class="form-control" name="cta_title" formControlName="cta_title"/>
              <label class="hint">{{ 'newsletter.ctaTitleHint' | translate }}</label>
            </div>
          </div>

          <div class="custom-divider" *ngIf="'EMAIL' === objForm.value['model']"></div>

          <div class="form-group" *ngIf="'EMAIL' === objForm.value['model']">
            <div class="form-group">
              <label>{{ 'newsletter.urlTitle' | translate }}</label>
              <input
                class="form-control"
                name="cta_url"
                formControlName="cta_url"
                pattern=".+?://.+"
              />
              <label class="hint">{{ 'newsletter.urlTitleHint' | translate }}</label>
            </div>
          </div>

          <div class="custom-divider" *ngIf="'EMAIL' === objForm.value['model']"></div>

          <div class="form-group" *ngIf="'EMAIL' === objForm.value['model']">
            <div class="form-group">
              <label>{{ 'newsletter.mainColor' | translate }}</label>
              <input
                class="form-control"
                type="color"
                name="main_color"
                formControlName="main_color"
              />
            </div>
          </div>
        </div>

        <!-- RIGHT -->
        <div class="col-lg-6 newsletter-preview" [hidden]="isSmallScreen || 'EMAIL' !== objForm.value['model']">
          <table
            cellpadding="0"
            cellspacing="0"
            border="0"
            width="100%"
            style="background: #f3f3f3; min-width: 350px; font-size: 1px; line-height: normal;"
          >
            <tr>
              <td align="center" valign="top">
                <table
                  cellpadding="0"
                  cellspacing="0"
                  border="0"
                  style="width: 100%; max-width: 750px; min-width: 350px; background: #f3f3f3;"
                >
                  <tr>
                    <td class="mob_pad" width="25" style="width: 25px; max-width: 25px; min-width: 25px;">&nbsp;</td>
                    <td align="center" valign="top" style="background: #ffffff;">

                      <table
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                        width="100%"
                        style="width: 100% !important; min-width: 100%; max-width: 100%; background: #f3f3f3;"
                      >
                        <tr>
                          <td align="right" valign="top">
                            <div class="top_pad" style="height: 25px; line-height: 25px; font-size: 23px;">&nbsp;
                            </div>
                          </td>
                        </tr>
                      </table>

                      <table
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                        width="88%"
                        style="width: 88% !important; min-width: 88%; max-width: 88%;"
                      >
                        <tr>
                          <td align="left" valign="top">
                            <div style="height: 39px; line-height: 39px; font-size: 37px;">&nbsp;</div>
                            <img
                              src="{{endpointStatic}}/assets/img/365.png"
                              alt=""
                              width="180"
                              border="0"
                              style="display: block; width: 180px;"/>
                            <div style="height: 50px; line-height: 50px; font-size: 71px;">&nbsp;</div>
                          </td>
                        </tr>
                      </table>

                      <table
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                        width="88%"
                        style="width: 88% !important; min-width: 88%; max-width: 88%;"
                      >
                        <tr>
                          <td align="left" valign="top">
                            <font face="'Source Sans Pro', sans-serif" color="#1a1a1a"
                                  style="font-size: 20px; line-height: 32px; ">
                                 <span
                                   style="font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #585858; font-size: 20px; line-height: 32px;">
                                   {{ 'newsletter.emailTitle' | translate }} </span>
                            </font>
                            <div style="height: 33px; line-height: 33px; font-size: 31px;">&nbsp;</div>
                            <font face="'Source Sans Pro', sans-serif" color="#585858"
                                  style="font-size: 20px; line-height: 32px;">
                                 <span
                                   *ngIf="!!objForm.value['body']"
                                   style="font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #585858; font-size: 20px; line-height: 32px;"
                                   [innerHTML]="objForm.value['body']| CoreLineBreakPipe | CoreSafePipe: 'html' "
                                 >
                                  </span>
                              <span
                                *ngIf="!objForm.value['body']"
                                style="font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #585858; font-size: 20px; line-height: 32px;"
                              >
                                  </span>
                            </font>
                            <div style="height: 33px; line-height: 33px; font-size: 31px;">&nbsp;</div>

                            <ng-container select="selector" *ngIf="!!objForm.value['cta_url'] && !!objForm.value['cta_title']">
                              <a
                                href="{{objForm.value['cta_url']}}"
                                target="_blank"
                                style="text-align:center;display: block; background: #ffffff; border-radius: 6px; padding: 12px 6px; font-family: 'Source Sans Pro', Arial, Verdana, Tahoma, Geneva, sans-serif; color: #ffffff; font-size: 20px; line-height: 30px; margin:0 10%; text-decoration: none; white-space: nowrap; font-weight: 600;"
                                [ngStyle]="{'border': objForm.value['main_color'] +' 2px solid' }"
                              >
                                <font face="'Source Sans Pro', sans-serif" color="{{objForm.value['main_color']}}"
                                      style="text-align:center;font-size: 20px; line-height: 30px; text-decoration: none; white-space: nowrap; font-weight: 600;">
                                    <span
                                      style="text-align:center;font-family: 'Source Sans Pro', Arial, Verdana, Tahoma, Geneva, sans-serif; font-size: 20px; line-height: 30px; text-decoration: none; white-space: nowrap; font-weight: 600;"
                                      [ngStyle]="{'color': objForm.value['main_color']}">
                                      {{ objForm.value['cta_title'] }}
                                   </span>
                                </font>
                              </a>
                              <div style="height: 33px; line-height: 33px; font-size: 31px;">&nbsp;</div>
                            </ng-container>
                            <font face="'Source Sans Pro', sans-serif" color="#585858"
                                  style="font-size: 20px; line-height: 32px;">
                                <span
                                  style="font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #585858; font-size: 20px; line-height: 32px;"
                                >
                                Buon proseguimento,<br><b target="_blank">mysmart365</b>
                                </span>
                            </font>

                            <hr style="border-color:#fafafa">
                            <table cellpadding="10" cellspacing="5" border="0">
                              <tr>
                                <td>&nbsp;</td>
                              </tr>
                            </table>
                            <font face="'Source Sans Pro', sans-serif" color="#585858"
                                  style="font-size: 11px; line-height: 16px;">
                                <span
                                  style="font-family: 'Source Sans Pro', Arial, Tahoma, Geneva, sans-serif; color: #585858; font-size: 11px; line-height: 11px;">
                                    <strong>Questo messaggio è stato inviato da un indirizzo di sola notifica. Non rispondere a questo
                                      messaggio. <br /> Effettuando l’accesso alle impostazioni del tuo profilo da App o Portale puoi
                                      modificare le tue preferenze relative a questo tipo di comunicazioni.</strong>
                                    <br><br>&nbsp;
                                </span>
                            </font>

                          </td>
                        </tr>
                      </table>

                      <table
                        cellpadding="0"
                        cellspacing="0"
                        border="0"
                        width="100%"
                        style="width: 100% !important; min-width: 100%; max-width: 100%; background: #f3f3f3;"
                      >
                        <tr>
                          <td align="right" valign="top">
                            <div class="top_pad" style="height: 25px; line-height: 25px; font-size: 23px;">&nbsp;
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td class="mob_pad" width="25" style="width: 25px; max-width: 25px; min-width: 25px;">&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!--  FINE SEZIONE CONTENUTI  -->

      <!--  INIZIO SEZIONE CONTENUTI  -->
      <div [hidden]="tab != 2" class="row">
        <!-- LEFT -->
        <div class="col-lg-12">
          <h2 class="my-0">{{ 'newsletter.doTest' | translate }}</h2>
          <small class="mat-hint mb-2">{{ 'newsletter.doTestHint' | translate }}</small>
        </div>

        <div class="col-lg-6 mt-4">
          <div class="form-group">
            <div class="form-group">
              <label *ngIf="'EMAIL' === objForm.value['model']">{{ 'newsletter.testEmail' | translate }}</label>
              <label *ngIf="'PUSH' === objForm.value['model']">{{ 'newsletter.testEmail' | translate }}</label>
              <input
                class="form-control"
                type="email"
                name="testEmail"
                formControlName="testEmail"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 mt-4" *ngIf="'EMAIL' === objForm.value['model']">
          <div class="form-group" *ngIf="identity.role == 'ROLE_ADMIN'">
            <label for="organization">{{ 'organization.title' | translate }} </label>
            <select class="form-control width-auto" 
              id="organization"
              formControlName="testOEM"
              name="organization_id" >
              <option value="null">
                {{ 'organization.select' | translate }}
              </option>
              <option *ngFor="let o of data['organization']" value="{{ o.id }}">
                {{ o.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-lg-12 mb-4">
          <button
            class="btn btn-success btn-lg"
            type="button"
            (click)="launchTest(obj.id, objForm.value['testEmail'], objForm.value['testOEM'])"
            [disabled]="!objForm.value['testEmail'] || saveLoading || sendInProgress || planInProgress"
            [ladda]="testInProgress"
          >
            {{ 'newsletter.doTestEmail' | translate }}
          </button>
        </div>

        <div class="custom-divider"></div>

        <div class="col-lg-12 mt-4">
          <label>
            {{ 'newsletter.selectDateTime' | translate }}
          </label>
          <small class="mat-hint mb-2"> </small>
        </div>

        <div class="col-12 mt-4">
          <div class="row flex-column justify-content-around">

            <div class="col-sm-12 col-md-6 d-flex justify-content-around align-items-center">
              <div>
                <label>{{ 'newsletter.sendOnDay' | translate }}</label>
              </div>
              <div class="form-inline">
                <div class="form-group">
                  <div class="input-group">
                    <input class="form-control"
                           placeholder="yyyy-mm-dd"
                           ngbDatepicker
                           name="send_at"
                           formControlName="send_at"
                           (dateSelect)="setDate($event)"
                           [minDate]="{year: minDate.getFullYear(), month: minDate.getMonth(), day: minDate.getDay()}"
                           [disabled]="!obj.tested"
                           #d="ngbDatepicker">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar h-100"
                              (click)="d.toggle()"
                              [disabled]="!obj.tested"
                              type="button"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 d-flex justify-content-around align-items-center">
              <div>
                <label>{{ 'newsletter.at' | translate }}</label>
              </div>
              <div class="form-group m-0 p-0">
                <div class="form-group" *ngIf="obj.tested">
                  <ngb-timepicker
                    formControlName="send_at_time"
                    name="send_at_time"
                  ></ngb-timepicker>
                </div>
                <div *ngIf="!obj.tested">
                  -- : --
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="custom-divider"></div>

        <div class="col-12 col-lg-6 mb-4">
          <button class="btn btn-success btn-lg"
                  type="button"
                  (click)="savePlan()"
                  [disabled]="!obj.tested ||!obj.send_at || saveLoading || sendInProgress || testInProgress"
                  [ladda]="planInProgress"
          >
            {{ 'newsletter.savePlan' | translate }}
          </button>
        </div>
        <div class="col-12 col-lg-6 mb-4" *ngIf="'ROLE_ADMIN' === identity.role">
          <button class="btn btn-success btn-lg"
                  type="button"
                  (click)="sendEmail()"
                  [disabled]="!obj.tested || saveLoading || planInProgress || testInProgress"
                  [ladda]="sendInProgress"
          >
            {{ 'newsletter.sendEmail' | translate }}
          </button>
        </div>

      </div>
      <!--  FINE SEZIONE CONTENUTI  -->
    </div>

    <div class="form-group mt-3" *ngIf="tab != 2">
      <button type="submit" class="btn btn-success btn-lg" [disabled]="objForm.invalid"
              [ladda]="saveLoading">{{ 'COMMON.save' | translate }}
      </button>
      <span class="btn" [hidden]="!saved"><strong>{{ 'COMMON.saved' | translate }}</strong></span>
    </div>

  </form>

</div>
