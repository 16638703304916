import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, Event } from '@angular/router';
// import { Observable }        from 'rxjs/Observable';

import { TicketmasterService, Ticketmaster } from './';

import { CoreStorageService, CoreStorage, CoreModalService, CoreMessageService } from '../core'




@Component({
  selector: 'ticketmaster-edit',
  templateUrl: './ticketmaster-edit.component.html'
})


export class TicketmasterEditComponent implements OnInit {

  constructor(
    private service: TicketmasterService,
    private router: Router,
    private route: ActivatedRoute,
    private message: CoreMessageService,
    private storage: CoreStorageService,
    // private state: AppState
  ) { }

  obj: Ticketmaster;
  params: any = {};
  @Input() mode = ''; // 'inline' when used as child view
  //@Input() queryIn: any //
  @Input() paramsIn: any // params inherited by parent in inline view
  @Input() objIn: any = {} // obj inherited by parent in inline view
  query: any = {};
  new = false; // true for creation of new entity, false instead
  saveLoading = false;
  saved = false;
  data: {}; // data retrieved by core storage

  tabs = ["Dati Richiesta"]; //list of tabs
  tab = 0; // tab index

  note: any = {}

  assignLoading = false;
  noteLoading = false;
 identity

  ngOnInit(): void {
    this.identity = this.storage.retrieve('identity') || {};
    // set params if inherited by parent (usually in inline view) then search
    if(this.paramsIn && Object.keys(this.paramsIn).length) {
      this.params = Object.assign({}, this.paramsIn);
      this.search();
    }
    // else subscribe to query and params then search
    else {
      this.route.queryParams.subscribe( queryParams => {
        this.query = queryParams;
      });
      this.route.params.subscribe( params => {
          this.params = Object.assign({}, params);
          this.search();
      });
    }

    // create a new entity with default data
    this.obj = new Ticketmaster().applyModel(Object.assign({
      status: 'NEW',
      priority: 'MEDIUM',
      area: 'GENERIC'
      //new model
    }, this.objIn));

    // retrieve adta from core storage
    this.data = this.storage.retrieve("data");


  }

  // search entity
  search(): void {
    if(this.params['id'] && this.params['id'] != "0") {
      // retrieve ticketmaster details
      this.service.get(this.params['id']).then(res => {
        this.service.getNotes(this.params['id']).then((res2) =>  {
          this.obj = res.models[0];
          this.obj.notes = res2.body;
          this.tabs.push("Note");
        })
      })
    }
    else {
      this.new = true;
    }
  }

  assign() {
    this.assignLoading = true;
    this.service.patch(this.obj.id, {assigned_to_id: this.identity.user.id}).then((res) => {
      setTimeout(() => {
        this.obj.assigned_to_id = this.identity.user.id;
        this.obj.assigned_to = res.models[0].assigned_to;
        this.assignLoading = false;
      }, 500)
    }, () => {
      this.assignLoading = false;
    });
  }

  addNote() {
    this.noteLoading = true;
    if(this.identity.role == 'ROLE_OEM') {
      this.note['status_to'] = 'NEW';
    }
    this.service.postNote(this.obj.id, this.note).then((res) => {
      setTimeout(() => {
        this.obj.notes.push(res.body);
        if( this.note['status_to']) {this.obj.status =   this.note['status_to'];}
        this.noteLoading = false;
        this.note = { text: ''};
      }, 500)

    }, () => {

      this.noteLoading = false;
    })
  }


  // create or pdate an entioty
  save() : void {
    this.saveLoading = true;
    // patch (UPDATE)
    if(this.obj.id) {
      this.service.patch(this.obj.id, this.obj).then(res => {
        setTimeout(() => {
          this.saveLoading = false
          this.saved = true;
          // trig event in inline mode to notify parents
          if(this.mode == 'inline') {
            res.models[0].loadings['new'] = true;
            this.message.change('ticketmaster.changed', res.models[0]);
          }
          else {
            setTimeout(() => {
                this.saved = false;
            }, 2000);
          }
        }, 1000);

      }, () => {
        this.saveLoading = false
      })
    }
    //post (CREATE)
    else {
      this.service.post(this.obj).then(res => {
        setTimeout(() => {
          this.tab = 0;
          this.saveLoading = false
          this.saved = true;
          setTimeout(() => {  if(this.mode != 'inline') {this.saved = false;} }, 2000);
          // broadcast event to parent if it's in inline mode
          if(this.mode == 'inline') {
            res.models[0].loadings['new'] = true;
            this.message.change('ticketmaster.new', res.models[0]);
          }
          // else move to path if is not in inline mode
          else {
            if(this.identity.role == 'ROLE_OEM') {
              this.router.navigate(['/ticketmaster/list/']);
            }
            else {
              this.router.navigate(['/ticketmaster/edit/', res.models[0].id], {replaceUrl: true});
            }
          }
        }, 1000);
      }, () => {
        this.saveLoading = false
      })
    }
  }

}
