import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {
  NgbCalendar,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbInputDatepicker,
  NgbDateStruct,
  NgbDate
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CoreMessageService } from '../message/message.service';

// require("moment/min/locales.min");

@Component({
  selector: 'core-week-date-picker',
  templateUrl: './week-date-picker.component.html',
  styleUrls: ['./week-date-picker.component.css'],
  host: {
    '(document:mousedown)': 'handlePickerClosing($event)',
  },
})
export class CoreWeekDatePickerComponent {

  markDisabled;
  inputDate: {year, month, day};
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;

  @Input() placeholder = 'dd-mm-yyyy';
  @Input() isComparison: boolean = false;
  @Input() format;
  @Input() model: {
    start?: Date,
    end?: Date,
    label?: string
  }
  @Input() closeDatePickerInput?: boolean;
  @Input() locale: string;

  @Output() dateOpened = new EventEmitter<boolean>();

  @ViewChild('ngbDatepicker') ngbInputDatepicker: NgbInputDatepicker;

  constructor(
    private calendar: NgbCalendar,
    private messageService: CoreMessageService,
    public formatter: NgbDateParserFormatter,
    public config: NgbDatepickerConfig
  ) {
    const today = moment();
    let lastWeek = today.clone().subtract(7, 'days');
    let limit = lastWeek.endOf('week');
    let maxDate = new NgbDate(
      limit.year(), limit.month()+1, limit.date()
    );

    this.markDisabled = (date: NgbDate) => {
      return this.calendar.getWeekday(date) >= 2 || date.after(maxDate);
    };
  }

  ngOnInit() {
    moment.locale(this.locale || 'en');

    if (!this.isComparison) {
      const date = moment(this.model.start);
      this.inputDate = {
        year: date.year(),
        month: date.month()+1,
        day: date.date()
      };
    }


    this.messageService.get('resetChartComparison').subscribe(
      response => {
        if (!this.isComparison) {
          return;
        }

        this.inputDate = {
          year: null,
          month: null,
          day: null
        };
      }
    );
  }

  removeComparison() {
    this.model = {
      start: null,
      end: null,
      label: null
    };

    this.inputDate = {
      year: null,
      month: null,
      day: null
    };

    this.messageService.change('rangeSelectionComparison', this.model);
  }

  ngOnChanges() {
    if (this.closeDatePickerInput) {
      this.ngbInputDatepicker.close();
    }
  }

  formatRangeWeek() {
    let date = moment({
      day: this.inputDate.day,
      month: this.inputDate.month -1,
      year: this.inputDate.year
    });

    this.model = {
      label: date.format(this.format),
      start: date.toDate(),
      end: date.add(7, 'days').toDate()
    }

    let message = this.isComparison ? 'rangeSelectionComparison' : 'rangeSelection';

    this.messageService.change(message, this.model);
  }

  toggleDatepicker(d: NgbInputDatepicker) {
    d.toggle();
    this.dateOpened.emit(true);
  }

  private handlePickerClosing(event) {
    const isClickOnPicker =  (((event || {}).target || {}).offsetParent || {}).localName === 'ngb-datepicker' ||
      (((event || {}).target || {}).classList || []).contains('btn-light');
    const isNotClosePickerElement = (((event || {}).target || {}).classList || []).contains('not-close-datepicker');

    if (this.ngbInputDatepicker && !isClickOnPicker && !isNotClosePickerElement) {
      this.ngbInputDatepicker.close();
    }
  }
}
