import { Directive, ElementRef, Input} from '@angular/core';
@Directive({
   selector: '[coreLoader]',
})

export class CoreLoaderDirective {

  @Input('coreLoader') loading: any;
  @Input() bounce: number = 500;
  el: any;
  time: number = 0;

  constructor(Element: ElementRef) {
    Element.nativeElement.className = Element.nativeElement.className + " core-loading";
    Element.nativeElement.innerHTML = Element.nativeElement.innerHTML + "<span class='core-loading-in'>...loading...</span>";
    this.el = Element.nativeElement;
    this.time = Date.now();
    // Element.nativeElement.innerText="Text is changed by changeText Directive. ";
  }
  ngOnInit() {

    if(Array.isArray && Array.isArray(this.loading)) {
      Promise
        .all(this.loading)
        .then(() => this.show(), () => this.show())
    }
    else if(typeof this.loading == "object") {
      this.loading.then(() => this.show(), () => this.show());
    }
    else {
      this.show();
    }

  }

  show() {
    var bounce = 0;
    if((Date.now() - this.time) < this.bounce) {
      bounce = this.bounce - (Date.now() - this.time);
    }
    setTimeout(() => {
      this.el.className = this.el.className.replace("core-loading", "");
      }, bounce);
    }
}
