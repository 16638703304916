import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

/*
 * Platform and Environment providers/directives/pipes
 */
import { environment } from 'environments/environment';
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { HomeComponent } from './home';
import { NoContentComponent } from './no-content';

// APP CORE MODULES
import { CoreModule } from './core';

// APP CLIENT MODULES
import {
  UserDashboardComponent,
  UserEditComponent,
  UserListComponent,
  UserLoginComponent,
  UserLogoutComponent,
  UserRedirectComponent,
  UserService,
} from './user';

import { DeviceEditComponent, DeviceListComponent, DeviceService, } from './device';
import { MainDashboardComponent } from './main';

import { OrganizationEditComponent, OrganizationListComponent, OrganizationService, } from './organization';

import { TicketEditComponent, TicketListComponent, TicketService, } from './ticket';
import { TicketmasterEditComponent, TicketmasterListComponent, TicketmasterService, } from './ticketmaster';
import { ContentEditComponent, ContentListComponent, ContentService, } from './content';
import { ContentmasterEditComponent, ContentmasterListComponent, ContentmasterService, } from './contentmaster';
import { EventEditComponent, EventListComponent, EventService, } from './event';
import { TaskEditComponent, TaskListComponent, TaskService, } from './task';
import { LayoutHeaderComponent, LayoutLeftComponent } from './layout';
import { CommunicationEditComponent, CommunicationListComponent, CommunicationService, } from './communication';
import { StatisticsService, StatisticsViewComponent, } from './statistics';
import { NotificationEditComponent, NotificationService, } from './notification';
// OTHER MODULES
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgProgressModule } from '@ngx-progressbar/core';
import { LaddaModule } from 'angular2-ladda';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import '../styles/styles.scss';
import { EncodeHttpParamsInterceptor } from './core/http/interceptor-http-params';
import { AdviceListComponent, AdviceService } from './advice';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routes';
import { NewsletterEditComponent, NewsletterListComponent } from "./newsletter";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

registerLocaleData(localeEn);
registerLocaleData(localeDe);
registerLocaleData(localeIt);
registerLocaleData(localeEs);


// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  // AppState
];

export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/portal-', '-i18n.json');
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    HomeComponent,
    NoContentComponent,
    // folders modules
    DeviceListComponent,
    DeviceEditComponent,
    ContentListComponent,
    ContentEditComponent,
    MainDashboardComponent,
    ContentmasterListComponent,
    ContentmasterEditComponent,
    TicketListComponent,
    TicketEditComponent,
    EventListComponent,
    EventEditComponent,
    AdviceListComponent,
    TicketmasterListComponent,
    TicketmasterEditComponent,
    OrganizationListComponent,
    OrganizationEditComponent,
    CommunicationListComponent,
    CommunicationEditComponent,
    TaskListComponent,
    TaskEditComponent,
    LayoutHeaderComponent,
    LayoutLeftComponent,
    UserDashboardComponent,
    UserListComponent,
    UserEditComponent,
    UserLoginComponent,
    UserRedirectComponent,
    UserLogoutComponent,
    StatisticsViewComponent,
    NotificationEditComponent,
    NewsletterEditComponent,
    NewsletterListComponent,
  ],
  /**
   * Import Angular's modules.
   */
  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgMultiSelectDropDownModule.forRoot(),
    LaddaModule,
    CoreModule,
    NgProgressModule,
    RouterModule,
    NgbModule,
    EditorComponent,
  ],
  exports: [
    CoreModule,
    TranslateModule,
    BrowserModule,
    NgProgressModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],

  /**
   * Expose our Services and Providers into Angular's dependency injection.
   */
  providers: [
    environment.ENV_PROVIDERS,
    CurrencyPipe,
    UserService,
    ContentService,
    ContentmasterService,
    TicketmasterService,
    EventService,
    AdviceService,
    TicketService,
    TaskService,
    OrganizationService,
    CommunicationService,
    DeviceService,
    StatisticsService,
    NotificationService,
    APP_PROVIDERS,
    { provide: LOCALE_ID, useValue: 'it' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    }
  ]
})
export class AppModule {
}
