<div class="chart" style="display: block;">
  <div class="chart-heading" *ngIf="title">
    {{title | translate }}
  </div>
  <div class="chart-body" >
    <canvas
      height="80"
      baseChart
      *ngIf="type != 'pie' && type != 'doughnut'"
      [datasets]="baseChartData"
      [labels]="labels"
      [options]="baseChartOptions"
      [colors]="baseChartColors"
      [legend]="legend"
      [chartType]="type"
    ></canvas>
    <canvas
      baseChart
      *ngIf="type == 'pie' || type == 'doughnut'"
      [data]="baseChartData"
      [labels]="labels"
      [colors]="baseChartColors"
      [legend]="legend"
      [chartType]="type"
    ></canvas>
  </div>
</div>
