<header>


    <nav class="navbar navbar-expand-md  fixed-top" >
      <a class="navbar-brand" href="#" *ngIf="identity && identity.user && identity.user.organization && identity.user.organization.logoUrl"><img src="{{identity.user.organization.logoUrl}}" class="img-responsive"  /> </a>
      <a class="navbar-brand" href="#" *ngIf="!identity.user || !identity.user.organization || !identity.user.organization.logoUrl"><img src="/assets/img/logo.png" class="img-responsive"  /> </a>
      <button
      class="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#mainNavBar" aria-controls="mainNavBar" aria-expanded="false" aria-label="Toggle navigation"
      (click)="switchMenu()"
      >
        <span class="fa fa-bars"></span>
      </button>

        <!-- <img src="/assets/img/ge-logo-white.png" class="mx-auto"alt="" /> -->

      <div class="collapse navbar-collapse" >
        <ul class="navbar-nav ml-auto ">
          <li class="nav-item">
            <button class="btn " [ngClass]="{'btn-outline-info': lang == 'it'}" style="margin-left:5px"(click)="changeLanguage('it')">IT</button>
          </li>
          <li class="nav-item">
            <button class="btn " [ngClass]="{'btn-outline-info': lang == 'en'}" style="margin-left:5px" (click)="changeLanguage('en')">EN</button>
          </li>
          <li class="nav-item">
            <button class="btn " [ngClass]="{'btn-outline-info': lang == 'de'}" style="margin-left:5px"(click)="changeLanguage('de')">DE</button>
          </li>
          <li class="nav-item">
            <button class="btn " [ngClass]="{'btn-outline-info': lang == 'es'}" style="margin-left:5px"(click)="changeLanguage('es')">ES</button>
          </li>
        </ul>

      </div>
    </nav>
  </header>
