import { CoreHttpModelBase } from './../core';

export class Organization extends CoreHttpModelBase {
    id: number
    name: string
    email: string
    eolUrl: string
    privacyUrl: string
    tosUrl: string
    logoUrl: string
    tag: string
    androidPlayLink: string
    appleStoreLink : string
    webAppLink: string
    portalLink: string
    emailSupport: string
    color?: any;
    secondaryLogoUrl?: any;
    backgroundImgUrl?: any;
    uploadFile?: any;
    uploadStatus?: any;

    loadings: any = {}
}

export const ORGANIZATION_FILTERS = {
  post: [
    "name", "email", "eolUrl", "privacyUrl", "tosUrl", "logoUrl", "tag", "androidPlayLink",
    "appleStoreLink", "webAppLink", "portalLink", "emailSupport", "color", "secondaryLogoUrl",
    "backgroundImgUrl",
  ],
  patch: [
    "name", "email", "eolUrl", "privacyUrl", "tosUrl", "logoUrl", "tag", "androidPlayLink",
    "appleStoreLink", "webAppLink", "portalLink", "emailSupport", "color", "secondaryLogoUrl",
    "backgroundImgUrl",
  ]
}
