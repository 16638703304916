import { Component, Input, Injectable } from  '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'core-modal-confirm',
  templateUrl: './modal-confirm.component.html'
})
@Injectable({
  providedIn: 'root'
})

export class CoreModalConfirmComponent {
  @Input() title;
  @Input() message;
  @Input() ok;
  @Input() cancel;
  constructor(public activeModal: NgbActiveModal) {}
}
