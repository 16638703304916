
<div class="block block-m-t">

  <div  [hidden]="mode == 'inline'">
      <h4>{{'common-edit.title' | translate}}</h4>
      <p>
        * {{'common-edit.required' | translate}}
      </p>
  </div>


  <form #objForm="ngForm" class="form-standard" [hidden]="!(new || obj.id)" (ngSubmit)="save()">
    <div [ngClass]="{'animated fadeOut': saveLoading}">

      <p class="tabs">
        <button type="button" *ngFor="let t of tabs; let i = index" class="btn btn-lg" [ngClass]="{'btn-info': tab == i, 'btn-link': i != tab}" (click)="tab = i">{{ 'tabs.' + tabs[i] | translate }}</button>
      </p>

        <div [hidden]="tab != 0" class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="name">{{'COMMON.name' | translate}} OEM *</label>
                <input
                  type="text" class="form-control" id="name"
                  required
                  [(ngModel)]="obj.name" name="name" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="name">Email *</label>
                <input
                  type="text" class="form-control" id="email"
                  required
                  [(ngModel)]="obj.email" name="email" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="name">TAG ({{'organization.sub_dom' | translate}}) *</label>
                <input
                  type="text" class="form-control" id="tag"
                  required
                  [(ngModel)]="obj.tag" name="tag" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="name">Url Logo *</label>
                <input
                  type="text" class="form-control" id="logoUrl"
                  required
                  [(ngModel)]="obj.logoUrl" name="logoUrl" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="name">Url TOS</label>
                <input
                  type="text" class="form-control" id="tosUrl"
                  [(ngModel)]="obj.tosUrl" name="tosUrl" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="name">URL Privacy</label>
                <input
                  type="text" class="form-control" id="privacyUrl"
                  [(ngModel)]="obj.privacyUrl" name="privacyUrl" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="name">URL EOL</label>
                <input
                  type="text" class="form-control" id="eolUrl"
                  [(ngModel)]="obj.eolUrl" name="eolUrl" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="androidPlayLink">Android Play</label>
                <input
                  type="text" class="form-control" id="androidPlayLink"
                  [(ngModel)]="obj.androidPlayLink" name="androidPlayLink" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="appleStoreLink">Apple Store</label>
                <input
                  type="text" class="form-control" id="appleStoreLink"
                  [(ngModel)]="obj.appleStoreLink" name="appleStoreLink" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="webAppLink">WebApp</label>
                <input
                  type="text" class="form-control" id="webAppLink"
                  [(ngModel)]="obj.webAppLink" name="webAppLink" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="portalLink">Portale</label>
                <input
                  type="text" class="form-control" id="portalLink"
                  [(ngModel)]="obj.portalLink" name="portalLink" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="emailSupport">Email per il Support</label>
                <input
                  type="text" class="form-control" id="emailSupport"
                  [(ngModel)]="obj.emailSupport" name="emailSupport" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="emailSupport">Color</label>
                <input
                  type="text" class="form-control" id="color"
                  [(ngModel)]="obj.color" name="color" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="emailSupport">Secondary logo URL</label>
                <input
                  type="text" class="form-control" id="secondaryLogoUrl"
                  [(ngModel)]="obj.secondaryLogoUrl" name="secondaryLogoUrl" >
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="emailSupport">Background image URL</label>
                <input
                  type="text" class="form-control" id="backgroundImgUrl"
                  [(ngModel)]="obj.backgroundImgUrl" name="backgroundImgUrl" >
              </div>
            </div>
          </div>

        </div>
    </div>

    <div class="form-group mt-3">
      <button type="submit" class="btn btn-success btn-lg" [disabled]="!objForm.valid " [ladda]="saveLoading">{{ 'COMMON.save' | translate }}</button>
      <span class="btn" [hidden]="!saved"><strong>{{ 'COMMON.saved' | translate }}</strong></span>
    </div>

  </form>

</div>
