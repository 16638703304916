import { CoreHttpModelBase } from './../core';

export class Event extends CoreHttpModelBase {
    id: number
    name: string
    type: string
    read?: boolean;
    created_at: string
    edited_at: string
    year?: any
    brand_id?: any
    area_id?: any
    degree_id?: any
    note?: any
    loadings: any = {}
}

export const EVENT_FILTERS = {
  post: [],
  patch: [ ]
}
