import { CoreHttpModelBase } from '../core';

export class Newsletter extends CoreHttpModelBase {
  id: string;
  name: string;
  send_at?: string;
  sent_at?: string;
  type: string;
  tested_at?: string;
  created_at?: string;
  edited_at?: string;
  subject: string;
  body?: string;
  cta_title?: string;
  cta_url?: string;
  main_color?: string;
  tested?: boolean;
  totals: number;
  statuses?: string[];
  roles?: string[];
  consents: {
    tos: boolean;
    tos_after_deadline: string;
    tos_before_deadline: string;
    privacy: boolean;
    privacy_after_deadline: string;
    privacy_before_deadline: string;
    commercial: boolean;
    commercial_after_deadline: string;
    commercial_before_deadline: string;
    third: boolean;
    third_after_deadline: string;
    third_before_deadline: string;
  }
  model: 'EMAIL' | 'PUSH' | 'SMS';
  cluster: {
    roles: string[],
    statuses: string[]
    organization_ids?: string[]
  };
  loadings: {
    completeObj?: boolean;
    all?: boolean;
  } = {};
}

export const NEWSLETTER_FILTERS = {
  post: [
    'name',
    'type',
    'subject',
    'model',
    'body',
    'cta_title',
    'cta_url',
    'main_color',
    'consents',
    'cluster'
  ],
  patch: [
    'name',
    'type',
    'subject',
    'body',
    'cta_title',
    'cta_url',
    'main_color',
    'model',
    'cluster'
  ]
}
