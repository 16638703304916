import { CoreHttpModelBase } from './../core';

export class Communication extends CoreHttpModelBase {
    id: number
    title: string
    subtitle: string
    text: string
    type: string
    tag: string
    lang: string
    status: string
    attachments: any[] = []
    created_at: string
    edited_at: string
    loadings: any = {}
}

export const COMMUNICATION_FILTERS = {
  post: [ "title", "subtitle", "text", "type", "tag", "status", "lang", "attachments"],
  patch: [ "title", "subtitle", "text", "type", "tag", "status", "lang", "attachments"]
}
