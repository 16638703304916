
<div class="block block-m-t">

  <h1 [hidden]="mode == 'inline'">
    <!-- <span [hidden]="query._title">Elenco comunicazioni</span>
    <span [hidden]="!query._title">Elenco comunicazioni</span> -->
    {{ query._title || 'ticket.list' | translate }}
    <span [hidden]="! page || page == 1"> - {{ 'COMMON.page' | translate }} {{ page }}</span>
    <!-- <span class="pull-right" *ngIf="acl == 'edit'">
      <button class="btn btn-info" (click)="add()" >Crea</button>
    </span> -->
  </h1>



  <form #searchForm="ngForm" class="searchForm" (ngSubmit)="navigate(1)"  [hidden]="mode == 'inline' && !modeSelect && !forceForm" >
    <div class="row">
      <div class="col-lg-11">
        <div class="form-group" >
              <!-- <div class="btn-group" >
                <label for="id" class="btn btn-default" >Nome</label>
                <input type="text" class="form-control" id="firstname" style="height:34px" [(ngModel)]="query.firstname" name="firstname">
              </div> -->



              <div class="btn-group" >
                <label class="btn btn-default">Status</label>
                <div>
                  <div class="btn-group" ngbRadioGroup name="status" [(ngModel)]="query.status">
                    <label ngbButtonLabel class="btn-info" *ngFor="let s of 'ticketStatus' | CoreLabelPipe : null : '*'">
                      <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                    </label>
                    <label ngbButtonLabel class="btn-info" [hidden]="!query.status">
                      <input ngbButton type="radio" (click)="query.status = ''"> <i class="fa fa-close"></i>
                    </label>
                  </div>
                </div>
              </div>

              <div class="btn-group" >
                <label class="btn btn-default">Area</label>
                <div>
                  <div class="btn-group" ngbRadioGroup name="area" [(ngModel)]="query.area">
                    <label ngbButtonLabel class="btn-info" *ngFor="let s of 'ticketArea' | CoreLabelPipe : null : '*'">
                      <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                    </label>
                    <label ngbButtonLabel class="btn-info" [hidden]="!query.area">
                      <input ngbButton type="radio" (click)="query.area = ''"> <i class="fa fa-close"></i>
                    </label>
                  </div>
                </div>
              </div>

              <div class="btn-group" >
                <label class="btn btn-default">{{'ticket.priority' | translate}}</label>
                <div>
                  <div class="btn-group" ngbRadioGroup name="priority" [(ngModel)]="query.priority">
                    <label ngbButtonLabel class="btn-info" *ngFor="let s of 'ticketPriority' | CoreLabelPipe : null : '*'">
                      <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                    </label>
                    <label ngbButtonLabel class="btn-info" [hidden]="!query.priority">
                      <input ngbButton type="radio" (click)="query.priority = ''"> <i class="fa fa-close"></i>
                    </label>
                  </div>
                </div>
              </div>

        </div>
      </div>
      <div class="col-lg-1 text-right">
        <div class="form-group">
          <button class="btn btn-outline-info" type="submit"><i class="fa fa-search"></i></button>
        </div>
      </div>
    </div>
  </form>


  <div class="overflow-x-scroll" [coreLoader]="loading">

    <table class="table table-bordered">
      <thead class="">
        <tr class="bg-dark lead" [hidden]="mode == 'inline'">
          <th class="text-center " colspan="10">
            Ticket
          </th>
        </tr>

        <tr>
          <th class="text-center">
            {{'COMMON.title' | translate}}
          </th>
          <th class="text-center">
            Area
          </th>
          <th class="text-center">
            Status
          </th>
          <th class="text-center">
            {{'ticket.priority' | translate}}
          </th>
          <th class="text-center">
            {{'COMMON.create2' | translate}}
          </th>
          <th class="text-center">
            {{'ticket.richiesta_da' | translate}}
          </th>
          <th class="text-center">
            {{'ticket.assign_to' | translate}}
          </th>
          <th class="text-center" *ngIf="acl == 'edit'">

          </th>
        </tr>
      </thead>
      <tbody *ngIf="loaded">
        <tr *ngFor="let obj of list; let i = index" class="animated fadeIn" >
          <td class="text-center">
            {{ obj.title }}
          </td>
          <td  class="text-center" >
              {{ 'ticketArea' | CoreLabelPipe : obj.area | translate }}
          </td>
          <td  class="text-center" >
              {{ 'ticketStatus' | CoreLabelPipe : obj.status | translate }}
          </td>
          <td  class="text-center" >
              {{ 'ticketPriority' | CoreLabelPipe : obj.priority | translate }}
          </td>
          <td class="text-center">
            {{ obj.created_at | CoreDatePipe }}
          </td>
          <td class="text-center">
            <span *ngIf="obj.user">{{ obj.user.lastname }} {{ obj.user.firstname }} </span>
          </td>
          <td class="text-center">
            <span *ngIf="obj.assigned_to">{{ obj.assigned_to.lastname }} {{ obj.assigned_to.firstname }} </span>
          </td>

          <td class="text-center pb-0" *ngIf="obj && obj.loadings && ! modeSelect && acl=='edit'">
            <button type="button" class="btn btn-sm btn-outline-info"  (click)="edit(obj, i)">{{'COMMON.edit' | translate}}</button>
            <button type="button" class="btn btn-sm btn-outline-danger"  (click)="delete(obj, i)" [ladda]="obj.loadings['delete']" data-spinner-color="#000" data-style="zoom-in"><i class="fa fa-remove"></i></button>
          </td>
          <td class="text-center pb-0" *ngIf="modeSelect && acl == 'edit' ">
            <button type="button" class="btn btn-sm btn-outline-info"  (click)="select(obj, i)">{{'COMMON.select' | translate}}</button>
          </td>

          <!-- <td>
            <button type="button" class="btn btn-sm btn-outline-info" routerLink="/ticket/edit/{{ obj.id }}">Gestisci</button>
          </td> -->
        </tr>
      </tbody>
    </table>

    <ng-container *ngIf="mode == 'inline'" >
      <p class="mb-0">
        <!-- <button type="button" class="btn btn-sm btn-info btn-block-inline" (click)="add(true)" [ngClass]="{'btn-block-inline-active': newInlineChild}"><span [hidden]="newInlineChildId">
          {{ 'COMMON.create' | translate }}</span><span [hidden]="!newInlineChildId">{{ 'COMMON.edit2' | translate }}</span> <i [hidden]="!newInlineChild" class="fa fa-arrow-down animated fadeIn"></i></button> -->
        <button type="button" class="btn btn-sm animated fadeIn btn-block-inline btn-block-inline-active" (click)="add(false)" [hidden]="!newInlineChild" ><i class="fa fa-close"></i></button>
      </p>
      <div class="block-inline" *ngIf="newInlineChild">
        <ticket-edit  [mode]="mode" [paramsIn]="{id: newInlineChildId}" [objIn]="objIn"></ticket-edit>
      </div>
    </ng-container>


    <p [hidden]="loaded && list.length || !loaded" class="text-center">
        {{ 'COMMON.nothing' | translate }}
    </p>

    <hr style="margin:50px 0"/>

  </div>
</div>
<core-pagination-nav [pageInfo]="pageInfo" [(page)]="page" (changed)="navigate($event)"  ></core-pagination-nav>
