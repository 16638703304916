<h1>Dashboard</h1>

<!-- <p class="text-center mt-5" *ngIf="!loaded">
  <i class="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i>
</p> -->

<div class="row animated fadeIn" *ngIf="loaded">
  <div class="col-lg-3">
    <div class="card" >
      <img class="card-img-top" src="http://via.placeholder.com/300x100/333/fff?text={{ dashboard.NEW || '-' }}" alt="Card image cap">
      <div class="card-body text-center">
        <h4 class="card-title">{{ 'user.open_request' | translate }}</h4>
        <p class="card-text">{{ 'user.new_request' | translate }}</p>
        <a routerLink="/ticket/list/1" [queryParams]="{ 'statusQ': 'NEW'}" class="btn btn-primary">{{ 'user.view_request' | translate }}</a>
      </div>
    </div>
  </div>
  <div class="col-lg-3">
    <div class="card" >
      <img class="card-img-top" src="http://via.placeholder.com/300x100/333/fff?text={{ dashboard.IN_CHARGE || '-' }}" alt="Card image cap">
      <div class="card-body text-center">
        <h4 class="card-title">{{ 'user.label' | translate }}</h4>
        <p class="card-text">{{ 'user.request_open2' | translate }}</p>
        <a routerLink="/ticket/list/1" [queryParams]="{ 'statusQ': 'IN_CHARGE'}" class="btn btn-warning">{{ 'user.view_request' | translate }}</a>
      </div>
    </div>
  </div>
  <div class="col-lg-3">
    <div class="card" >
      <img class="card-img-top" src="http://via.placeholder.com/300x100/333/fff?text={{ dashboard.SOLVED || '-' }}" alt="Card image cap">
      <div class="card-body text-center">
        <h4 class="card-title">{{ 'user.resolved_request' | translate }}</h4>
        <p class="card-text">{{ 'user.completed_request' | translate }}</p>
        <a routerLink="/ticket/list/1" [queryParams]="{ 'statusQ': 'SOLVED'}" class="btn btn-success">{{ 'user.view_request' | translate }}</a>
      </div>
    </div>
  </div>
  <div class="col-lg-3">
    <div class="card" >
      <img class="card-img-top" src="http://via.placeholder.com/300x100/333/fff?text={{ dashboard.REJECTED || '-' }}" alt="Card image cap">
      <div class="card-body text-center">
        <h4 class="card-title">{{ 'user.rejected_request' | translate }}</h4>
        <p class="card-text">{{ 'user.rejected_request2' | translate }}</p>
        <a routerLink="/ticket/list/1" [queryParams]="{ 'statusQ': 'REJECTED'}" class="btn btn-danger">{{ 'user.view_request' | translate }}</a>
      </div>
    </div>
  </div>
  <!-- <div class="col-lg-3">
    <p>
      Quick links
    </p>
    <ul>
      <li>
        <a href="">Visualizza ultime richieste</a>
      </li>
      <li>
        <a href="">Visualizza utenti attivi</a>
      </li>
      <li>
        <a href="">Crea nuova richiesta</a>
      </li>

    </ul>
  </div> -->
</div>
