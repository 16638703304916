<div class="block block-m-t">
  <h1 class="d-flex flex-wrap justify-content-between">
    <span class="pointer">
      {{ 'statistics.device' | translate }}: {{ data?.id }}
      &nbsp;<i class="fa fa-info-circle" placement="right" [ngbTooltip]="'statistics.tooltipDashboard' | translate"></i>
    </span>
  </h1>

  <div class="row">
    <div class="col-lg-3 col-md-12">
      <div class="d-flex flex-row justify-content-start py-1">
        <div class="btn-group">
          <div>
            <div class="display-inline-block py-1">
              <div class="input-group">
                <select class="form-control" id="chartPeriod" name="chartPeriod" [(ngModel)]="selectedChartPeriod"
                  (change)="changePeriod()">
                  <option *ngFor="let period of chartPeriod" [ngValue]="period">{{ 'statistics.period-' + period |
                    translate }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-9 col-md-12" *ngIf="!isNoRangePeriod">
      <div class="d-flex flex-row justify-content-lg-end py-1">
        <div class="btn-group mr-1">
          <div *ngIf="selectedChartPeriod === 'daily'">
            <div class="display-inline-block py-1">
              <div class="input-group">
                <input class="form-control" type="text" name="chartRange" [ngModel]="selectedChartRange ? {
                    year: selectedChartRange.start.getFullYear(),
                    month: (selectedChartRange.start.getMonth() + 1),
                    day: selectedChartRange.start.getDate()
                  } : null" (dateSelect)="formatDayRange($event)" ngbDatepicker placeholder="01 Jan 2020"
                  #d="ngbDatepicker" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary dropdown-toggle-split not-close-datepicker"
                    (click)="d.toggle(); dc.close()" type="button">
                    <div class="calendar not-close-datepicker" aria-hidden="true"></div>
                  </button>
                </div>
              </div>
            </div>
            <div class="display-inline-block py-1">
              <div class="input-group">
                <input class="form-control" type="text" name="chartRangeComparison" [ngModel]="selectedChartRangeComparison ? {
                    year: selectedChartRangeComparison.start.getFullYear(),
                    month: (selectedChartRangeComparison.start.getMonth() + 1),
                    day: selectedChartRangeComparison.start.getDate()
                  } : null" (dateSelect)="formatDayRangeComparison($event)" ngbDatepicker
                  placeholder="{{ 'statistics.compareWith' | translate }}" #dc="ngbDatepicker" />
                <button class="datepicker-clearform" type="button"
                  *ngIf="!!selectedChartRangeComparison && !!selectedChartRangeComparison.start"
                  (click)="removeComparison()">
                  <i class="fa fa-times"></i>
                </button>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary dropdown-toggle-split not-close-datepicker"
                    (click)="dc.toggle(); d.close()" type="button">
                    <div class="calendar not-close-datepicker" aria-hidden="true"></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="selectedChartPeriod === 'weekly'">
            <core-week-date-picker [model]="selectedChartRange" [format]="'DD/MM/YYYY'" [locale]="getMomentUserLocale()"
              [closeDatePickerInput]="closeWeek" (dateOpened)="closeWeekComparison = true; closeWeek = false;">
            </core-week-date-picker>
            <core-week-date-picker [model]="selectedChartRangeComparison" [format]="'DD/MM/YYYY'" [isComparison]="true"
              [placeholder]="'statistics.compareWith'" [locale]="getMomentUserLocale()"
              [closeDatePickerInput]="closeWeekComparison"
              (dateOpened)="closeWeekComparison = false; closeWeek = true;">
            </core-week-date-picker>
          </div>

          <div *ngIf="selectedChartPeriod === 'monthly'">
            <core-month-date-picker name="chartRange" [model]="selectedChartRange" [format]="'MMM YYYY'"
              [mode]="'month'" [locale]="getMomentUserLocale()">
            </core-month-date-picker>
            <core-month-date-picker name="selectedChartRangeComparison" [model]="selectedChartRangeComparison"
              [format]="'MMM YYYY'" [mode]="'month'" [isComparison]="true" [placeholder]="'statistics.compareWith'"
              [locale]="getMomentUserLocale()">
            </core-month-date-picker>
          </div>
          <div *ngIf="selectedChartPeriod === 'annual'">
            <core-month-date-picker name="chartRange" [model]="selectedChartRange" [format]="'YYYY'" [mode]="'year'"
              [locale]="getMomentUserLocale()">
            </core-month-date-picker>
            <core-month-date-picker name="selectedChartRangeComparison" [model]="selectedChartRangeComparison"
              [format]="'YYYY'" [mode]="'year'" [isComparison]="true" [placeholder]="'statistics.compareWith'"
              [locale]="getMomentUserLocale()">
            </core-month-date-picker>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div [ngClass]="{'mb-0': isMobile}" *ngIf="this.data && adviceList && adviceList.length" class="block block-m-t">

  <h1>
    <div class="row justify-content-between">
      <div class="col-lg-4">
        {{ 'statistics.advicesTitle' | translate }}
      </div>

      <div class="col-auto">
        {{ 'statistics.new' | translate }} <span class="badge badge-info">{{ adviceList?.length || 0 }}</span>
      </div>
    </div>
  </h1>

  <div [coreLoader]="adviceLoading">
    <table class="table table-bordered">
      <thead class="">
        <tr>
          <th class="text-center">
            {{'COMMON.message' | translate}}
          </th>
          <th class="text-center">
            {{'COMMON.date' | translate}}
          </th>
        </tr>
      </thead>
      <tbody *ngIf="adviceLoaded">
        <tr *ngFor="let obj of adviceList | slice:0:5; let i = index" class="animated fadeIn">
          <td class="text-center">
            {{ obj.text }}
          </td>
          <td class="text-center">
            {{ obj.created_at | CoreDatePipe : 'dd/MM/yyyy HH:mm' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row justify-content-end">
    <div class="col-auto">
      <button (click)="goToTips()" type="button" class="btn btn-info">{{ 'statistics.goToTips' | translate }}</button>
    </div>
  </div>
</div>


<div [ngClass]="{'mb-0': isMobile}" [hidden]="!(data?.groups?.length)" class="block block-m-t">

  <h1>
    <div class="row justify-content-between">
      <div class="col-lg-4">
        <ng-template #tooltipGroup><span class="text-left"
            [innerHTML]="'statistics.tooltipGroup' | translate"></span></ng-template>
        {{ 'statistics.group' | translate }}
        &nbsp;<i class="fa fa-info-circle" placement="right" [ngbTooltip]="tooltipGroup"
          *ngIf="(identity.role !== 'ROLE_USER' && identity.role !== 'ROLE_PARENT')"></i>
      </div>

      <div class="col-lg-4">
        <div class="btn-group w-100">
          <select class="form-control" id="group" name="group" [(ngModel)]="selectedGroup" (change)="selectGroup()">
            <option *ngFor="let group of data?.groups" [ngValue]="group">{{ group.name }}</option>
          </select>
          <button *ngIf="(identity.role !== 'ROLE_USER' && identity.role !== 'ROLE_PARENT')" (click)="export('groups')"
            type="button" class="btn btn-outline-dark"><i class="fa fa-download"
              [ngbTooltip]="'statistics.export' | translate"></i></button>
        </div>
      </div>
    </div>
  </h1>

  <ng-container>
    <div class="row py-5" *ngIf="(identity.role !== 'ROLE_USER' && identity.role !== 'ROLE_PARENT')">
      <div class="col-lg-12">
        <div id="group-chartA"></div>
      </div>
    </div>

    <div class="row py-5">
      <div class="col-lg-12">
        <div id="group-chartD"></div>
      </div>
    </div>

    <div [hidden]="selectedChartRangeComparison" class="row py-5 justify-content-center"
      *ngIf="(identity.role !== 'ROLE_USER' && identity.role !== 'ROLE_PARENT')">
      <div class="col-lg-4">
        <div id="group-chartB" class="dunut-chart"></div>
      </div>
      <div class="col-lg-4">
        <div id="group-chartC" class="dunut-chart"></div>
      </div>
    </div>
  </ng-container>

</div>

<div [hidden]="!(selectedGroup?.rooms?.length)" class="block block-m-t">

  <h1>
    <div class="row justify-content-between">
      <div class="col-lg-4">
        <ng-template #tooltipRoom><span class="text-left"
            [innerHTML]="'statistics.tooltipRoom' | translate"></span></ng-template>
        {{ 'statistics.room' | translate }}
        &nbsp;<i class="fa fa-info-circle" placement="right" [ngbTooltip]="tooltipRoom"></i>
      </div>

      <div class="col-lg-4">
        <div class="btn-group w-100">
          <select class="form-control" id="group" name="group" [(ngModel)]="selectedRoom" (change)="selectRoom()">
            <option *ngFor="let room of selectedGroup?.rooms" [ngValue]="room">{{ room.name }}</option>
          </select>
          <button (click)="export('rooms')" type="button" class="btn btn-outline-dark"><i class="fa fa-download"
              [ngbTooltip]="'statistics.export' | translate"></i></button>
        </div>
      </div>
    </div>
  </h1>

  <div class="row py-5">
    <div class="col-lg-12">
      <div id="room-chartC"></div>
    </div>
  </div>
  <div class="row py-5">
    <div class="col-lg-12">
      <div id="room-chartA"></div>
    </div>
  </div>

  <div class="row py-5">
    <div class="col-lg-12">
      <div id="room-chartB"></div>
    </div>
  </div>
</div>

<div [hidden]="!(selectedGroup?.airMachines?.length)" class="block block-m-t">

  <h1>
    <div class="row justify-content-between">
      <div class="col-lg-4">
        <ng-template #tooltipAirMachine><span class="text-left"
            [innerHTML]="'statistics.tooltipAirMachine' | translate"></span></ng-template>
        <ng-template #tooltipAirMachineUser><span class="text-left"
            [innerHTML]="'statistics.tooltipAirMachineUser' | translate"></span></ng-template>
        {{ 'statistics.airMachine' | translate }}
        &nbsp;<i class="fa fa-info-circle" placement="right" [ngbTooltip]="tooltipAirMachine"
          *ngIf="(identity.role !== 'ROLE_USER' && identity.role !== 'ROLE_PARENT')"></i>
        <i class="fa fa-info-circle" placement="right" [ngbTooltip]="tooltipAirMachineUser"
          *ngIf="(identity.role == 'ROLE_USER' || identity.role == 'ROLE_PARENT')"></i>
      </div>

      <div class="col-lg-4">
        <div class="btn-group w-100">
          <select class="form-control" id="airMachine" name="airMachine" [(ngModel)]="selectedAirMachine"
            (change)="selectAirMachine()">
            <option *ngFor="let airMachine of selectedGroup?.airMachines" [ngValue]="airMachine">{{ airMachine.name }}
            </option>
          </select>
          <button (click)="export('air-machines')" type="button" class="btn btn-outline-dark"><i class="fa fa-download"
              [ngbTooltip]="'statistics.export' | translate"></i></button>
        </div>
      </div>
    </div>
  </h1>

  <div class="row py-5" [hidden]="!(identity.role !== 'ROLE_USER' && identity.role !== 'ROLE_PARENT')">
    <div class="col-lg-12">
      <div id="airMachine-chartA"></div>
    </div>
  </div>

  <div class="row py-5">
    <div class="col-lg-12">
      <div id="airMachine-chartB"></div>
    </div>
  </div>
</div>