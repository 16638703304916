import { Injectable, Pipe, PipeTransform } from '@angular/core';


@Pipe({
 name: 'CoreAttachmentPipe'
})

@Injectable({
  providedIn: 'root'
})
export class CoreAttachmentPipe implements PipeTransform {
 transform(filename: string): string {
   var r = "";
   if(filename) {
     r = filename.split("/").pop();
     r = r.split("?")[0];
   }
   return r;
 }
}
