import { CoreHttpModelBase } from './../core';

export class Ticketmaster extends CoreHttpModelBase {
    id: number
    title: string
    text: string
    area: number
    status: string
    priority: number
    user_id: string
    oem_id: number
    type:string
    notes: any[] = []
    assigned_to_id: string
    assigned_to: {}
    user: {}
    created_at: string
    edited_at: string
    loadings: any = {}
}

export const TICKETMASTER_FILTERS = {
  post: [ "title", "text", "area",  "user_id", "assigned_to_id"],
  patch: [ "title", "text", "area", "status", "priority", "assigned_to_id"]
}
