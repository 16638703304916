import { Component, Injectable, Input, Output, EventEmitter  } from '@angular/core';


@Component({
  selector: 'core-pagination-nav',
  templateUrl: './pagination-nav.component.html'
})

@Injectable({
  providedIn: 'root'
})
export class CorePaginationNavComponent {
  @Input() pageInfo;
  // implementation with setter (and in line change event in the view)
  @Input() set page(value) {
    this.changed.emit(value);
  };
  @Output() changed = new EventEmitter();

  // implementation with custome method on event of child parent
  // pageChange(page): void {
  //   this.page = page;
  //   console.log("PAGE", this.page);
  //   this.changed.emit(this.page);
  // }



  // constructor(private paginator: NgbPagination) {}
}
