import { Injectable } from '@angular/core';
import { CoreModalAlertComponent } from './modal-alert.component';
import { CoreModalConfirmComponent } from './modal-confirm.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})

export class CoreModalService {
  constructor(private modalService: NgbModal/*, private modalAlertComponent: AppModalAlertComponent*/) {
  }

  public alert(opt?): any {
    // console.log(opt);
    const modalRef = this.modalService.open(CoreModalAlertComponent);
    if (opt && opt.viewVars) {
      for (var k in opt.viewVars) {
        modalRef.componentInstance[k] = opt.viewVars[k];
      }
    }
    if (opt && opt.timeout) {
      setTimeout(() => {
        modalRef.dismiss()
      }, opt.timeout);
    }
    return modalRef.result;
  };

  public confirm(opt?): any {
    // console.log(opt);
    const modalRef2 = this.modalService.open(CoreModalConfirmComponent);
    if (opt && opt.viewVars) {
      for (var k in opt.viewVars) {
        modalRef2.componentInstance[k] = opt.viewVars[k];
      }
    }
    if (opt && opt.timeout) {
      setTimeout(() => {
        modalRef2.dismiss()
      }, opt.timeout);
    }
    return modalRef2.result;
  };

  public test(): void {
    // console.log("modal test");
  }

}
