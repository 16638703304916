<div class="block block-m-t">

  <h1 [hidden]="mode == 'inline'">
    <!-- <span [hidden]="query._title">Elenco comunicazioni</span>
    <span [hidden]="!query._title">Elenco comunicazioni</span> -->
    {{ query._title || 'device.list' | translate }}
    <span [hidden]="! page || page == 1"> - {{ 'COMMON.page' | translate }} {{ page }}</span>
    <!-- <span class="pull-right">
      <button class="btn btn-info" (click)="add()" >{{ 'COMMON.create' | translate }}</button>
    </span> -->
  </h1>



  <form #searchForm="ngForm" class="searchForm mb-3" (ngSubmit)="navigate(1)"
    [hidden]="mode == 'inline' && !modeSelect && !forceForm">
    <div class="row">
      <div class="col-lg-11">
        <div class="form-group">
          <div class="btn-group" *ngIf="identity.role == 'ROLE_ADMIN'">
            <label for="oem_id" class="btn btn-default btn-auto">{{ 'organization.title' | translate }} </label>
            <select class="form-control width-auto" id="oem_id" [(ngModel)]="query.oem_id" name="oem_id">
              <option [ngValue]="null">
                {{ 'organization.select' | translate }}
              </option>
              <option *ngFor="let o of data['organization']" value="{{ o.id }}">
                {{ o.name }}
              </option>
            </select>
          </div>
          <div class="btn-group">
            <label for="lastname" class="btn btn-default" style="min-width: 120px !important">{{ 'device.label12' |
              translate }} </label>

            <input class="form-control" id="id" [(ngModel)]="query.id" name="id" />
          </div>
          <div class="btn-group" [hidden]="isMobile">
            <label for="orderBy" class="btn btn-default" style="min-width: 100px !important">{{ 'COMMON.orderBy' |
              translate }} </label>
            <select class="form-control" id="orderBy" [(ngModel)]="query.orderBy" name="orderBy">
              <!-- <option value="null">
                {{ 'COMMON.orderNull' | translate }}
              </option> -->
              <option value="id">
                ID
              </option>
              <option value="created_at">
                {{ 'COMMON.create2' | translate }}
              </option>
              <option value="first_run_at">
                {{ 'device.label8' | translate }}
              </option>
              <option value="setup_at">
                {{ 'COMMON.date' | translate }} setup
              </option>
              <!-- <option value="expire_at">
                {{ 'device.scadenza' | translate }}
              </option> -->
            </select>
            <select class="form-control" id="order" [(ngModel)]="query.order" name="order" style="margin-left:10px">
              <!-- <option value="null">
                {{ 'COMMON.orderNull' | translate }}
              </option> -->

              <option value="DESC">
                {{ 'COMMON.orderDesc' | translate }}
              </option>
              <option value="ASC">
                {{ 'COMMON.orderAsc' | translate }}
              </option>

            </select>
          </div>
        </div>
      </div>
      <div class="col-lg-1 text-right">
        <div class="form-group">
          <button class="btn btn-outline-info" type="submit"><i class="fa fa-search"></i></button>
        </div>
      </div>
    </div>
  </form>


  <div class="overflow-x-scroll" [coreLoader]="loading">

    <table class="table table-bordered"
      *ngIf="!isMobile && (identity.role !== 'ROLE_USER' && identity.role !== 'ROLE_PARENT')">
      <thead class="">
        <tr class="bg-dark lead" [hidden]="mode == 'inline'">
          <th class="text-center " colspan="10">
            {{ 'device.title' | translate }}
          </th>
        </tr>

        <tr>
          <th class="text-center">
            ID
          </th>
          <th class="text-center">
            {{ 'COMMON.create2' | translate }}
          </th>
          <th class="text-center">
            {{ 'device.label8' | translate }}
          </th>
          <th class="text-center">
            {{ 'COMMON.date' | translate }} setup
          </th>
          <!-- <th class="text-center">
            {{ 'device.scadenza' | translate }}
          </th> -->
          <th class="text-center">
            PIN/PUK
          </th>

          <th class="text-center">
            {{ 'device.label11' | translate }}
          </th>
        </tr>
      </thead>
      <tbody *ngIf="loaded">
        <tr *ngFor="let obj of list; let i = index" class="animated fadeIn"
          [ngClass]="{'refused': obj.status == 'REFUSED', 'highlight': obj.loadings && obj.loadings.new}">
          <td class="text-center">
            {{ obj.id }}
          </td>
          <td class="text-center">
            {{ obj.created_at | CoreDatePipe : 'MMM d, y' }}
          </td>
          <td class="text-center">
            {{ obj.first_run_at | CoreDatePipe : 'MMM d, y' }}
          </td>
          <td class="text-center">
            {{ obj.setup_at | CoreDatePipe : 'MMM d, y' }}
          </td>
          <!-- <td class="text-center">
            {{ obj.expire_at | CoreDatePipe : 'MMM d, y' }}
          </td> -->
          <td class="text-center">
            {{ obj.pin }}
          </td>

          <td class="text-center pb-0" *ngIf="obj && obj.loadings && ! modeSelect">
            <button type="button" class="btn btn-sm btn-outline-info" (click)="edit(obj, i)"> {{ 'COMMON.edit' |
              translate }}</button>
            <!--<button type="button" class="btn btn-sm btn-outline-danger"  (click)="delete(obj, i)" [ladda]="obj.loadings['delete']" data-spinner-color="#000" data-style="zoom-in"><i class="fa fa-remove"></i></button>-->
          </td>
          <td class="text-center pb-0" *ngIf="modeSelect">
            <button type="button" class="btn btn-sm btn-outline-info" (click)="select(obj, i)">{{ 'COMMON.select' |
              translate }}</button>
          </td>

          <!-- <td>
            <button type="button" class="btn btn-sm btn-outline-info" routerLink="/device/edit/{{ obj.id }}">Gestisci</button>
          </td> -->
        </tr>
      </tbody>
    </table>

    <table class="table table-bordered"
      *ngIf="!isMobile && !(identity.role !== 'ROLE_USER' && identity.role !== 'ROLE_PARENT')">
      <thead class="">
        <tr class="bg-dark lead" [hidden]="mode == 'inline'">
          <th class="text-center " colspan="10">
            {{ 'device.title' | translate }}
          </th>
        </tr>

        <tr>
          <th class="text-center">
            {{ 'device.title2' | translate }}
          </th>
          <th class="text-center">
            {{ 'statistics.device' | translate}}
          </th>

          <th class="text-center">
            {{ 'device.label8' | translate }}
          </th>

          <th class="text-center">
            PIN/PUK
          </th>

          <th class="text-center">
            {{ 'device.edit2' | translate }}
          </th>
        </tr>
      </thead>
      <tbody *ngIf="loaded">
        <tr *ngFor="let obj of list; let i = index" class="animated fadeIn"
          [ngClass]="{'refused': obj.status == 'REFUSED', 'highlight': obj.loadings && obj.loadings.new}">
          <td class="text-center">
            {{ obj?.data?.reported?.loc || '--' }}
          </td>
          <td class="text-center">
            {{ obj.id }}
          </td>

          <td class="text-center">
            {{ obj.first_run_at | CoreDatePipe : 'MMM d, y' }}
          </td>

          <td class="text-center">
            {{ obj.pin }}
          </td>

          <td class="text-center pb-0" *ngIf="obj && obj.loadings && ! modeSelect">
            <button type="button" class="btn btn-sm btn-outline-info" (click)="edit(obj, i)"> {{ 'device.edit2' |
              translate }}</button>
            <!--<button type="button" class="btn btn-sm btn-outline-danger"  (click)="delete(obj, i)" [ladda]="obj.loadings['delete']" data-spinner-color="#000" data-style="zoom-in"><i class="fa fa-remove"></i></button>-->
          </td>
          <td class="text-center pb-0" *ngIf="modeSelect">
            <button type="button" class="btn btn-sm btn-outline-info" (click)="select(obj, i)">{{ 'COMMON.select' |
              translate }}</button>
          </td>

          <!-- <td>
            <button type="button" class="btn btn-sm btn-outline-info" routerLink="/device/edit/{{ obj.id }}">Gestisci</button>
          </td> -->
        </tr>
      </tbody>
    </table>


    <div *ngIf="isMobile">

      <div class="card" *ngIf="'ROLE_USER' !== identity.role && 'ROLE_PARENT' !== identity.role">
        <div class="card-body text-left">
          <p>{{ 'device.label8' | translate }}: <i class="fa fa-plug" aria-hidden="true"></i></p>
          <p>{{ 'COMMON.date' | translate }} setup: <i class="fa fa-star-o" aria-hidden="true"></i></p>
          <p>{{ 'device.scadenza' | translate }}: <i class="fa fa-remove"></i></p>
        </div>
      </div>

      <ng-container *ngFor="let obj of list; let i = index" class="animated fadeIn">
        <button class="btn btn-outline-info w-100 my-2 text-left" type="button" (click)="edit(obj, i)">
          {{obj?.data?.reported?.loc || '--'}} (PIN/PUK: {{obj.pin}})<br>

          SN: {{obj.id}} <br>

          <span *ngIf="'ROLE_USER' !== identity.role && 'ROLE_PARENT' !== identity.role">
            {{ 'COMMON.create2' | translate }}: {{ obj.created_at | CoreDatePipe : 'MMM d, y' }} <br>

            <span [hidden]="!obj.first_run_at"><i class="fa fa-plug" aria-hidden="true"></i> {{ obj.first_run_at |
              CoreDatePipe : 'MMM d, y' }}&nbsp;&nbsp;&nbsp;</span>
            <span [hidden]="!obj.setup_at"><i class="fa fa-star-o" aria-hidden="true"></i> {{ obj.setup_at |
              CoreDatePipe : 'MMM d, y' }}&nbsp;&nbsp;&nbsp;</span>
            <span [hidden]="!obj.expire_at"><i class="fa fa-remove" aria-hidden="true"></i> {{ obj.expire_at |
              CoreDatePipe : 'MMM d, y' }}&nbsp;&nbsp;&nbsp;</span>
          </span>
        </button>
      </ng-container>
    </div>

    <ng-container *ngIf="mode == 'inline'">
      <!-- <p class="mb-0">
        <button type="button" class="btn btn-sm btn-info btn-block-inline" (click)="add(true)" [ngClass]="{'btn-block-inline-active': newInlineChild}"><span [hidden]="newInlineChildId">{{ 'COMMON.create' | translate }}</span><span [hidden]="!newInlineChildId">{{ 'COMMON.edit2' | translate }}</span> <i [hidden]="!newInlineChild" class="fa fa-arrow-down animated fadeIn"></i></button>
        <button type="button" class="btn btn-sm animated fadeIn btn-block-inline" (click)="add(false)" [hidden]="!newInlineChild" ><i class="fa fa-close"></i></button>
      </p> -->
      <div class="block-inline" *ngIf="newInlineChild">
        <device-edit [mode]="mode" [paramsIn]="{id: newInlineChildId}" [objIn]="objIn"></device-edit>
      </div>
    </ng-container>


    <p [hidden]="loaded && list.length || !loaded" class="text-center">
      {{ 'COMMON.nothing' | translate }}
    </p>

    <hr style="margin:50px 0" />

  </div>
</div>
<core-pagination-nav [pageInfo]="pageInfo" [(page)]="page" (changed)="navigate($event)"></core-pagination-nav>