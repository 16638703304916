import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home';
import { MainDashboardComponent } from './main';
import {
  UserDashboardComponent,
  UserEditComponent,
  UserListComponent,
  UserLoginComponent,
  UserLogoutComponent,
  UserRedirectComponent
} from './user';
import { DeviceEditComponent, DeviceListComponent } from './device';
import { EventEditComponent, EventListComponent } from './event';
import { ContentEditComponent, ContentListComponent } from './content';
import { ContentmasterEditComponent, ContentmasterListComponent } from './contentmaster';
import { OrganizationEditComponent, OrganizationListComponent } from './organization';
import { TicketEditComponent, TicketListComponent } from './ticket';
import { TaskEditComponent, TaskListComponent } from './task';
import { TicketmasterEditComponent, TicketmasterListComponent } from './ticketmaster';
import { CommunicationEditComponent, CommunicationListComponent } from './communication';
import { StatisticsViewComponent } from './statistics';
import { AdviceListComponent } from './advice';
import { NotificationEditComponent } from './notification';
import { NgModule } from '@angular/core';
import { NewsletterEditComponent, NewsletterListComponent } from "./newsletter";

const ROUTES: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'main/dashboard', component: MainDashboardComponent, data: { acl: '*', section: 'main.dashboard' } },
  { path: 'user/dashboard', component: UserDashboardComponent, data: { acl: '*', section: 'dashboard' } },
  { path: 'user/list/:page', component: UserListComponent, data: { acl: '*', section: 'user.list' } },
  { path: 'user/edit/:id', component: UserEditComponent, data: { acl: '*', section: 'user.edit' } },
  { path: 'user/login', component: UserLoginComponent, data: { area: 'login' } },
  { path: 'user/redirect', component: UserRedirectComponent, data: { area: 'login' } },
  { path: 'user/logout', component: UserLogoutComponent, data: { acl: '*' } },
  { path: 'device/list', component: DeviceListComponent, data: { acl: '*', section: 'device.list' } },
  { path: 'device/edit/:id', component: DeviceEditComponent, data: { acl: '*', section: 'device.edit' } },
  { path: 'event/list', component: EventListComponent, data: { acl: '*', section: 'event.list' } },
  { path: 'event/edit/:id', component: EventEditComponent, data: { acl: '*', section: 'event.edit' } },
  { path: 'content/list', component: ContentListComponent, data: { acl: '*', section: 'content.list' } },
  { path: 'content/edit/:id', component: ContentEditComponent, data: { acl: '*', section: 'content.edit' } },
  {
    path: 'contentmaster/list',
    component: ContentmasterListComponent,
    data: { acl: '*', section: 'contentmaster.list' }
  },
  {
    path: 'contentmaster/edit/:id',
    component: ContentmasterEditComponent,
    data: { acl: '*', section: 'contentmaster.edit' }
  },
  { path: 'organization/list', component: OrganizationListComponent, data: { acl: '*', section: 'organization.list' } },
  {
    path: 'organization/edit/:id',
    component: OrganizationEditComponent,
    data: { acl: '*', section: 'organization.edit' }
  },
  { path: 'ticket/list', component: TicketListComponent, data: { acl: '*', section: 'ticket.list' } },
  { path: 'ticket/edit/:id', component: TicketEditComponent, data: { acl: '*', section: 'ticket.edit' } },
  { path: 'task/list', component: TaskListComponent, data: { acl: '*', section: 'task.list' } },
  { path: 'task/edit/:id', component: TaskEditComponent, data: { acl: '*', section: 'task.edit' } },
  { path: 'ticketmaster/list', component: TicketmasterListComponent, data: { acl: '*', section: 'ticketmaster.list' } },
  {
    path: 'ticketmaster/edit/:id',
    component: TicketmasterEditComponent,
    data: { acl: '*', section: 'ticketmaster.edit' }
  },
  {
    path: 'communication/list',
    component: CommunicationListComponent,
    data: { acl: '*', section: 'communication.list' }
  },
  {
    path: 'communication/edit/:id',
    component: CommunicationEditComponent,
    data: { acl: '*', section: 'communication.edit' }
  },
  { path: 'telemetry/view/:id', component: StatisticsViewComponent, data: { acl: '*', section: 'statistics.view' } },
  { path: 'advice/list', component: AdviceListComponent, data: { acl: '*', section: 'advice.list' } },
  { path: 'notification/edit', component: NotificationEditComponent, data: { acl: '*', section: 'notification.edit' } },

  { path: 'newsletter/list', component: NewsletterListComponent, data: {acl: "*", section: 'newsletter.list'} },
  { path: 'newsletter/edit/:id', component: NewsletterEditComponent, data: {acl: "*", section: 'newsletter.edit'} },

  // { path: 'network/list', component: NetworkListComponent, data: {acl: "*", section: 'network.list'} },
  // { path: 'network/edit/:id', component: NetworkEditComponent, data: {acl: "*", section: 'network.edit'} },

];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
