
<div class="block block-m-t">

  <div  [hidden]="mode == 'inline'">
      <h4>{{'common-edit.title' | translate}}</h4>
      <p>
        * {{'common-edit.required' | translate}}
      </p>
  </div>


  <form #objForm="ngForm" class="form-standard" [hidden]="!(new || obj.id)" (ngSubmit)="save()">
    <div [ngClass]="{'animated fadeOut': saveLoading}">

      <p class="tabs">
        <button type="button" *ngFor="let t of tabs; let i = index" class="btn btn-lg" [ngClass]="{'btn-info': tab == i, 'btn-link': i != tab}" (click)="tab = i">{{ 'tabs.' + tabs[i] | translate }}</button>
      </p>

        <div [hidden]="tab != 0" class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <div class="form-group">
                <label for="name">Nome event *</label>
                <input
                  type="text" class="form-control" id="name"
                  required
                  [(ngModel)]="obj.name" name="name" >
              </div>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="form-group">
              <label for="name">Anno *</label>
              <div>
                  <select class="form-control" id="year"
                     required
                     [(ngModel)]="obj.year" name="year" >
                     <option [ngValue]="null">
                       Seleziona un valore
                     </option>
                     <option *ngFor="let c of data['year']" [value]="c.id">
                       {{ c.name }}
                     </option>
                  </select>
              </div>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="form-group">
              <label for="type">Tipologia *</label>
              <div>
                <div class="btn-group" ngbRadioGroup name="type" [(ngModel)]="obj.type">
                  <label ngbButtonLabel class="btn-info" *ngFor="let t of 'communicationType' | CoreEnumPipe">
                    <input ngbButton type="radio" [value]="t.id"> {{t.label | translate }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group">
              <label for="name">Marchio *</label>
              <div>
                  <select class="form-control" id="brand"
                     required
                     [(ngModel)]="obj.brand_id" name="brand" >
                     <option [ngValue]="null">
                       Seleziona un valore
                     </option>
                     <option *ngFor="let c of data['brand']" [value]="c.id">
                       {{ c.name }}
                     </option>
                  </select>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group">
              <label for="name">Area *</label>
              <div>
                  <select class="form-control" id="area"
                     required
                     [(ngModel)]="obj.area_id" name="area" >
                     <option [ngValue]="null">
                       Seleziona un valore
                     </option>
                     <option *ngFor="let c of data['area']" [value]="c.id">
                       {{ c.name }}
                     </option>
                  </select>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group">
              <label for="name">Grado scolastico *</label>
              <div>
                  <select class="form-control" id="degree"
                     required
                     [(ngModel)]="obj.degree_id" name="degree" >
                     <option [ngValue]="null">
                       Seleziona un valore
                     </option>
                     <option *ngFor="let c of data['degree']" [value]="c.id">
                       {{ c.name }}
                     </option>
                  </select>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group">
              <label for="name">Note</label>
              <div>
                  <textarea [(ngModel)]="obj.note" class="form-control" rows="5" name="note"></textarea>
              </div>

            </div>
          </div>
        </div>
    </div>

    <div class="form-group mt-3">
      <button type="submit" class="btn btn-success btn-lg" [disabled]="!objForm.valid || !obj.type " [ladda]="saveLoading">{{ 'COMMON.save' | translate }}</button>
      <span class="btn" [hidden]="!saved"><strong>{{ 'COMMON.saved' | translate }}</strong></span>
    </div>

  </form>

</div>
