import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService, Device } from './';
import { CoreStorageService, CoreMessageService } from '../core'
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';


@Component({
  selector: 'device-edit',
  templateUrl: './device-edit.component.html'
})
export class DeviceEditComponent implements OnInit, OnDestroy {


  //spostare in config
  roomTypes = {
    "0": "Cameretta",
    "1": "Camera da letto",
    "2": "Camera matrimoniale",
    "3": "Corridoio",
    "4": "Cucina",
    "5": "Esterno",
    "6": "Giardino",
    "7": "Camera degli ospiti",
    "8": "Piano inferiore",
    "9": "Piano superiore",
    "10": "Sala da pranzo",
    "11": "Sala hobby",
    "12": "Seminterrato",
    "13": "Soggiorno",
    "14": "Studio",
    "15": "Ufficio",
    "16": "Garage",
    "17": "Bagno"
  };

  groupModes = {
    10: "Only Cooling",
    11: "Only Heating",
    20: "Cooling (+ Heating)",
    21: "Heating (+ Cooling)",
    30: "Cooling",
    31: "Heating"
  };

  groupOff = {
    0: "ON",
    1: "OFF",
    2: "AWAY",
    3: "HOLIDAY"
  }

  days = [];
  day: any = 'week';

  constructor(
    private service: DeviceService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private message: CoreMessageService,
    private storage: CoreStorageService,
    // private state: AppState
  ) {
    // Questa subscription è in ascolto a un evento presente in application.ts, che controlla i breakpoints e notifica il layout dell'app
    // In caso sia di tipo 'mobile', engono aggiornate le colonne della lista
    this.subscription.add(
      this.message.get('layout.changed').subscribe((m: any) => {
        this.isMobile = m === 'mobile'
      })
    );
  }


 identity

  obj: Device;
  params: any = {};
  @Input() mode = ''; // 'inline' when used as child view
  //@Input() queryIn: any //
  @Input() paramsIn: any // params inherited by parent in inline view
  @Input() objIn: any = {} // obj inherited by parent in inline view
  query: any = {};
  new = false; // true for creation of new entity, false instead
  saveLoading = false;
  saved = false;
  data: {}; // data retrieved by core storage

  tabs = ["Dati Dispositivo"]; //list of tabs
  tab = 0; // tab index
  users = [];

  rooms = [];

  step = "statistics";

  downloadReady = false; //true hwen link on s is downloadReady
  downloading = false; //true while requesting download (waiting for s3
  downloadUrl = ""; //link on s3
  downloadInterval: any;

  hasHoldCharts: boolean = false;

  isMobile: boolean = false // flag per la gestione della vista mobile
  subscription: Subscription = new Subscription() // registrazione delle subscription da cancellare al destroy


  download() {
    this.downloadUrl = "";
    this.downloadReady = false;
    this.downloading = true;
    this.service.initDownload(this.obj['id']).then((d) => {
      this.downloadInterval = setInterval(() => {
        this.service.download(d['url']).then(() => {
          clearInterval(this.downloadInterval);
          console.log("AAA");
          this.downloadUrl = d['url'];
          this.downloadReady = true;
          this.downloading = false;
        }, () => {
        });
      }, 5000);
    });
  }

  goToPortal() {
    console.log(this.identity.user.organization);
    if( this.identity.user.organization.tag) {
      window.location.href = 'https://' + this.identity.user.organization.tag + "." + environment.APP_CONFIG.domain ;
    }
    else {
      window.location.href = 'https://'  + environment.APP_CONFIG.domain ;
    }

  }
  addsupport = false;
  addsupportadded = false;

  ngOnInit(): void {
    this.identity = this.storage.retrieve('identity') || {};

    for(let i = 0; i < 14; i++) {
      let d = new Date();
      d.setDate(d.getDate()-i);
      this.days.push(d)
    }

    // set params if inherited by parent (usually in inline view) then search
    if(this.paramsIn && Object.keys(this.paramsIn).length) {
      this.params = Object.assign({}, this.paramsIn);
      this.search();
    }
    // else subscribe to query and params then search
    else {
      this.route.queryParams.subscribe( queryParams => {
        this.query = queryParams;
      });
      this.route.params.subscribe( params => {
          this.params = Object.assign({}, params);
          this.search();
      });
    }

    // create a new entity with default data
    this.obj = new Device().applyModel(Object.assign({
      year: 2018,
      area_id: null,
      brand_id: null,
      degree_id: null,
      agency_id: null
      //new model
    }, this.objIn));

    // retrieve adta from core storage
    this.data = this.storage.retrieve("data");

    this.message.get("user.selected").subscribe(m => {
      if(m && m.id) {
        this.service.addsupport(this.obj.id, m.id).then(() => {
          this.addsupport = false;
          this.addsupportadded = true;
        }, () => {
          this.addsupport = false;
        });
      }
     })

    // Gli utenti di Homie non hanno accesso alla nuova telemetria
    if ((((this.identity || {}).user || {}).organization || {}).id == 11) {
      this.hasHoldCharts = true;
    }

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  // search entity
  search(): void {
    if(this.params['id'] && this.params['id'] != "0") {
      // retrieve ticket details
      this.service.get(this.params['id']).then(res => {
        this.obj = res.models[0];
        console.log("DEVICe", this.obj)

        //create an array of rooms for chart select box
        if(this.obj.data && this.obj.data.reported && this.obj.data.reported.groups) {
          for(let i = 0; i < this.obj.data.reported.groups.length; i++) {
            this.rooms = this.rooms.concat(this.obj.data.reported.groups[i].rooms)
          }
        }
        console.log("rooms", this.rooms);


        this.service.getUsers(this.params['id']).then((users) => {
          this.users = users;
          console.log("U", users);
        })
      })
    }
    else {
      this.new = true;
    }
  }



  showRoom(room) {
    if(room.show) {
      room.show = false;
    }
    else {
      this.day = 'week';
      setTimeout(() => {

        this.roomchart = room.SN;
        // this.chartRoom(room.SN, 'week');

        room.show = true;
      }, 250)
      // this.service.getTelemetry(room.SN, this.obj.id).then((data) => {
      //   room.labels = [];
      //   room.telemetry = {"h": {'data':[], label:'T', yAxisID: 'y-axis-2'}, "t": {'data':[], label:'T', yAxisID: 'y-axis-1'}};
      //   for(var k = 0; k < data.body.length; k++) {
      //     room.labels.push("");
      //     room.telemetry.t.data.push(data.body[k].temperature / 10);
      //     room.telemetry.h.data.push(data.body[k].humidity);
      //     // if(parseInt(data.body[k].humidity) > 2 && parseInt(data.body[k].humidity) < 100) {
      //     //   room.telemetry.h.data.push(data.body[k].humidity);
      //     // }
      //     // else {room.telemetry.h.data.push(null);}
      //   }
      //   room.show = true;
      // })
    }
  }



  // create or pdate an entioty
  save() : void {
    this.saveLoading = true;
    // patch (UPDATE)
    if(this.obj.id) {
      this.service.patch(this.obj.id, this.obj).then(res => {
        setTimeout(() => {
          this.saveLoading = false
          this.saved = true;
          // trig event in inline mode to notify parents
          if(this.mode == 'inline') {
            res.models[0].loadings['new'] = true;
            this.message.change('device.changed', res.models[0]);
          }
          else {
            setTimeout(() => {
                this.saved = false;
            }, 2000);
          }
        }, 1000);

      }, () => {
        this.saveLoading = false
      })
    }
    //post (CREATE)
    else {
      this.service.post(this.obj).then(res => {
        setTimeout(() => {
          this.tab = 0;
          this.saveLoading = false
          this.saved = true;
          setTimeout(() => {  if(this.mode != 'inline') {this.saved = false;} }, 2000);
          // broadcast event to parent if it's in inline mode
          if(this.mode == 'inline') {
            res.models[0].loadings['new'] = true;
            this.message.change('device.new', res.models[0]);
          }
          // else move to path if is not in inline mode
          else {
            this.router.navigate(['/device/edit/', res.models[0].id], {replaceUrl: true});
          }
        }, 1000);
      }, () => {
        this.saveLoading = false
      })
    }
  }



    public lineChartLabels:Array<any>
    public lineChartData:Array<any>
    public lineChartData2:Array<any>
    public lineChartOptions:any
    public lineChartOptions2:any
    public lineChartColors:Array<any>
    public lineChartColors2:Array<any>
    public lineChartLegend:boolean = false;
    public lineChartType:string = 'line';
    public chartTooltip = {};
    public chartRemoteDataSet = [];


    chartClick = function(event) {
      //console.log("EV", event)
      if(event.active && event.active[0] && event.active[0]._index >=0) {
        this.chartTooltip = {};
        setTimeout(() => {
          // var i = event.active[0]._index
          this.chartTooltip = this.chartRemoteDataSet[event.active[0]._index];
        }, 250);
      }

    }
    el:any;
    chart: any;
    chartLoading = false;
    roomchart = null;

    chartTelemetry(d) {
    }

    chartRoom(sensor, d) {
      let range = "";
      let group = "day"
      if(d == 'week' || !d) {
      }
      else {
        group = "hour";
        range = new Date(d).toISOString().split("T")[0];
      }

      if(!sensor || sensor == "null") {
        this.lineChartData = [];
        return;
      }
      this.chartLoading = true;
      this.service.getTelemetry(sensor, this.obj.id, group, range).then((res2) => {
        var res = res2.body;
        this.chartRemoteDataSet = res;
        var data = [];
        var data2 = [];
        var dates = [];
        for(var k = 0; k < res.length; k++) {
          data.push((res[k].temperature / 10).toFixed(1));
          if(parseInt(res[k].humidity) > 2 && parseInt(res[k].humidity) < 100) {
            data2.push((res[k].humidity * 1).toFixed(1));
          }
          else {
            data2.push(null);
          }
          dates.push(res[k].created_at);
        }


        this.lineChartData = [
          {data: data, label: this.translate.instant('chart.temp'), yAxisID: 'y-axis-1', fill:false, borderColor: "#fff", backgroundColor: "#000", pointHitRadius: 12, pointRadius: 8}/*,
          {data: data2, label: this.translate.instant('chart.hum'), yAxisID: 'y-axis-2', fill:false, borderColor: "#fff", backgroundColor: "#000", pointHitRadius: 12, pointRadius: 8}*/
        ];

        this.lineChartData2 = [
          {data: data2, label: this.translate.instant('chart.hum'), yAxisID: 'y-axis-2', fill:false, borderColor: "#fff", backgroundColor: "#000", pointHitRadius: 12, pointRadius: 8}
        ];
        // this.lineChartLabels =  new Array(12);
        // this.lineChartLabels =  Array.apply(null, new Array(data.length)).map(() => "");
        this.lineChartLabels = [];
        for(var i = 0; i < data.length; i ++) {
          if(d == 'week') {
            this.lineChartLabels.push(dates[i])

          }
          else {
            if(this.day == 'week') {

              this.lineChartLabels.push(dates[i].split(" ")[0].split("-")[2] + "/" + dates[i].split(" ")[0].split("-")[1])
            }
            else {

              this.lineChartLabels.push(dates[i].substr(-2) + ":00")
            }
            // this.lineChartLabels.push(data[i].label || i+1+":00")
          }
        }
        this.lineChartOptions = {
          legend: {
             labels: {
                fontColor: "black",
             },
             onClick: function() {}
          },
          tooltips: {
            enabled: false
          },
          plugins: {
            datalabels: {
              backgroundColor: function(context) {
                return "#ddd";
              },
              anchor:'end',
              align: 'top',
              borderRadius: 50,
              color: 'black',
              font: {
                weight: 'bold',
                size: 15
              }
            }
          },
          responsive: true,
          scales: {
            yAxes: [{
              display:true,
              type:'linear',
              position:'left',
    					id: 'y-axis-1',
              gridLines: {
                drawBorder: true,
                    display: true,
              },
              ticks: {
                maxTicksLimit: 6,
                beginAtZero:false,
                stepSize: 1,
                fontColor: "#000", // this here
                max: Math.max.apply(Math, data)+3,
                min: Math.min.apply(Math, data)-3,


              },
            }],
            xAxes: [{
              gridLines: {
                  fontColor: "#17A2B8", // this here
                  color: "#17A2B8", // this here
              },
              ticks: {
                fontColor: "#000", // this here
              }
          }]
        }

        };
        this.lineChartOptions2 = {
          legend: {
             labels: {
                fontColor: "black",
             },
             onClick: function() {}
          },
          tooltips: {
            enabled: false
          },
          plugins: {
            datalabels: {
              backgroundColor: function(context) {
                return "#ddd";
              },
              anchor:'end',
              align: 'top',
              borderRadius: 50,
              color: 'black',
              font: {
                weight: 'bold',
                size: 15
              }
            }
          },
          responsive: true,
          scales: {
            yAxes: [{
              display:true,
              type:'linear',
              position:'left',
    					id: 'y-axis-2',
              gridLines: {
                drawBorder: true,
                    display: true,
              },
              ticks: {
                maxTicksLimit: 6,
                beginAtZero:false,
                stepSize: 1,
                fontColor: "#000", // this here
                max: Math.max.apply(Math, data2)+5,
                min: Math.min.apply(Math, data2)-1
              },
            }],
            xAxes: [{
              gridLines: {
                  fontColor: "#17A2B8", // this here
                  color: "#17A2B8", // this here
              },
              ticks: {
                fontColor: "#000", // this here
              }
          }]
        }

        };
        this.lineChartColors2 = [
          { // min
            backgroundColor: '#5f9fb9',
            borderColor: '#0388a6',
            fontColor: '#5f9fb9',
            pointBackgroundColor: '#5f9fb9',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#5f9fb9'
          }
        ];
        this.lineChartColors = [
          {
            backgroundColor: '#d37d76',
            borderColor: '#b74239',
            fontColor: '#5f9fb9',
            pointBackgroundColor: '#d37d76',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#d37d76'
          }
        ];

        this.chartLoading = false;

        // this.chart =  new Chart(this.el.getContext('2d'), {
        //       type: 'line',
        //       data: this.lineChartData,
        //       options: this.lineChartOptions
        //   });



      }, () => {this.chartLoading = false;});
    }

    // TEST END


}
