import {Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgProgress } from '@ngx-progressbar/core';
import { UserService, User } from './';
import { CoreStorageService, CoreModalService, CoreMessageService, CoreUtilsService } from '../core'

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
})


export class UserListComponent implements OnInit {

  constructor(
    private service: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: CoreStorageService,
    private progress: NgProgress,
    private modal: CoreModalService,
    private message: CoreMessageService,
    private utils: CoreUtilsService,

  ) { }

  @Input() list : User[] = []; // list of entites (by remote service or by parent in inline mode)
  @Input() queryIn: any; // query params (for remote service in inline mode)
  @Input() objIn: any = {organization_id: null, role: null, orderBy:'signupDate', order:'DESC'}; // object passed to child edit component
  @Input() mode = ""; // 'inline' when used by parent
  @Input() modeSelect = false; // true when the inline mode is used to select element
  @Input() forceForm = false; // used to show the search form (usually in inline views)
  @ViewChild('blockEdit') blockEdit: ElementRef;

  pageInfo: any = {}; // pagination
  params: any = {};
  query: any = {organization_id: null, role: null, orderBy:'signupDate', order:'DESC'};
  page: 0;
  newInlineChild: boolean = false; // controls to show inline child edit view
  newInlineChildId: any = 0;

  loaded: boolean = false;
  loading: Promise<any>
  data: any = {}; // data from core storage


 identity
  organizations = [];

  ngOnInit(): void {
    this.identity = this.storage.retrieve('identity') || {};
    // query by parent component
    if(this.queryIn) {
      this.query = this.queryIn;
      this.search();
    }
    // watch queris in normal mode
    else if(this.mode != "inline"){
      this.route.queryParams.subscribe( queryParams => {
        this.query = Object.assign(this.query, queryParams);
        this.page = queryParams.page || 1;
        this.search();
      });
    }
    else {
      this.loaded = true;
    }

    //events to interact with child and parent
    this.message.get("user.new").subscribe(m => { this.newInlineChild = false; this.list.push(m); })
    this.message.get("user.changed").subscribe(m => { this.newInlineChild = false; this.list = this.utils.replace(this.list, m) })

    // retrieve data for storage
    this.data = this.storage.retrieve("data");


  }

  // uses service to get remote entitites
  search(): void {
    this.progress.start();
    let d = JSON.parse(JSON.stringify(this.query));
    console.log("query", d)
    if(d['organization_id'] === null || d['organization_id'] == 'null') {
      delete d['organization_id'];
    }
    if(d['role'] === null || d['role'] == 'null') {
      delete d['role'];
    }
    if(d['lastname'] === null || d['lastname'] == '') {
      delete d['lastname'];
    }
    if(d['username'] === null || d['username'] == '') {
      delete d['username'];
    }
    else {
      // d['username'] = d['username'].replace("+", "%2B")
    }
    console.log("query", d)
    this.loading = this.service.get(false, this.params, d, this.identity).then(res => {
      this.list = res.models;
      setTimeout(() => {
        this.pageInfo = res.pagination;
        this.loaded = true;
        this.progress.complete();
      }, 250)
    }, err => {this.loaded = true; this.progress.complete();});
  }

  // merge params and navigate to new path (or call "search" method if in inline mode)
  navigate(page): void {
    if(page) {
      this.params['page'] = page;
      this.query['page'] = page;
      var path = this.route.routeConfig.path;
      var params = this.service.objToQuery(this.params);
      for(var k in params) {
        path = path.replace(":" + k, params[k]);
      }
      // go to path o call seach in inline mode
      if(this.mode == 'inline') {
        this.search();
      }
      else {
        this.router.navigate([path], {queryParams: this.query, replaceUrl: true });
      }
    }
  }

  // go to new element route or opena a mask for inline adds
  add(open?: boolean) {
    if(this.mode == 'inline') {
      this.newInlineChildId = 0;
      this.newInlineChild = open
    }
    else {
      this.router.navigate(['user/edit/0']);
    }
  }

  // go to edit form for element or open a mask for inline editing
  edit(user: User):void {
    if(this.mode === 'inline') {
      this.newInlineChildId = null;
      this.newInlineChild = false;
      setTimeout(() => {
        this.newInlineChildId = user.id;
        this.newInlineChild = true;
      }, 100);

      setTimeout(() => {
        if (this.blockEdit && this.blockEdit.nativeElement) {
          this.blockEdit.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 250);

    } else {
      this.router.navigate(['user/edit/' + user.id]);
    }

  }

  // notify selection in inline mode
  select(user: User):void {
    this.message.change('user.selected', user);
  }

  // modal confirmation
  delete(user, index) {
    this.modal.confirm().then((confirmed) => {
      if(confirmed) {
        user.loadings['delete'] = true;
        setTimeout(() => {
          this.service.delete(user.id).then(() => {
            user.loadings['delete'] = false;
            this.list.splice(index, 1);
          }, function() {
            user.loadings['delete'] = false;
          });
        }, 500);
      }
    }, (dismiss) => { });
  }



}
