import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';


@Pipe({
 name: 'CoreEnumPipe'
})

@Injectable({
  providedIn: 'root'
})
export class CoreEnumPipe implements PipeTransform {
  transform(namespace: any): any {

    if(environment.APP_CONFIG.labels && environment.APP_CONFIG.labels[namespace]) {
      return environment.APP_CONFIG.labels[namespace];
    }
    else return [];

  }
}
