import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgProgress } from '@ngx-progressbar/core';
import { Newsletter, NewsletterService } from './';
import { CoreMessageService, CoreModalService, CoreStorageService, CoreUtilsService } from '../core'

@Component({
  selector: 'newsletter-list',
  templateUrl: './newsletter-list.component.html',
})

export class NewsletterListComponent implements OnInit {
  @Input() list: Newsletter[] = []; // list of entites (by remote service or by parent in inline mode)
  @Input() queryIn: any; // query params (for remote service in inline mode)
  @Input() objIn: any = {}; // object passed to child edit component
  @Input() mode = ""; // 'inline' when used by parent
  @Input() modeSelect = false; // true when the inline mode is used to select element
  @Input() forceForm = false; // used to show the search form (usually in inline views)
  pageInfo: any = {}; // pagination
  params: any = {};
  query: any = { not_sent: false };
  page: 0;
  newInlineChild: boolean = false; // controls to show inline child edit view
  newInlineChildId: string = '0';

  loaded: boolean = false;
  loading: Promise<any>
  data: any = {}; // data from core storage
  identity: any; // data from core storage

  constructor(
    private service: NewsletterService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: CoreStorageService,
    private progress: NgProgress,
    private modal: CoreModalService,
    private message: CoreMessageService,
    private utils: CoreUtilsService,
  ) {
    this.identity = this.storage.retrieve('identity') || {};
  }

  ngOnInit(): void {
    // query by parent component
    if (this.queryIn) {
      this.query = this.queryIn;
      this.search();
    }
    // watch queris in normal mode
    else if (this.mode != "inline") {
      this.route.queryParams.subscribe(queryParams => {
        this.query = Object.assign(this.query, queryParams);
        this.page = queryParams.page || 1;
        this.search();
      });
    } else {
      this.loaded = true;
    }

    //events to interact with child and parent
    this.message.get("newsletter.new").subscribe(m => {
      this.newInlineChild = false;
      this.list.push(m);
    })
    this.message.get("newsletter.changed").subscribe(m => {
      this.newInlineChild = false;
      this.list = this.utils.replace(this.list, m)
    })

    // retrieve data for storage
    this.data = this.storage.retrieve("data");

  }

  // uses service to get remote entitites
  search(): void {
    this.progress.start();
    this.loading = this.service.get('', this.params, this.query).then(res => {
      this.list = res.models;
      setTimeout(() => {
        this.pageInfo = res.pagination;
        this.loaded = true;
        this.progress.complete();
      }, 250)
    }, err => {
      this.loaded = true;
      this.progress.complete();
    });
  }

  // merge params and navigate to new path (or call "search" method if in inline mode)
  navigate(page): void {
    if (page) {
      this.progress.start();
      this.params['page'] = page;
      this.query['page'] = page;
      var path = this.route.routeConfig.path;
      var params = this.service.objToQuery(this.params);
      for (var k in params) {
        path = path.replace(":" + k, params[k]);
      }
      // go to path o call search in inline mode
      if (this.mode == 'inline') {
        this.search();
      } else {
        this.router.navigate([path], { queryParams: this.query, replaceUrl: true });
      }
    }
  }

  // go to new element route or opena a mask for inline adds
  add(open?: boolean) {
    if (this.mode == 'inline') {
      this.newInlineChildId = '0';
      this.newInlineChild = open
    } else {
      this.router.navigate(['newsletter/edit/0']);
    }
  }

  // go to edit form for element or open a mask for inline editing
  edit(newsletter: Newsletter): void {
    if (this.mode == 'inline') {
      this.newInlineChildId = newsletter.id;
      this.newInlineChild = true;
    } else {
      this.router.navigate(['newsletter/edit/' + newsletter.id]);
    }
  }

  // notify selection in inline mode
  select(newsletter: Newsletter): void {
    this.message.change('newsletter.selected', newsletter);
  }

  // modal confirmation
  delete(newsletter, index) {
    this.modal.confirm().then((confirmed) => {
      if (confirmed) {
        newsletter.loadings['delete'] = true;
        setTimeout(() => {
          this.service.delete(newsletter.id).then(() => {
            newsletter.loadings['delete'] = false;
            this.list.splice(index, 1);
          }, function () {
            newsletter.loadings['delete'] = false;
          });
        }, 500);
      }
    }, (dismiss) => { });
  }

  getBadge(data: string, row: Newsletter): string {
    const tested = !!row.tested;
    const sent = !!row.sent_at;

    if (tested && !sent) {
      // Testato
      return data === 'color' ? '' : 'newsletter.tested';
    } else if (tested && sent) {
      // Testato e inviato
      return data === 'color' ? 'green' : 'newsletter.sent';
    } else {
      // Non testato
      return data === 'color' ? 'grey' : 'newsletter.notTested';
    }
  }

}
