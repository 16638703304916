import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreHttpModelParser, CoreUtilsService } from './../core';
import 'rxjs/add/operator/map';
import { Ticketmaster, TICKETMASTER_FILTERS } from './';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketmasterService extends CoreHttpModelParser {

  constructor(
    public http: HttpClient,
    public utils: CoreUtilsService
  ) { super(); }

  entityName = 'admin/ticket';

  public get(id: any, params?: {}, query?: {}): any {
    params = {};
    let p = new Promise((resolve, reject) => {
      if(!id) {
        id = "";
      }
      else {
        id = "/" + id;
      }
      if(params && params['id']) {
        delete params['id'];
      }
      this.http.get(environment.APP_CONFIG.endpoint + this.entityName + id, { params: Object.assign(this.objToQuery(params), this.objToQuery(query)), observe: 'response' }).toPromise().then(res => {
        resolve(this.parse(res, Ticketmaster));
      }, err => reject(err));
    });
    return p;
  }



  public post(data): any {
    let p = new Promise((resolve, reject) => {
        this.http.post(environment.APP_CONFIG.endpoint + this.entityName,  this.utils.pick(TICKETMASTER_FILTERS['post'], data), {observe: 'response'}).toPromise().then(res => {
          console.log(JSON.stringify(res));
          resolve(this.parse(res, Ticketmaster));
        }, err => reject(err));
    });
    return p;
  }

  public patch(id, data: any): Promise<any> {
    let p = new Promise((resolve, reject) => {
        this.http.patch(environment.APP_CONFIG.endpoint + this.entityName + '/' + id, this.utils.pick(TICKETMASTER_FILTERS['patch'], data), {observe: 'response'}).toPromise().then(res => {
          resolve(this.parse(res, Ticketmaster));
        }, err => reject(err));
    });
    return p;
    // return this.http.patch(environment.APP_CONFIG.endpoint + this.entityName + '/' + id, this.utils.pick(TICKETMASTER_FILTERS['patch'], data), {observe: 'response'}).toPromise();
  }


  public postNote(id, data): any {
      return  this.http.post(environment.APP_CONFIG.endpoint + this.entityName +'/' + id + '/note',  data, {observe: 'response'}).toPromise()
  }

  public getNotes(id): any{
    return this.http.get(environment.APP_CONFIG.endpoint + this.entityName +'/' + id + '/note', {observe: 'response'}).toPromise()
  }


  public delete(id): Promise<any> {
    return this.http.delete(environment.APP_CONFIG.endpoint + this.entityName + '/' + id, {observe: 'response'}).toPromise();
  }



  // public get(): Promise<any> {
  //    return this.http.get('https://academy.universe.corley.it/v1/course', { observe: 'response' }).toPromise();
  // }

}
