import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { CoreStorageService } from '../';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class CoreHttpInterceptorAuth implements HttpInterceptor {
    // token = new AppState().get("token");
  constructor(public storage: CoreStorageService) {}
  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var headers = {};
    const forceAuthentication = req.params && req.params['customParams'] && req.params['customParams']['forceAuthentication']
    // console.log("REQ", req.url.substr(-11));
    var identity =  this.storage.retrieve("identity", true);
    //if(req.url.split("/")[2] && req.url.split("/")[2].substr(-13) == 'amazonaws.com') {
    if(!forceAuthentication && (req.url.search("amazonaws.com") != -1 || req.url.search("googleapis.com") != -1) ) {
      const authReq = req.clone(/*{
        headers: req.headers.set('Content-Type', 'multipart/form-data ')
      }*/);
      return next.handle(authReq);
    }
    else if(identity && identity.access_token ) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + identity.access_token)
      });
      return next.handle(authReq).catch((error, caught) => {
        //intercept the respons error and displace it to the console
        // console.log("Error Occurred");
        // console.log(error);
        //return the error to the method that called it
        return Observable.throw(error);
        }) as any;;
    }
    else {
      return next.handle(req).catch((error, caught) => {
        //intercept the respons error and displace it to the console
        // console.log("Error Occurred");
        // console.log(error);
        //return the error to the method that called it
        return Observable.throw(error);
        }) as any;;
    }
  }
}
