<div class="area-{{ routeData.area }}">

  <app-header *ngIf="routeData.area != 'login'"></app-header>
  <ng-progress></ng-progress>

  <div class="container-fluid">
    <div class="row">

      <app-left *ngIf="routeData.area != 'login'" class="col-sm-3 col-md-2 d-sm-block sidebar"
                [ngClass]="{'d-none': !menuOpened }" [section]="section"></app-left>

      <main role="main" class="col-sm-9 ml-sm-auto col-md-10 pt-3 main">
        <router-outlet></router-outlet>
      </main>

    </div>
  </div>
</div>
