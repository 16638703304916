import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, Event } from '@angular/router';
// import { Observable }        from 'rxjs/Observable';

import { OrganizationService, Organization } from './';

import { CoreStorageService, CoreStorage, CoreModalService, CoreMessageService } from '../core'




@Component({
  selector: 'organization-edit',
  templateUrl: './organization-edit.component.html'
})


export class OrganizationEditComponent implements OnInit {

  constructor(
    private service: OrganizationService,
    private router: Router,
    private route: ActivatedRoute,
    private message: CoreMessageService,
    private storage: CoreStorageService,
    // private state: AppState
  ) { }

  obj: Organization;
  params: any = {};
  @Input() mode = ''; // 'inline' when used as child view
  //@Input() queryIn: any //
  @Input() paramsIn: any // params inherited by parent in inline view
  @Input() objIn: any = {} // obj inherited by parent in inline view
  query: any = {};
  new = false; // true for creation of new entity, false instead
  saveLoading = false;
  saved = false;
  data: {}; // data retrieved by core storage

  tabs = ["Dati Organization"]; //list of tabs
  tab = 0; // tab index

  ngOnInit(): void {
    // set params if inherited by parent (usually in inline view) then search
    if(this.paramsIn && Object.keys(this.paramsIn).length) {
      this.params = Object.assign({}, this.paramsIn);
      this.search();
    }
    // else subscribe to query and params then search
    else {
      this.route.queryParams.subscribe( queryParams => {
        this.query = queryParams;
      });
      this.route.params.subscribe( params => {
          this.params = Object.assign({}, params);
          this.search();
      });
    }

    // create a new entity with default data
    this.obj = new Organization().applyModel(Object.assign({
      year: 2018,
      area_id: null,
      brand_id: null,
      degree_id: null,
      agency_id: null
      //new model
    }, this.objIn));

    // retrieve adta from core storage
    this.data = this.storage.retrieve("data");


  }

  // search entity
  search(): void {
    if(this.params['id'] && this.params['id'] != "0") {
      // retrieve ticket details
      this.service.get(this.params['id']).then(res => {
        this.obj = res.models[0];
      })
    }
    else {
      this.new = true;
    }
  }


  // create or pdate an entioty
  save() : void {
    this.saveLoading = true;
    // patch (UPDATE)
    if(this.obj.id) {
      this.service.patch(this.obj.id, this.obj).then(res => {
        setTimeout(() => {
          this.saveLoading = false
          this.saved = true;
          // trig event in inline mode to notify parents
          if(this.mode == 'inline') {
            res.models[0].loadings['new'] = true;
            this.message.change('organization.changed', res.models[0]);
          }
          else {
            setTimeout(() => {
                this.saved = false;
            }, 2000);
          }
        }, 1000);

      }, () => {
        this.saveLoading = false
      })
    }
    //post (CREATE)
    else {
      this.service.post(this.obj).then(res => {
        setTimeout(() => {
          this.tab = 0;
          this.saveLoading = false
          this.saved = true;
          setTimeout(() => {  if(this.mode != 'inline') {this.saved = false;} }, 2000);
          // broadcast event to parent if it's in inline mode
          if(this.mode == 'inline') {
            res.models[0].loadings['new'] = true;
            this.message.change('organization.new', res.models[0]);
          }
          // else move to path if is not in inline mode
          else {
            this.router.navigate(['/organization/edit/', res.models[0].id], {replaceUrl: true});
          }
        }, 1000);
      }, () => {
        this.saveLoading = false
      })
    }
  }

}
