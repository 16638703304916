import { CoreHttpModelBase } from './../core';

export class Device extends CoreHttpModelBase {
    id: string
    data: any
    loadings: any = {}
}

export const DEVICE_FILTERS = {
  post: [ "name", "type", "year", "brand_id", "degree_id", "area_id", "agency_id", "note"],
  patch: [ "name", "type", "year", "brand_id", "degree_id", "area_id", "agency_id", "note"]
}
