
<div class="block block-m-t">

  <h1 [hidden]="mode == 'inline'">
    <!-- <span [hidden]="query._title">Elenco comunicazioni</span>
    <span [hidden]="!query._title">Elenco comunicazioni</span> -->
    {{ query._title || 'user.list' | translate }}
    <span [hidden]="! page || page == 1"> - {{ 'COMMON.page' | translate }} {{ page }}</span>
    <span class="pull-right">
      <button class="btn btn-info" (click)="add()" >{{ 'COMMON.create' | translate }}</button>
    </span>
  </h1>



  <form #searchForm="ngForm" class="searchForm" (ngSubmit)="navigate(1)"  [hidden]="mode == 'inline' && !modeSelect && !forceForm" >
    <div class="row">
      <div class="col-lg-11">
        <div class="form-group" >
              <!-- <div class="btn-group" >
                <label for="id" class="btn btn-default" >Nome</label>
                <input type="text" class="form-control" id="firstname" style="height:34px" [(ngModel)]="query.firstname" name="firstname">
              </div> -->
              <div class="btn-group">
                <label for="lastname" class="btn btn-default btn-auto">{{ 'user.surname' | translate }} </label>
                <input class="form-control" id="lastname"    [(ngModel)]="query.lastname" name="lastname" />
              </div>
              <div class="btn-group">
                <label for="lastname" class="btn btn-default btn-auto">Email </label>
                <input class="form-control" id="username"    [(ngModel)]="query.username" name="username" />
              </div>
              <div class="btn-group" *ngIf="identity.role == 'ROLE_ADMIN'">
                <label for="organization" class="btn btn-default btn-auto">{{ 'organization.title' | translate }} </label>
                <select class="form-control width-auto" id="organization"    [(ngModel)]="query.organization_id" name="organization_id" >
                  <option value="null">
                    {{ 'organization.select' | translate }}
                  </option>
                  <option *ngFor="let o of data['organization']" value="{{ o.id }}">
                    {{ o.name }}
                  </option>
                </select>
              </div>
              <div class="btn-group">
                <label for="role" class="btn btn-default">{{ 'user.role' | translate }}  </label>
                <select class="form-control" id="role"
                   required
                   [(ngModel)]="query.role" name="role" >
                   <option [ngValue]="null">
                      {{ 'COMMON.all' | translate }}
                   </option>
                   <option *ngFor="let c of 'roles' | CoreEnumPipe" [value]="c.id"  [hidden]="(identity.role == 'ROLE_OEM' && (c.id == 'ROLE_ADMIN' || c.id == 'ROLE_OEM' || c.id == 'ROLE_ADMIN_SUPPORT') && c.id) || ((identity.role == 'ROLE_USER' || identity.role == 'ROLE_PARENT') && c.id != 'ROLE_PARENT' && c.id != 'ROLE_USER') ">
                     {{ c.label  | translate }}
                   </option>
                </select>
              </div>

              <div class="btn-group">
                <label for="orderBy" class="btn btn-default" style="min-width: 100px !important">{{ 'COMMON.orderBy' | translate }} </label>
                <select class="form-control" id="orderBy"    [(ngModel)]="query.orderBy" name="orderBy" >
                  <!-- <option value="null">
                    {{ 'COMMON.orderNull' | translate }}
                  </option> -->
                  <option value="signupDate">
                      {{ 'COMMON.create2' | translate }}
                  </option>
                  <option value="lastname">
                      {{ 'user.surname' | translate }}
                  </option>
                  <option value="firstname">
                      {{ 'user.name' | translate }}
                  </option>

                </select>
                <select class="form-control" id="order"    [(ngModel)]="query.order" name="order" style="margin-left:10px" >
                  <!-- <option value="null">
                    {{ 'COMMON.orderNull' | translate }}
                  </option> -->

                  <option value="DESC">
                      {{ 'COMMON.orderDesc' | translate }}
                  </option>
                  <option value="ASC">
                      {{ 'COMMON.orderAsc' | translate }}
                  </option>

                </select>
              </div>

        </div>
      </div>
      <div class="col-lg-1 text-right">
        <div class="form-group">
          <button class="btn btn-outline-info" type="submit"><i class="fa fa-search"></i></button>
        </div>
      </div>
    </div>
  </form>


  <!-- <p class="text-center mt-5" *ngIf="!loaded">
    <i class="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i>
  </p> -->
  <div class="overflow-x-scroll" [coreLoader]="loading">

    <table class="table table-bordered">
      <thead class="">
        <tr class="bg-dark lead" [hidden]="mode == 'inline'">
          <th class="text-center " colspan="10">
              {{ 'user.title' | translate }}
          </th>
        </tr>

        <tr>
          <th class="text-center">
              {{ 'user.surname' | translate }}
          </th>
          <th class="text-center">
              {{ 'user.name' | translate }}
          </th>
          <!-- <th class="text-center">
            Status
          </th> -->
          <th class="text-center" *ngIf="identity.role == 'ROLE_ADMIN'">
            OEM
          </th>
          <th class="text-center">
              {{ 'user.role' | translate }}
          </th>
          <th class="text-center">
              {{ 'user.nation' | translate }}
          </th>
          <th class="text-center">
            eMail
          </th>
          <th class="text-center">
          </th>
        </tr>
      </thead>
      <tbody *ngIf="loaded">
        <tr *ngFor="let obj of list; let i = index" class="animated fadeIn" [ngClass]="{'refused': obj.status == 'REFUSED', 'highlight': obj.loadings && obj.loadings.new}">
          <td class="text-center">
            {{ obj.lastname }}
          </td>
          <td class="text-center">
            {{ obj.firstname }}
          </td>
          <td class="text-center" *ngIf="identity.role == 'ROLE_ADMIN'">
            <span *ngIf="obj.organization">{{ obj.organization.name }}</span>
          </td>
          <td class="text-center">
            {{ 'roles' | CoreLabelPipe : obj.role | translate}}
          </td>
          <td class="text-center">
            {{ obj.country }}
          </td>
          <td  class="text-center">
            {{ obj.username }}
          </td>
          <td class="text-center pb-0" *ngIf="obj && obj.loadings && !modeSelect">
            <button type="button" class="btn btn-sm btn-outline-info"  (click)="edit(obj, i)">{{ 'COMMON.edit' | translate }}</button>
            <!--<button type="button" class="btn btn-sm btn-outline-danger"  (click)="delete(obj, i)" [ladda]="obj.loadings['delete']" data-spinner-color="#000" data-style="zoom-in"><i class="fa fa-remove"></i></button>-->
          </td>
          <td class="text-center pb-0" *ngIf="modeSelect">
            <button type="button" class="btn btn-sm btn-outline-info"  (click)="select(obj, i)">{{ 'COMMON.select' | translate }}</button>
          </td>

          <!-- <td>
            <button type="button" class="btn btn-sm btn-outline-info" routerLink="/user/edit/{{ obj.id }}">Gestisci</button>
          </td> -->
        </tr>
      </tbody>
    </table>
    <ng-container *ngIf="mode == 'inline'" >
     <p class="mb-0">
        <!-- <button type="button" class="btn btn-sm btn-info btn-block-inline" (click)="add(true)" [ngClass]="{'btn-block-inline-active': newInlineChild}"><span [hidden]="newInlineChildId">Crea</span><span [hidden]="!newInlineChildId">Modifica</span> <i [hidden]="!newInlineChild" class="fa fa-arrow-down animated fadeIn"></i></button> -->
        <button type="button" class="btn btn-sm animated fadeIn btn-block-inline btn-block-inline-active" (click)="add(false)" [hidden]="!newInlineChild" ><i class="fa fa-close"></i></button>
      </p>
      <div #blockEdit class="block-inline" *ngIf="newInlineChild">
        <user-edit  [mode]="mode" [paramsIn]="{id: newInlineChildId}" [objIn]="objIn"></user-edit>
      </div>
    </ng-container>

    <p [hidden]="loaded && list.length || !loaded" class="text-center">
        {{ 'COMMON.nothing' | translate }}
    </p>

    <hr style="margin:50px 0"/>




  </div>
</div>
<core-pagination-nav [pageInfo]="pageInfo" [(page)]="page" (changed)="navigate($event)"  ></core-pagination-nav>
