
<div class="block block-m-t">

  <div  [hidden]="mode == 'inline'">
      <h4>{{ 'common-edit.title' | translate }}</h4>
      <p>
        * {{ 'common-edit.required' | translate }}
      </p>
  </div>


  <form #objForm="ngForm" class="form-standard" [hidden]="!(new || obj.id)" (ngSubmit)="save()">
    <div [ngClass]="{'animated fadeOut': saveLoading}">

      <p class="tabs">
        <button type="button" *ngFor="let t of tabs; let i = index" class="btn btn-lg" [ngClass]="{'btn-info': tab == i, 'btn-link': i != tab}" (click)="tab = i">{{ 'tabs.' + tabs[i] | translate }}</button>
      </p>

        <div [hidden]="tab != 0" class="row">


            <!-- <div class="col-lg-6">
              <div class="form-group">
                <div class="form-group">
                  <label for="name">Username *</label>
                  <input
                    type="text" class="form-control" id="username"
                    required
                    [(ngModel)]="obj.username" name="username" >
                </div>
              </div>
            </div> -->

            <div class="col-lg-12" *ngIf="obj.id  && (identity.role == 'ROLE_ADMIN' || (identity.role == 'ROLE_OEM' && obj.role !='ROLE_OEM'))">
              <div class="form-group">
                <label for="channel">{{ 'user.role' | translate}} *</label>
                <div>
                  <div class="btn-group" ngbRadioGroup name="channel" [(ngModel)]="obj.role">
                    <label ngbButtonLabel class="btn-info" *ngFor="let s of 'roles' | CoreEnumPipe" [hidden]="identity.role == 'ROLE_OEM' && s.id != 'ROLE_SUPPORT' && s.id != 'ROLE_MANAGEMENT' && s.id ">
                      <input ngbButton type="radio" [value]="s.id"> {{s.label | translate }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="form-group">
                <div class="form-group" *ngIf="obj.id">
                  <label for="username">Email *</label>
                  <input
                    type="email" class="form-control" id="username"
                    required
                    disabled="disabled"
                    [(ngModel)]="obj.username" name="username" >
                </div>
                <div class="form-group" *ngIf="!obj.id">
                  <label for="username">Email *</label>
                  <input
                    type="email" class="form-control" id="username"
                    required
                    [(ngModel)]="obj.username" name="username" >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <div class="form-group">
                  <label for="firstname">{{ 'COMMON.name' | translate }} *</label>
                  <input
                    type="text" class="form-control" id="firstname"
                    required
                    [(ngModel)]="obj.firstname" name="firstname" >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <div class="form-group">
                  <label for="lastname">{{ 'user.surname' | translate }} *</label>
                  <input
                    type="text" class="form-control" id="lastname"
                    required
                    [(ngModel)]="obj.lastname" name="lastname" >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <div class="form-group">
                  <label for="email_2">Email {{ 'COMMON.secondary' | translate }}</label>
                  <input
                    type="text" class="form-control" id="secondaryEmail"
                    [(ngModel)]="obj.secondaryEmail" name="secondaryEmail" >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <div class="form-group">
                  <label for="phone_1">{{ 'user.phone' | translate }}</label>
                  <input
                    type="text" class="form-control" id="phoneNumber"
                    [(ngModel)]="obj.phoneNumber" name="phoneNumber" >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <div class="form-group">
                  <label for="phone_2">{{ 'user.cellular' | translate }}</label>
                  <input
                    type="text" class="form-control" id="mobileNumber"
                    [(ngModel)]="obj.mobileNumber" name="mobileNumber" >
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <div class="form-group">
                  <label for="country">{{ 'user.nation' | translate }}</label>
                  <select
                    class="form-control" id="country"
                    required
                    [(ngModel)]="obj.country" name="country" >
                    <option *ngFor="let c of countries" value="{{ c }}">
                      {{ c }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <div class="form-group">
                  <label for="lang">{{ 'content.lingua' | translate }} app</label>
                  <select
                    class="form-control" id="language"
                    required
                    [(ngModel)]="obj.language" name="language" >
                    <option value="it">it</option>
                    <option value="en">en</option>
                    <option value="de">de</option>
                    <option value="es">es</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-4" *ngIf="identity.role == 'ROLE_ADMIN' && !obj.id && obj.role != 'ROLE_ADMIN'">
              <div class="form-group">
                <div class="form-group">
                  <label for="lang">{{ 'organization.title' | translate }} *</label>
                  <select
                    class="form-control" id="organization"
                    required
                    [(ngModel)]="obj.organization" name="organization" >
                    <option *ngFor="let o of data['organization']" value="{{ o.id }}">
                      {{ o.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-4" *ngIf="identity.role == 'ROLE_ADMIN' && obj.id &&obj.role != 'ROLE_ADMIN'">
              <div class="form-group">
                <div class="form-group">
                  <label for="lang">{{ 'organization.title' | translate }} *</label>
                  <select
                    class="form-control" id="organization"
                    name="organization" disabled="disabled">
                    <option >
                      {{ obj.organization.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-4" *ngIf="!obj.id">
              <div class="form-group">
                <div class="form-group">
                  <label for="password">Password *</label>
                  <input
                    type="password" class="form-control" id="password"
                    required
                    [(ngModel)]="obj.password" name="password" >
                </div>
              </div>
            </div>
          <div class="col-lg-12" *ngIf="obj.id">
            <div class="form-check">
              <input
                type="checkbox" class="form-check-input"
                id="privacy1"
                [checked]="obj.terms != null"
                disabled="disabled"
                name="privacy1" >
              <label for="privacy1">{{ 'user.privacy1' | translate }}</label>
            </div>
            <div class="form-check">
              <input
                type="checkbox" class="form-check-input"
                id="privacy2"
                [checked]="obj.oem != null"
                disabled="disabled"
                name="privacy2">
              <label for="privacy2">{{ 'user.privacy2' | translate }}</label>
            </div>
            <div class="form-check">
              <input
                type="checkbox" class="form-check-input"
                id="privacy3"
                [checked]="obj.marketing != null"
                disabled="disabled"
                name="privacy3">
              <label for="privacy3">{{ 'user.privacy3' | translate }}</label>
            </div>
          </div>


        </div>

      <div [hidden]="tab != 1" class="row">

        <div class="col-lg-12" *ngIf="obj.id">
          <div class="form-group">
            <label> {{ "user.telemetryUpdate" | translate }}</label>
            <div>
              <div class="btn-group" ngbRadioGroup name="status" [(ngModel)]="obj.telemetryMailReception">
                <label ngbButtonLabel class="btn-info" *ngFor="let t of 'telemetryUpdateFrequency' | CoreEnumPipe">
                  <input ngbButton type="radio" [value]="t.id"> {{t.label | translate }}
                </label>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="form-group mt-3">
      <button type="submit" class="btn btn-success btn-lg" [disabled]="!objForm.valid " [ladda]="saveLoading">{{ 'COMMON.save' | translate }}</button>
      <span class="btn" [hidden]="!saved"><strong>{{ 'COMMON.saved' | translate }}</strong></span>
    </div>

  </form>

</div>
