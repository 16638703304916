import { Component, Input, ElementRef, OnInit } from '@angular/core';
import { CoreChartService } from '../';

@Component({
  selector: 'core-chart-gauge',
  templateUrl: './chart-gauge.component.html'
})
export class CoreChartGaugeComponent implements OnInit {

  constructor(
    private service: CoreChartService,
    private el:ElementRef
  ) {
    this.width = this.el.nativeElement.offsetWidth;

  }

  ngOnInit() {
    this.hex = this.service.getColor(this.color);

    if(this.schema) {
      this.hex = this.service.getColorByValue(this.schema, this.value);
    }

    // this.color = this.service.getColor(this.type);
    // this.color = this.service.getColor(this.type);
  }


  width: number = 150;

  @Input() title: string;
  @Input() type;
  @Input() color;
  @Input() value;
  @Input() label;
  @Input() append;
  @Input() max;
  @Input() thick = 5;
  @Input() min;
  @Input() schema;
  hex : string = "";
}
