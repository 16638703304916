import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreHttpModelParser, CoreUtilsService, CoreStorageService } from './../core';
import { Observable } from 'rxjs';

import 'rxjs/add/operator/map';

import { User, USER_FILTERS } from './';
import { environment } from 'environments/environment';



@Injectable({
  providedIn: 'root'
})
export class UserService extends CoreHttpModelParser {

  constructor(
    public http: HttpClient,
    public utils: CoreUtilsService,
    public storage: CoreStorageService
  ) { super(); }

  entityName = 'user';

  public get(id: any, params?: {}, query?: {}, identity?: any): any {
    params = {};
    let p = new Promise((resolve, reject) => {
      if(!id) {
        id = "";
      }
      else {
        id = "/" + id;
      }
      if(params && params['id']) {
        delete params['id'];
      }
      // query ={}
      // if(identity && identity.user) {
      //   if(identity.user.role != 'ROLE_ADMIN' && identity.user.organization && identity.user.organization.id ) {
      //     query['organization_id'] = identity.user.organization.id
      //   }
      // }
      this.http.get(environment.APP_CONFIG.endpointIdentity + this.entityName + id, { params: Object.assign(this.objToQuery(params), this.useFields(query)), observe: 'response' }).toPromise().then(res => {
        resolve(this.parse(res, User));
      }, err => reject(err));
    });
    return p;
  }
  public getOrganization(id: any, params?: {}, query?: {}): any {
    params = {};
      if(!id) {
        id = "";
      }
      else {
        id = "/" + id;
      }
      if(params && params['id']) {
        delete params['id'];
      }
      return this.http.get(environment.APP_CONFIG.endpointIdentity + "organization" + id, { params: Object.assign(this.objToQuery(params), this.objToQuery(query))}).toPromise()
  }

  public getMe(): any {
      return this.http.get(environment.APP_CONFIG.endpointIdentity + "me").toPromise()
  }




  public post(data): any {
    let p = new Promise((resolve, reject) => {
        this.http.post(environment.APP_CONFIG.endpointIdentity + this.entityName,  this.utils.pick(USER_FILTERS['post'], data), {observe: 'response'}).toPromise().then(res => {
          console.log(JSON.stringify(res));
          resolve(this.parse(res, User));
        }, err => reject(err));
    });
    return p;
  }

  public patch(id, data: any): Promise<any> {
    let p = new Promise((resolve, reject) => {
        this.http.patch(environment.APP_CONFIG.endpointIdentity + this.entityName + '/' + id, this.utils.pick(USER_FILTERS['patch'], data), {observe: 'response'}).toPromise().then(res => {
          resolve(this.parse(res, User));
        }, err => reject(err));
    });
    return p;
    // return this.http.patch(environment.APP_CONFIG.endpointIdentity + this.entityName + '/' + id, this.utils.pick(USER_FILTERS['patch'], data), {observe: 'response'}).toPromise();
  }

  public delete(id): Promise<any> {
    return this.http.delete(environment.APP_CONFIG.endpointIdentity + this.entityName + '/' + id, {observe: 'response'}).toPromise();
  }


  public getDashboard(): any {
    let p = new Promise((resolve, reject) => {
      this.http.get(environment.APP_CONFIG.endpointIdentity + 'dashboard', { observe: 'response' }).toPromise().then(res => {
        resolve(res);
      }, err => reject(err));
    });
    return p;
  }


  public login(username, password): any {
    console.log("service");
    let p = new Promise((resolve, reject) => {
        this.http.post(environment.APP_CONFIG.endpointLogin + 'login',  {username: username.toLowerCase(), password: password/*, organization_id: environment.APP_CONFIG.oem_id*/}, {observe: 'response'}).toPromise().then(res => {
          console.log("AA", res.body);
          this.storage.store("identity", {user: res.body['user'], access_token: res.body['access_token'], role: res.body['user'].role});
          console.log(res);
          resolve(res);
        }, err => reject(err));
    });
    return p;
  }

  public logout(): void {
    this.storage.clear();
    this.storage.store("identity", { role: 'ROLE_GUEST'});
  }


  public loadData(role) {
    if(role != "ROLE_ADMIN") {
      this.storage.store("data", {});
      return Promise.all([]);

    }
    var data = {};
    return Promise.all([
      this.http.get(environment.APP_CONFIG.endpointIdentity + 'organization', { observe: 'response', params: { limit: '250' }}).toPromise().then(res => {data['organization'] = res.body}),
    ]).then((d) => {
      this.storage.store("data", data);
      return data;
    })
  }

  public setUsers(users: any) {
    function compare(a,b) {
      if (a.name < b.name)
        return -1;
      if (a.name > b.name)
        return 1;
      return 0;
    }

    users.sort(compare);


    this.storage.store("users", users);
  }
  public getUsers() {
    return this.storage.retrieve("users") || [];
  }


}
