<div class="row">
    <div class="col-md-6 col-lg-4 offset-md-3 offset-lg-4 form-login" >

      <form class="form-horizontal" #loginForm="ngForm" >
                  <p class="text-center">
                    <img src="/assets/img/365.png" class="img-fluid" alt=""  />
                  </p>
                    <!-- <h2>Accedi</h2> -->
                    <hr>

                    <div class="form-group has-danger">
                        <label class="sr-only" for="email">Username</label>
                        <div class="input-group mb-2 mr-sm-2 mb-sm-0">
                            <div class="input-group-prepend" style="width: 2.7rem"><label class="input-group-text" style="width:100%"><i class="fa fa-user"></i></label></div>
                            <input type="email" name="email" class="form-control" id="email"
                                   placeholder="Username" [(ngModel)]="username" required autofocus>
                        </div>
                    </div>
                    <div class="form-control-feedback">
                        <span class="text-danger align-middle">
                            <!-- <i class="fa fa-close"></i> Example error message -->
                        </span>
                    </div>
                    <div class="form-group">
                        <label class="sr-only" for="password">Password</label>
                        <div class="input-group mb-2 mr-sm-2 mb-sm-0">
                          <div class="input-group-prepend" style="width: 2.7rem"><label class="input-group-text" style="width:100%"><i class="fa fa-key"></i></label></div>
                            <input type="password" name="password" [(ngModel)]="password" class="form-control" id="password"
                                   placeholder="Password" required>
                        </div>
                    </div>
                    <div class="form-control-feedback">
                        <span class="text-danger align-middle">
                        <!-- Put password error message here -->
                        </span>
                    </div>

                    <button type="submit" [ladda]="loginLoading"  [disabled]="!loginForm.valid" (click)="login()" class="btn btn-success btn-lg"> LOGIN</button>

                    <!-- <a class="btn btn-link" href="#">Password dimenticata?</a> -->
                    <p [hidden]="!error" class="mt-3 text-danger">
                            {{ 'user.error_auth' | translate }}
                    </p>
        </form>
      </div>
    </div>
