import { CoreHttpModelBase } from '../core';

export class Notification extends CoreHttpModelBase {
    user_id: string;
    ios_push: string[];
    android_push: string[];
    all_push: string[];
    alarm_push: boolean;
    alarm_email: boolean;
    message_push: boolean;
    message_email: boolean;
    loadings: any = {};
}

export const NOTIFICATION_FILTERS = {
  patch: [ 
    'alarm_push',
    'alarm_email',
    'message_push',
    'message_email'
  ]
}
