import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CoreLineBreakPipe'
})

@Injectable({
  providedIn: 'root'
})
export class CoreLineBreakPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    console.log('Original Value:', value);

    // Gestisci sia \n che \r\n
    const transformedValue = value.replace(/(\r\n|\n|\r)/g, '<br/>');
    console.log('Transformed Value:', transformedValue);
    return transformedValue;
  }
}
