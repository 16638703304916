<div class="chart" style="display: block;">
    <div class="chart-heading" *ngIf="title">
      {{title | translate }}
    </div>
    <div class="chart-body">
      <ngx-gauge [type]="type"
                 [value]="value"
                 [label]="label"
                 [max]="max"
                 [append]="append"
                 [thick]="thick"
                 [foregroundColor]="hex"
                 >
      </ngx-gauge>
    </div>
  </div>
