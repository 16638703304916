import { ApplicationRef, NgModuleRef } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { Environment } from './model';

export const environment: Environment = {
  production: false,

  showDevModule: true,

  /** Angular debug tools in the dev console
   * https://github.com/angular/angular/blob/86405345b781a9dc2438c0fbe3e9409245647019/TOOLS_JS.md
   * @param modRef
   * @return {any}
   */
  decorateModuleRef(modRef: NgModuleRef<any>) {
    const appRef = modRef.injector.get(ApplicationRef);
    const cmpRef = appRef.components[0];

    let _ng = (<any>window).ng;
    enableDebugTools(cmpRef);
    (<any>window).ng.probe = _ng.probe;
    (<any>window).ng.coreTokens = _ng.coreTokens;
    return modRef;
  },
  ENV_PROVIDERS: [],
  APP_CONFIG: {
    firebase: {
      apiKey: "",
      authDomain: "XXXXXX.firebaseapp.com",
      databaseURL: "https://XXXXXX.firebaseio.com",
      projectId: "XXXXXX",
      storageBucket: "XXXXXX.appspot.com",
      messagingSenderId: "000000000000"
    },
    domain: false,
    domainRedirect: "",
    basePath: "main/dashboard",
    endpointDevice: "https://thing.sc365.enetec.info/v1/",
    endpointLogin: "https://app.sc365.enetec.info/v1/",
    endpointAcl: "https://acl.sc365.enetec.info/v1/",
    endpointTelemetry: "https://telemetry.sc365.enetec.info/v1/",
    endpointIdentity: "https://user.sc365.enetec.info/v1/",
    endpoint: "https://crm.sc365.enetec.info/v1/",
    endpointV2: "https://crm.sc365.enetec.info/v2/",
    endpointNewsletter: "https://newsletter.sc365.enetec.info/v1/",
    endpointNotification: "https://m0w40jkr8d.execute-api.eu-central-1.amazonaws.com/Stage/",
    uploadLink: "https://upload.mysmart365.app/",
    gMapsKey: "AIzaSyBt01ML0nLxltFi0jIgLyFBIIBmjkZNFwo",
    colors: {
      info: "#17A2B8",
      danger: "#DC3545",
      warning: "#FFC107",
      success: "#28A745",
      primary: "#007BFF",
      dark: "#1E1E20",
      light: "#fff",
      shade: 30
    },
    pipe: {
      currencyCode: "€",
      currencySymbol: "code"
    },
    oem_id: 1,
    labels: {
     roles:[
        {
           id:"ROLE_USER",
           label:"roles.ROLE_USER"
        },
        {
           id:"ROLE_ADMIN",
           label:"roles.ROLE_ADMIN"
        },
        {
           id:"ROLE_PARENT",
           label:"roles.ROLE_PARENT"
        },
        {
           id:"ROLE_MANAGEMENT",
           label:"roles.ROLE_MANAGEMENT"
        },
        {
           id:"ROLE_SUPPORT",
           label:"roles.ROLE_SUPPORT"
        },
        {
           id:"ROLE_ADMIN_SUPPORT",
           label:"roles.ROLE_ADMIN_SUPPORT"
        },
        {
           id:"ROLE_OEM",
           label:"roles.ROLE_OEM"
        }
     ],
     contentStatus:[
        {
           id:"DRAFT",
           label:"contentStatus.DRAFT"
        },
        {
           id:"PUBLISHED",
           label:"contentStatus.PUBLISHED"
        }
     ],
     communicationStatus:[
        {
           id:"DRAFT",
           label:"communicationStatus.DRAFT"
        },
        {
           id:"PUBLISHED",
           label:"communicationStatus.PUBLISHED"
        }
     ],
     eventType:[
        {
           id:"ALARM",
           label:"eventType.ALARM"
        },
        {
           id:"MESSAGE",
           label:"eventType.MESSAGE"
        }
     ],
     taskType:[
        {
           id:"LOCAL",
           label:"taskType.LOCAL"
        },
        {
           id:"REMOTE",
           label:"taskType.REMOTE"
        }
     ],
     contentTag:[
        {
           id:"App web & mobile",
           label:"contentTag.App web & mobile"
        },
        {
           id:"Dispositivi e sensori",
           label:"contentTag.Dispositivi e sensori"
        },
        {
           id:"Installazione",
           label:"contentTag.Installazione"
        },
        {
           id:"Supporto",
           label:"contentTag.Supporto"
        },
        {
           id:"Comandi vocali",
           label:"contentTag.Comandi vocali"
        }
     ],
     contentType:[
        {
           id:"FAQ",
           label:"contentType.FAQ"
        },
        {
           id:"DOCUMENT",
           label:"contentType.DOCUMENT"
        }
     ],
     ticketArea:[
        {
           id:"GENERIC",
           label:"ticketArea.GENERIC"
        },
        {
           id:"TECH",
           label:"ticketArea.TECH"
        },
        {
           id:"APP",
           label:"ticketArea.APP"
        },
        {
           id:"BUSINESS",
           label:"ticketArea.BUSINESS"
        }
     ],
     ticketStatus:[
        {
           id:"NEW",
           label:"ticketStatus.NEW"
        },
        {
           id:"REJECTED",
           label:"ticketStatus.REJECTED"
        },
        {
           id:"IN_CHARGE",
           label:"ticketStatus.IN_CHARGE"
        },
        {
           id:"INFO_NEEDED",
           label:"ticketStatus.INFO_NEEDED"
        },
        {
           id:"SOLVED",
           label:"ticketStatus.SOLVED"
        }
     ],
     ticketPriority:[
        {
           id:"LOW",
           label:"ticketPriority.LOW"
        },
        {
           id:"MEDIUM",
           label:"ticketPriority.MEDIUM"
        },
        {
           id:"HIGH",
           label:"ticketPriority.HIGH"
        }
     ],
     langs:[
        {
           id:"it",
           label:"langs.it"
        },
        {
           id:"de",
           label:"langs.de"
        },
        {
           id:"en",
           label:"langs.en"
        },
        {
           id:"es",
           label:"langs.es"
        }
     ],
      telemetryUpdateFrequency: [
        {
          id: null,
          label: "user.telemetryNoUpdate"
        },
        {
          id: "daily",
          label: "user.telemetryDailyUpdate"
        },
        {
          id: "weekly",
          label: "user.telemetryWeeklyUpdate"
        },
        {
          id: "monthly",
          label: "user.telemetryMonthlyUpdate"
        }
      ],
      newsletterType:[
         {
            id:"EMAIL",
            label:"newsletter.EMAIL"
         },
         {
            id:"PUSH",
            label:"newsletter.PUSH"
         },
      ],
      entityStatus: [
        {
          id: 'ACTIVE',
          label: 'COMMON.active'
        },
        {
          id: 'SUSPENDED',
          label: 'COMMON.suspended'
        },
        {
          id: 'PENDING',
          label: 'COMMON.pending'
        },
        {
          id: 'DELETED',
          label: 'COMMON.deleted'
        }
      ],
      userStatus: [
        {
          id: 'ACTIVE',
          label: 'COMMON.active'
        },
        {
          id: 'SUSPENDED',
          label: 'COMMON.suspended'
        },
        {
          id: 'WAITING',
          label: 'COMMON.pending'
        }
      ]
    },
    statistics: {
      bucketEndpoint: "https://enetec-upload-api-bucketenetectelemetry-ym6wq6n1nj82.s3.eu-central-1.amazonaws.com"
    }
  },
  LAYOUT_CONFIG: {
    breakpoints: [
      '(orientation: portrait)', // portrait
      '(orientation: landscape)', // landscape
      '(max-width: 599.99px)', // xSmall
      '(min-width: 600px) and (max-width: 959.99px)', // small
      '(min-width: 960px) and (max-width: 1279.99px)', // medium
      '(min-width: 1280px) and (max-width: 1919.99px)', // large
      '(min-width: 1920px)' // xLarge
    ],
    sideViewWidth: '30%',
    componentModalWidth: '62%',
    componentModalHeight: '80%'
  }
};
