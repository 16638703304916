import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreHttpModelParser, CoreUtilsService, CoreStorageService, CustomHttpParams } from '../core';
import { Notification, NOTIFICATION_FILTERS } from '.';
import { environment } from 'environments/environment';

@Injectable()
export class NotificationService extends CoreHttpModelParser {

  constructor(
    public http: HttpClient,
    public utils: CoreUtilsService,
    public storage: CoreStorageService
  ) { super(); }

  entityName = 'user';

  public get(): Promise<Notification> {
    return new Promise((resolve, reject) => {
      this.http.get(
        `${environment.APP_CONFIG.endpointNotification}${this.entityName}/pushtoken`,
        {
          observe: 'response',
          params: new CustomHttpParams({
            'forceAuthentication': true
          })
        }
      ).toPromise().then(res => {
        resolve(this.getModels(res, Notification)[0]);
      }, err => reject(err));
    });
  }

  public patch(data: any): Promise<Notification> {
    return new Promise((resolve, reject) => {
        this.http.patch(
          `${environment.APP_CONFIG.endpointNotification}${this.entityName}/preference`,
          this.utils.pick(NOTIFICATION_FILTERS['patch'], data),
          {
            observe: 'response',
            params: new CustomHttpParams({
              'forceAuthentication': true
            })
          }
        ).toPromise().then(res => {
          resolve(this.getModels(res, Notification)[0]);
        }, err => reject(err));
    });
  }

}
