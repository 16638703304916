import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, Event } from '@angular/router';
import { environment } from 'environments/environment';
import { CoreStorageService } from '../core';
import { UserService }     from './';

@Component({
  selector: 'user-redirect',
  template: '',
})
export class UserRedirectComponent implements OnInit {
  constructor(
    // private service: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public storage: CoreStorageService,

    private service: UserService
  ) {}

  logout() : void {
    this.service.logout();

  }

  getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
  }

  ngOnInit(): void {
    var token = this.getCookie("token");
    var url = this.getCookie("url");
    if(token && url) {
      this.storage.store("identity", {access_token: token});
      setTimeout(() => {
        this.service.getMe().then((data) => {
          console.log("AAA", data)
          this.storage.store("identity", {user: data, access_token: token, role: data.role});
          this.service.loadData(data.role).then((data2) => {
            document.cookie = "token=-;path=/;domain=" + environment.APP_CONFIG.domain +";expires=Thu, 01 Jan 1970 00:00:01 GMT";
            document.cookie = "url=-;path=/;domain=" + environment.APP_CONFIG.domain+";expires=Thu, 01 Jan 1970 00:00:01 GMT";
            this.router.navigateByUrl(url);

            //
          });

        })

      }, 250)

      }
      console.log("REDIRECT:" + url +" " +token);
    }

}
