import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreHttpModelParser, CoreUtilsService } from '../core';
import 'rxjs/add/operator/map';
import { Newsletter, NEWSLETTER_FILTERS } from './';
import { environment } from 'environments/environment';

const endpointNewsletter = environment.APP_CONFIG.endpointNewsletter

@Injectable({
  providedIn: 'root'
})
export class NewsletterService extends CoreHttpModelParser {

  constructor(
    public http: HttpClient,
    public utils: CoreUtilsService
  ) {
    super();
  }

  entityName = 'newsletter';

  public get(id: string, params?: {}, query?: {}): any {
    params = {};
    return new Promise((resolve, reject) => {
      if (id === '') {
        id = "";
      } else {
        id = "/" + id;
      }
      if (params && params['id']) {
        delete params['id'];
      }
      this.http.get(endpointNewsletter + this.entityName + id, {
        params: Object.assign(this.objToQuery(params), this.objToQuery(query)),
        observe: 'response'
      }).toPromise().then(res => {
        resolve(this.parse(res, Newsletter));
      }, err => reject(err));
    });
  }

  public post(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(endpointNewsletter + this.entityName, this.utils.pick(NEWSLETTER_FILTERS['post'], data), { observe: 'response' }).toPromise().then(res => {
        console.log(JSON.stringify(res));
        resolve(this.parse(res, Newsletter));
      }, err => reject(err));
    });
  }

  public patch(id: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.patch(endpointNewsletter + this.entityName + '/' + id, this.utils.pick(NEWSLETTER_FILTERS['patch'], data), { observe: 'response' }).toPromise().then(res => {
        resolve(this.parse(res, Newsletter));
      }, err => reject(err));
    });
    // return this.http.patch(APP_CONFIG.endpointNewsletter + this.entityName + '/' + id, this.utils.pick(NEWSLETTER_FILTERS['patch'], data), {observe: 'response'}).toPromise();
  }

  public delete(id: string): Promise<any> {
    return this.http.delete(endpointNewsletter + this.entityName + '/' + id, { observe: 'response' }).toPromise();
  }

  test(id: string, email: string, oem_id: number = 1): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ endpointNewsletter }${ this.entityName }/${ id }/test`,
          {
            email: email,
            oem_id: oem_id
          },
          {
            observe: 'response'
          }
        )
        .toPromise()
        .then(
          (res: any) => {
            resolve(this.getModels(res, Newsletter)[0]);
          },
          (err: any) => reject(err)
        );
    });
  }

  plan(id: string, sendAt: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ endpointNewsletter }${ this.entityName }/${ id }/plan`,
          {
            send_at: sendAt
          },
          {
            observe: 'response'
          }
        )
        .toPromise()
        .then(
          (res: any) => {
            resolve(this.getModels(res, Newsletter)[0]);
          },
          (err: any) => reject(err)
        );
    });
  }

  send(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ endpointNewsletter }${ this.entityName }/${ id }/send`,
          {},
          {
            observe: 'response'
          }
        )
        .toPromise()
        .then(
          (res: any) => {
            resolve(this.getModels(res, Newsletter)[0]);
          },
          (err: any) => reject(err)
        );
    });
  }

}
