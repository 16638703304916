/**
 * Angular 2 decorators and services
 */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'environments/environment';
// import { AppState } from './app.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router'

import { CoreMessageService, CoreStorageService, CoreUtilsService } from './core';

import { UserService } from './user'

/**
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './app.component.css'
  ],
  templateUrl: './app.component.html'

})
export class AppComponent implements OnInit {
  // public name = 'Angular Starter';
  // public tipe = 'assets/img/tipe.png';
  // public twitter = 'https://twitter.com/gdi2290';
  // public url = 'https://tipe.io';
  public showDevModule: boolean = environment.showDevModule;
  public menuOpened = false;


  public angularclassLogo = 'assets/img/logo.png';
  public name = 'TEST';
  public url = 'https://corley.it/';
  public routeData: any = {};
  public section: string = '';
  public title: string = '';
  public layout: 'web' | 'mobile';

  constructor(
    // public appState: AppState,
    public storage: CoreStorageService,
    private router: Router,
    private titleService: Title,
    private breakpointObserver: BreakpointObserver,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private message: CoreMessageService,
    private utils: CoreUtilsService,
    public translate: TranslateService
  ) {
    this.translate.setDefaultLang('it');
    this.translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      // this.translate.get('a-loca-string').subscribe(
      //   data => {}
      // )
    });
    this.translate.use(this.translate.getBrowserLang() || 'it');
    this.message.get("language.changed").subscribe((m) => {
      if (m) {
        this.translate.use(m)
        console.log("lang", this.identity['user']['language'])
        if (this.identity['user'] && this.identity['user']['language'] != m) {

          this.userService.patch(this.identity['user']['id'], { language: m })
          this.identity['user']['language'] = m;
          this.storage.store('identity', this.identity);
        }
      }
    })

    // Layout breakpoints
    this.breakpointObserver
      .observe(environment.LAYOUT_CONFIG.breakpoints)
      .subscribe(result => {
        const {
          isPortrait,
          isLandscape,
          isXSmall,
          isSmall,
          isMedium,
          isLarge,
          isXLarge
        } = this.utils.breakpointsAnalizer(result);
        if (isXSmall || isSmall || isPortrait) {
          this.changeLayout('mobile');
        } else {
          this.changeLayout('web');
        }
      });
  }


  identity;
  activeIdentity: any = {};


  public ngOnInit() {
    this.identity = this.storage.retrieve('identity') || { role: 'ROLE_GUEST' };
    // this.message.currentMessage.subscribe(m => this.closed = m)
    this.message.init("menu.opened", false);
    this.message.get("menu.opened").subscribe(m => this.menuOpened = m)
    // this.appState.set("identity", this.storage.retrieve("identity") || {});
    // console.log('Initial App State', this.appState.state);


    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.message.change("menu.opened", false);// chiudo il menu al cambio di rotta
      }
      if (e instanceof NavigationEnd) {
        this.routeData = this.activatedRoute.root.firstChild.snapshot.data || {};
        this.section = this.activatedRoute.root.firstChild.snapshot.queryParams['_section'] || this.routeData['section'] || '';
        this.title = this.activatedRoute.root.firstChild.snapshot.queryParams['_title'] || this.routeData['title'] || '';

        //change page title (based on section or _title parameter)
        var title = this.title || this.section || this.routeData['title'] || "common.title";
        this.translate.get(title).subscribe(newTitle => {
          this.titleService.setTitle(newTitle);
        });
        //   data => {}
        // )

        if (this.activatedRoute.root.firstChild.snapshot.routeConfig.path) {
          this.checkAcl((this.identity || {})['role'], this.routeData['acl'])
        } else {
          this.router.navigate([environment.APP_CONFIG.basePath]);
        }
      }
    });


    this.storage.observe('identity')
      .subscribe((newIdentity) => {
        newIdentity = newIdentity || {};
        this.identity = newIdentity;
        this.checkAcl(newIdentity.role, this.routeData['acl'])
      })

  }

  checkAcl(role: any, routeAcl: any) {
    console.log("CHECK", role, routeAcl)
    if (routeAcl === false || routeAcl === null || routeAcl === undefined) {
      return;
    }
    if (!role || role === undefined) {
      this.router.navigate(['/user/login']).then(() => {/*location.reload();*/
      });
    } else {
      if (!(
        routeAcl === false || routeAcl === null || routeAcl === undefined  // public route
        || (role != 'ROLE_GUEST' && routeAcl === "*") //user authorized and route open to all logged users
        || (role && routeAcl instanceof Array && routeAcl.indexOf(role) != -1) // role in the list of admitted roles
      )) {
        const currentUrl = this.router.url;

        if (this.isBringBackToPageAfterLoginNeeded(currentUrl)) {
          const redirectHash = btoa(currentUrl);
          this.router.navigate(['/user/login'], { queryParams: { r: redirectHash } });
        } else {
          this.router.navigate(['/user/login']);
        }
      }
    }
  }

  private isBringBackToPageAfterLoginNeeded(currentUrl: string): boolean {
    const bringBackPagesArray = ['telemetry/view'];

    return !!bringBackPagesArray.find(item => currentUrl.includes(item));
  }

  changeLayout(mode) {
    // this.logger.log('mode ' + mode);
    if (mode == 'mobile') {
      this.layout = 'mobile';
      this.message.change('layout.changed', this.layout);
    } else if (mode == 'web') {
      this.layout = 'web';
      this.message.change('layout.changed', this.layout);
    }
  }

}

/**
 * Please review the https://github.com/AngularClass/angular-examples/ repo for
 * more angular app examples that you may copy/paste
 * (The examples may not be updated as quickly. Please open an issue on github for us to update it)
 * For help or questions please contact us at @AngularClass on twitter
 * or our chat on Slack at https://AngularClass.com/slack-join
 */
