import { CoreHttpModelBase } from './../core';

export class User extends CoreHttpModelBase {
    id: string
    firstname: string
    lastname: string
    email: string
    role: string
    status: string
    secondaryEmail: string
    phoneNumber: string
    mobileNumber: string
    country: string
    language: string
    organization: number
    telemetryMailReception: 'daily' | 'weekly' | 'monthly'
    loadings: any = {}
}

export const USER_FILTERS = {
  post: [ "firstname", "lastname", "username", "password", "role","organization", "secondaryEmail", "phoneNumber", "mobileNumber", "country", "language", "terms", "oem", "telemetryMailReception"],
  patch: [ "firstname", "lastname", "username", "password", "role", "organization", "secondaryEmail", "phoneNumber", "mobileNumber", "country", "language", "terms", "oem", "telemetryMailReception"]
}
