import { Component, Input, OnInit } from '@angular/core';
import { CoreChartService } from '../';
import 'chartjs-plugin-datalabels'
import { environment } from 'environments/environment';

@Component({
  selector: 'core-chart-base',
  templateUrl: './chart-base.component.html'
})
export class CoreChartBaseComponent implements OnInit  {

  constructor(
      private service: CoreChartService,
  ) {}

  @Input() title: string;
  @Input() colors : string[] = ['info', 'danger', 'warning', 'success', 'primary']; // array of colors ['info', 'warning']
  @Input() values: any[] = []; // array of values {data: [10,11], label: 'Test'}
  @Input() min : any = false; // min value on axis (if false, auto-calc)
  @Input() max : any = false; // max value on axis (if fals, auto-calc)
  @Input() shade: number = environment.APP_CONFIG.colors['shade']
  @Input() type: 'bar'|'line'|'pie'|'radar'|'doughnut' = 'line';
  @Input() legend : boolean = true;
  @Input() labels : string[] = [];
  @Input() height : string = "";
  @Input() width : string = "";

  hexs: string[] = [];
  done = false;
  total : any[] = [];

  ngOnInit() {
    //get colors
    for(var i = 0; i < this.colors.length; i++) {
      this.hexs.push(this.service.getColor(this.colors[i]));
    }

    //apply colors and calculates min and max values
    this.baseChartData = this.values;

    for(var k = 0; k < this.baseChartData.length; k ++) {
      this.hexs.push(this.service.getColor(this.colors[i]));
      if(this.type != 'pie' && this.type != 'doughnut') {
        this.baseChartColors.push({ // min
            backgroundColor: this.service.getShadedColor(this.hexs[k], this.shade),
            borderColor: this.hexs[k],
            fontColor: this.hexs[k],
            pointBackgroundColor: this.hexs[k],
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: this.hexs[k]
          });
          this.total = this.total.concat(this.baseChartData[k]['data']);
        }
        else {
            this.baseChartColors = [{backgroundColor: this.hexs}];
        }
    }

    this.baseChartOptions = {
      legend: {
         labels: {
            fontColor: "black",
         }
      },
  		plugins: {
  			datalabels: {
  				backgroundColor: function(context) {
  					return "transparent";
  				},
          anchor:'end',
          align: 'top',
  				borderRadius: 50,
  				color: 'black',
  				font: {
  					weight: 'bold'
  				},
  				formatter: Math.round
  			}
  		},
      responsive: true,
      scales: {
        yAxes: [{
          display:false,
          gridLines: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            fontColor: "#fff", // this here
            min: this.getMin(),
            beginAtZero: true,
            max: this.getMax()
          },
        }]
      },
      xAxes: [{
          gridLines: {
              fontColor: "#ffffff", // this here
              color: "white", // this here
          },
          ticks: {
            fontColor: "#ffffff", // this here
          },
      }]
    };

    if(this.values.length > 1 ) {
      for(var i = 0; i< this.values.length; i++) {
          this.baseChartOptions.scales.yAxes[i] = {
            display:false,
  					id: this.values[i].yAxisID,
            gridLines: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              fontColor: "#fff", // this here
              min: this.getMin(),
              beginAtZero: true,
              max: this.getMax()
            },
          }
      }
    }

    if(this.type == 'pie') {
      console.log("PIE", this.baseChartColors, this.baseChartData, this.labels);
    }


  }

  getMin() {
    if(this.min === false) {
      return Math.min.apply(Math, this.total) - Math.min.apply(Math, this.total)/10
    }
    else return this.min;
  }
  getMax() {
    if(this.max === false) {
      return Math.max.apply(Math, this.total) + Math.max.apply(Math, this.total)/4
    }
    else return this.max;
  }


  // max: Math.max.apply(Math, this.maxs)+Math.max.apply(Math, this.maxs)/8,
  // min: Math.min.apply(Math, this.mins)-Math.min.apply(Math, this.mins)/10

  public baseChartData:Array<any> = [];
  public baseChartOptions:any = {}
  public baseChartColors:Array<any> = []


}
