import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { environment } from 'environments/environment';
@Pipe({ name: 'CoreCurrencyPipe' })
export class CoreCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) { }
  transform(value: any): string {
       if (value != null) {
          return this.currencyPipe.transform(value, environment.APP_CONFIG.pipe.currencyCode, environment.APP_CONFIG.pipe.currencySymbol);
      }
      return this.currencyPipe.transform(0, environment.APP_CONFIG.pipe.currencyCode, environment.APP_CONFIG.pipe.currencySymbol).split('0.00')[0];
   }
}
