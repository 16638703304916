import { Component, Input,ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CoreMessageService } from '../message/message.service';

// require("moment/min/locales.min");

interface Data {
  month: number;
  year: number;
}
interface Idata {
  label?: string,
  start?: Date,
  end?: Date,
}
@Component({
  selector: 'core-month-date-picker',
  templateUrl: './month-date-picker.component.html',
  styleUrls: ['./month-date-picker.component.css'],
})
export class CoreMonthDatePickerComponent {

  dataTxt: string;
  separator: string;
  monthFirst: boolean;
  place: number;

  isYear: boolean = false;
  displayYear: boolean = false;
  incr: number = 2015;
  months: string[] = ['jan', 'feb', 'mar', 'apr', 'mayShort', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec'];
  data: Data;
  lastAvailableMonth: number;
  lastAvailableYear: number;

  @Input() model: Idata;
  @Input() disabled = false;
  @Input() mode: 'year' | 'month' = 'month'
  @Input() format = 'year' === this.mode ? 'YYYY' : 'MM-YYYY';
  @Input() placeholder = 'year' === this.mode ? 'yyyy' : 'mm-yyyy';
  @Input() isComparison: boolean = false;
  @Input() locale: string;


  @ViewChild('calendarPanel') calendarPanel: NgbDropdown;

  constructor(
    private messageService: CoreMessageService
  ) {
    this.separator = this.placeholder.replace(/m|y|M/gi, "");
    this.monthFirst = this.placeholder.indexOf('y') > 0;
    this.place = this.placeholder.indexOf(this.separator);
  }

  ngOnInit() {
    moment.locale(this.locale || 'en');

    if ('year' === this.mode) {
      this.isYear = true;
    } else {
      this.isYear = false;
    }

    // questo ne assicura la retrocompatibilità nel caso in cui non arrivi l'oggetto esterno,
    // ma la data da inizializzare è quella che arriva dal padre
    this.lastAvailableMonth = (new Date()).getMonth()+1;
    this.lastAvailableYear = (new Date()).getFullYear()
    if (!((this.model || {}).start)) {
      this.data = {
        month: this.lastAvailableMonth-1,
        year: 'year' === this.mode ? this.lastAvailableYear-1 : this.lastAvailableYear
      };

      if (!this.isComparison) {
        let date = moment({
          day: 1,
          month: this.data.month - 1,
          year: this.data.year
        });

        this.dataTxt = date.format(this.format);
      }
    } else { // Questa sarebbe la logica giusta
      this.data = {
        month: this.model.start.getMonth(),
        year: this.model.start.getFullYear(),
      }

      if (!this.isComparison) {
        let date = moment({
          day: 1,
          month: this.data.month,
          year: this.data.year
        });

        this.dataTxt = date.format(this.format);
      }
    }


    this.messageService.get('resetChartComparison').subscribe(
      response => {
        if (!this.isComparison) {
          return;
        }

        this.dataTxt = '';
      }
    );
  }

  removeComparison() {
    this.model = {
      start: null,
      end: null,
      label: null
    };

    this.dataTxt = '';

    this.messageService.change('rangeSelectionComparison', this.model);
  }

  change(value: string) {
    if (this.isYear) {
      this.data.year = parseInt(value);
    } else {
      let date = moment(value, this.format);
      if (date.isValid()) {
        this.data = {
          month: date.month()+1,
          year: date.year()
        }
      }
    }
    this.notifyToIdata(this.data);
  }

  selectYearMonth($event, index: number) {
    if (!this.isYear) {
      if (this.displayYear) {
        $event.stopPropagation();
        this.data.year = index+this.incr;
        this.dataTxt = this.formatData(this.data);
        this.displayYear = false;
        this.incr = this.getIncr(this.data.year);
      } else {
        this.data.month=index;
        this.dataTxt = this.formatData(this.data);
      }
    } else {
      this.data.year = index+this.incr;
      this.dataTxt = this.formatData(this.data);
      this.incr = this.getIncr(this.data.year);
    }
  }

  showYear($event: any) {
    $event.stopPropagation();
    if (!this.isYear) {
      this.displayYear=!this.displayYear;
    }
  }

  addYear($event: any, incr: number) {
    $event.stopPropagation();
    let year = this.displayYear ? this.data.year+10*incr : this.data.year+incr;
    if (year == this.lastAvailableYear && this.data.month > this.lastAvailableMonth) {
      this.data.month = this.lastAvailableMonth-1;
    }
    this.data.year = year;
    this.incr = this.getIncr(year);
    this.dataTxt = this.formatData(this.data);
  }

  getIncr(year: number): number {
    return (year-year%10)-1;
  }

  formatData(data: Data): string {
    let date = moment({
      day: 1,
      month: data.month,
      year: data.year
    });

    this.notifyToIdata(this.data);
    return date.format(this.format)
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  notifyToIdata(data: Data): void {
    let date = moment({
      day: 1,
      month: data.month,
      year: data.year
    });

    this.model = {
      label: date.format(this.format),
      start: date.startOf(this.mode).toDate(),
      end: date.endOf(this.mode).toDate()
    }

    let message = this.isComparison ? 'rangeSelectionComparison' : 'rangeSelection';

    this.messageService.change(message, this.model);
  }

  public isSuggested(index): boolean {
    if (this.isComparison && this.mode === 'month') {
      const suggestedComparisonMonth = new Date().getMonth() - 2;
      const thisYear = new Date().getFullYear();
      return (this.data.year == thisYear) && (index == suggestedComparisonMonth) && !(this.model || {}).start;
    } else if (this.isComparison && this.mode === 'year') {
      const suggestedComparisonYear = new Date().getFullYear() - 2;
      return (this.incr + index == suggestedComparisonYear) && !(this.model || {}).start;
    }
    return false;
  }
}
