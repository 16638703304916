<nav id="mainNavBar" class="navbar-collapse" *ngIf="identity.role == 'ROLE_OEM' || identity.role == 'ROLE_ADMIN'  || identity.role == 'ROLE_SUPPORT'">

  <ul class="nav nav-pills flex-column" >
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'main.dashboard'}" routerLink="/main/dashboard" [queryParams]="{_title: 'layout.menu1'}" >{{ "layout.menu1" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'notification.mine'}" routerLink="/notification/edit" [queryParams]="{_section: 'notification.mine', _title: 'layout.menu21'}"  >{{ "layout.menu21" | translate }}</a>
    </li>
    <li class="nav-item" *ngIf="identity.role == 'ROLE_ADMIN' ">
      <a class="nav-link" [ngClass]="{'active': section == 'organization.list'}" routerLink="/organization/list" [queryParams]="{page: 1,_title: 'layout.menu2'}">{{ "layout.menu2" | translate }}</a>
    </li>
    <li class="nav-item" *ngIf="!identity.role == 'ROLE_SUPPORT'">
      <a class="nav-link" [ngClass]="{'active': section == 'user.list'}" routerLink="/user/list/1" [queryParams]="{_title: 'layout.menu3'}" >{{ "layout.menu3" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'device.new'}" routerLink="/device/list" [queryParams]="{page: 1, _section: 'device.new', _title: 'layout.menu5'}"  >{{ "layout.menu5" | translate }}</a>
    </li>
    <li class="nav-item">
    </li>
  </ul>

  <hr />

  <ul class="nav nav-pills flex-column" *ngIf="identity.role == 'ROLE_ADMIN'">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'contentmaster.faq'}" routerLink="/contentmaster/list" [queryParams]="{page: 1, type: 'FAQ', _title: 'layout.menu6', _section: 'contentmaster.faq'}">{{ "layout.menu6" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'contentmaster.docs'}" routerLink="/contentmaster/list" [queryParams]="{page: 1, type: 'DOCUMENT', _title: 'layout.menu7', _section: 'contentmaster.docs'}">{{ "layout.menu7" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'ticketmaster.list'}" routerLink="/ticketmaster/list" [queryParams]="{page: 1, _title: 'layout.menu8', order:'DESC', orderBy: 'created_at'}">{{ "layout.menu8" | translate }} </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'newsletter.new'}" routerLink="/newsletter/list" [queryParams]="{page: 1, _section: 'newsletter.new', _title: 'layout.menu_newsletter'}"  >{{ "layout.menu_newsletter" | translate }}</a>
    </li>
  </ul>

  <ul class="nav nav-pills flex-column" *ngIf="identity.role == 'ROLE_OEM'">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'content.faq'}" routerLink="/content/list" [queryParams]="{page: 1, type: 'FAQ', _section: 'content.faq', _title: 'layout.menu9'}">{{ "layout.menu9" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'content.document'}" routerLink="/content/list" [queryParams]="{page: 1, type: 'DOCUMENT', _section: 'content.document', _title: 'layout.menu10'}">{{ "layout.menu10" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'communication.list'}" routerLink="/communication/list" [queryParams]="{page: 1, _title: 'layout.menu11'}">{{ "layout.menu11" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'ticket.list'}" routerLink="/ticket/list" [queryParams]="{page: 1, _title: 'layout.menu12', order:'DESC', orderBy: 'created_at'}">{{ 'layout.menu12' | translate }}</a>
    </li>
  </ul>
  <!-- <ul class="nav nav-pills flex-column" *ngIf="identity.role == 'ROLE_SUPPORT'">

    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'ticket.list'}" routerLink="/ticket/list" [queryParams]="{page: 1, _title: 'layout.menu12'}">{{ 'layout.menu12' | translate }}</a>
    </li>
  </ul> -->

  <ul class="nav nav-pills flex-column" *ngIf="identity.role == 'ROLE_OEM'">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'contentmaster.faq'}" routerLink="/contentmaster/list" [queryParams]="{page: 1, type: 'FAQ', _title: 'layout.menu13', _section: 'contentmaster.faq'}">{{ "layout.menu13" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'contentmaster.docs'}" routerLink="/contentmaster/list" [queryParams]="{page: 1, type: 'DOCUMENT', _title: 'layout.menu14', _section: 'contentmaster.docs'}">{{ "layout.menu14" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'ticketmaster.list'}" routerLink="/ticketmaster/list" [queryParams]="{page: 1, _title: 'layout.menu15'}">{{ "layout.menu15" | translate }} </a>
    </li>
  </ul>

  <hr />
  <ul class="nav nav-pills flex-column">
    <li class="nav-item">
      <a class="nav-link"  routerLink="/user/logout">{{ "layout.menu16" | translate }}</a>
    </li>
  </ul>

</nav>


<nav id="mainNavBar" class="navbar-collapse" *ngIf="identity.role == 'ROLE_USER' || identity.role == 'ROLE_PARENT' ">

  <ul class="nav nav-pills flex-column" >

    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'main.dashboard'}" routerLink="/main/dashboard" [queryParams]="{_title: 'layout.menu17'}" >{{ "layout.menu17" | translate }}</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'profile.mine'}" routerLink="/user/edit/{{ identity.user.id}}" [queryParams]="{_section: 'profile.mine', _title: 'layout.menu18'}"  >{{ "layout.menu18" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'notification.mine'}" routerLink="/notification/edit" [queryParams]="{_section: 'notification.mine', _title: 'layout.menu21'}"  >{{ "layout.menu21" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': section == 'device.mine'}" routerLink="/device/list" [queryParams]="{page: 1,  _section: 'device.mine', _title: 'layout.menu19'}"  >{{ "layout.menu19" | translate }}</a>
    </li>
    <li class="nav-item">
    </li>
  </ul>
  <hr />

  <ul class="nav nav-pills flex-column">
    <li class="nav-item">
      <a class="nav-link"  routerLink="/user/logout">{{ "layout.menu20" | translate }}</a>
    </li>
  </ul>
</nav>
