import { Component, ElementRef, Input, ViewChild, EventEmitter, Output} from '@angular/core';
import { HttpClient, HttpEventType, HttpResponse, HttpRequest } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from 'environments/environment';

@Component({
    selector: 'core-upload',
    template: '<input type="file"   #fileInput>  <ngb-progressbar  [hidden]="uploadStatus <= 0" type="success"  [animated]="true" [value]="uploadStatus" [striped]="uploadStatus < 100"></ngb-progressbar> '
})
export class CoreUploadComponent {
    @Input() multiple: boolean = false;
    @Input() service: any;
    @Input() trigger: any;
    @ViewChild('fileInput') inputEl: ElementRef;
    uploadStatus = 0;
    @Input() files: any[];
    @Output() uploaded = new EventEmitter<string>();

    constructor(private http: HttpClient) {}

    upload() {
        let inputEl: HTMLInputElement = this.inputEl.nativeElement;
        let fileCount: number = inputEl.files.length;
        // let formData = new FormData(); //not used
        var reader = new FileReader();
        if (fileCount > 0) { // a file was selected
          // for (let i = 0; i < fileCount; i++) {
          //     formData.append('file[]', inputEl.files.item(i));
          // }
          // console.log("sto per caricare",inputEl.files.item(0));

          //get policy from api to upload file
          var name = inputEl.files.item(0).name + "";
          // var name2 = name.replace(/[`~!@#$%^&*()_|+\-=?;:'" ,<>\{\}\[\]\\\/]/gi, '-');
          var name2 = name.replace(" ", "-").toLowerCase();
          name2 = name2.replace(/[^a-zA-Z0-9-_\.]/g, '').toLowerCase();

          if(this.service && this.service.getUploadSign) {
            this.service.getUploadSign(name2).then(this.toS3);
          }
          else {
            this.toHttp({body: {url: environment.APP_CONFIG.endpoint + 'upload', name: inputEl.files.item(0).name }});
          }
        }
    }


    //upload to S3 with signed policy
    toS3(data) {
        let inputEl: HTMLInputElement = this.inputEl.nativeElement;
        //read buffer from file
        var reader = new FileReader();
        reader.readAsArrayBuffer(inputEl.files.item(0));

        //whn done start upload
        reader.onload = (e) => {
          console.log("CARICANDO", data.body.url);

          const req = new HttpRequest('PUT', data.body.url, e.target['result'], {
            reportProgress: true,
            responseType: 'arraybuffer'
          });
          this.http.request(req).subscribe(event => {
            // Via this API, you get access to the raw event stream.
            // Look for upload progress events.
            if (event.type === HttpEventType.UploadProgress) {
              // This is an upload progress event. Compute and show the % done:
              this.uploadStatus  = Math.round(99 * event.loaded / event.total);

            } else if (event instanceof HttpResponse) {
              this.uploadStatus = 100;
              this.files.push({url:environment.APP_CONFIG.uploadLink + data.body.url.split("?")[0], name:"upload"});
              if(this.trigger) {
                this.uploaded.emit('complete');
              }
            }
          });
        };
    }


    //upload to a classic http endpoint with multipart
    toHttp(data) {
      let inputEl: HTMLInputElement = this.inputEl.nativeElement;
      //read buffer from file
      var reader = new FileReader();
      reader.readAsArrayBuffer(inputEl.files.item(0));

      //whn done start upload
      reader.onload = (e) => {
        //multipart
        const formData = new FormData();
        formData.append('body', inputEl.files.item(0));
        formData.append('name', data.body.name);

        const req = new HttpRequest('POST', data.body.url , formData, {
          reportProgress: true,
          responseType: 'arraybuffer'
        });
        this.http.request(req).subscribe(event => {
          // Via this API, you get access to the raw event stream.
          // Look for upload progress events.
          if (event.type === HttpEventType.UploadProgress) {
            // This is an upload progress event. Compute and show the % done:
            this.uploadStatus  = Math.round(99 * event.loaded / event.total);

          } else if (event instanceof HttpResponse) {
            this.uploadStatus = 100;
            this.files.push({url: environment.APP_CONFIG.uploadLink + data.body.name, name: data.body.name});
            if(this.trigger) {
              this.uploaded.emit('complete');
            }

            // console.log('File is completely uploaded!');
          }
        });
      };

  }
}
