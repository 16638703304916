import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, Event } from '@angular/router';
// import { Observable }        from 'rxjs/Observable';

import { UserService, User } from './';

// import { AppState } from '../app.service';

// import 'rxjs/add/operator/switchMap';


@Component({
  selector: 'user-dashboard',
  templateUrl: './user-dashboard.component.html',
  // providers: [
  //   UserService
  // ]
})


export class UserDashboardComponent implements OnInit {

  constructor(private service: UserService) {}

  dashboard: any = {};
  loaded: boolean = false;

  ngOnInit() {
    this.service.getDashboard().then((data) => {
      setTimeout(() => {
        this.dashboard = data.body;
        this.loaded = true;
      }, 250)
    });
  }


}
