import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, Event } from '@angular/router';
import { Observable }        from 'rxjs/Observable';
import { Observer }        from 'rxjs/Observer';
import { LocalStorage } from 'ngx-webstorage';
import { NgProgress } from '@ngx-progressbar/core';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ContentmasterService, Contentmaster } from './';

import { CoreStorageService, CoreModalService, CoreMessageService, CoreUtilsService } from '../core'

@Component({
  selector: 'contentmaster-list',
  templateUrl: './contentmaster-list.component.html',
})


export class ContentmasterListComponent implements OnInit {

  constructor(
    private service: ContentmasterService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: CoreStorageService,
    private progress: NgProgress,
    private modal: CoreModalService,
    private message: CoreMessageService,
    private utils: CoreUtilsService,

  ) { }

  @Input() list : Contentmaster[] = []; // list of entites (by remote service or by parent in inline mode)
  @Input() queryIn: any; // query params (for remote service in inline mode)
  @Input() objIn: any = {}; // object passed to child edit component
  @Input() mode = ""; // 'inline' when used by parent
  @Input() modeSelect = false; // true when the inline mode is used to select element
  @Input() forceForm = false; // used to show the search form (usually in inline views)
  pageInfo: any = {}; // pagination
  params: any = {};
  query: any = {degree_id: null, brand_id: null};
  page: 0;
  newInlineChild: boolean = false; // controls to show inline child edit view
  newInlineChildId: number = 0;

  loaded: boolean = false;
  loading: Promise<any>
  data: any = {}; // data from core storage

  acl: "view" | "edit" = "edit";
  identity: any = {}



  ngOnInit(): void {


    this.identity = this.storage.retrieve('identity') || {};
    if(this.identity && this.identity['role'] != 'ROLE_ADMIN') {
      this.acl = 'view';
    }

    // query by parent component
    if(this.queryIn) {
      this.query = this.queryIn;
      this.search();
    }
    // watch queris in normal mode
    else if(this.mode != "inline"){
      this.route.queryParams.subscribe( queryParams => {
        this.query = Object.assign(this.query, queryParams);
        this.page = queryParams.page || 1;
        this.search();
      });
    }
    else {
      this.loaded = true;
    }

    //events to interact with child and parent
    this.message.get("contentmaster.new").subscribe(m => { this.newInlineChild = false; this.list.push(m); })
    this.message.get("contentmaster.changed").subscribe(m => { this.newInlineChild = false; this.list = this.utils.replace(this.list, m) })

    // retrieve data for storage
    this.data = this.storage.retrieve("data");

  }

  // uses service to get remote entitites
  search(): void {
    this.progress.start();
    this.loading = this.service.get(false, this.params, this.query).then(res => {
      this.list = res.models;
      setTimeout(() => {
        this.pageInfo = res.pagination;
        this.loaded = true;
        this.progress.complete();
      }, 250)
    }, err => {this.loaded = true; this.progress.complete();});
  }

  // merge params and navigate to new path (or call "search" method if in inline mode)
  navigate(page): void {
    if(page) {
      this.progress.start();
      this.params['page'] = page;
      this.query['page'] = page;
      var path = this.route.routeConfig.path;
      var params = this.service.objToQuery(this.params);
      for(var k in params) {
        path = path.replace(":" + k, params[k]);
      }
      // go to path o call seach in inline mode
      if(this.mode == 'inline') {
        this.search();
      }
      else {
        this.router.navigate([path], {queryParams: this.query, replaceUrl: true });
      }
    }
  }

  // go to new element route or opena a mask for inline adds
  add(open?: boolean) {
    if(this.mode == 'inline') {
      this.newInlineChildId = 0;
      this.newInlineChild = open
    }
    else {
      this.router.navigate(['/contentmaster/edit/0'], {queryParams: {type: this.query['type']}});
    }
  }

  // go to edit form for element or open a mask for inline editing
  edit(contentmaster: Contentmaster):void {
    if(this.mode == 'inline') {
      this.newInlineChildId = contentmaster.id;
      this.newInlineChild = true;
    }
    else {
      this.router.navigate(['contentmaster/edit/' + contentmaster.id]);
    }
  }

  // notify selection in inline mode
  select(contentmaster: Contentmaster):void {
    this.message.change('contentmaster.selected', contentmaster);
  }

  // modal confirmation
  delete(contentmaster, index) {
    this.modal.confirm().then((confirmed) => {
      if(confirmed) {
        contentmaster.loadings['delete'] = true;
        setTimeout(() => {
          this.service.delete(contentmaster.id).then(() => {
            contentmaster.loadings['delete'] = false;
            this.list.splice(index, 1);
          }, function() {
            contentmaster.loadings['delete'] = false;
          });
        }, 500);
      }
    }, (dismiss) => { });
  }



}
