// import { Bootstrap }    from '@angular/platform-browser-dynamic';
import {Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

// bootstrap(AppModalService);
@Injectable(
)
export class CoreHttpInterceptorPaginator implements HttpInterceptor {

  constructor() {}

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).do((event: HttpEvent<any>) => {
       if (event instanceof HttpResponse) {
         // console.log("PAGINATOR", event);
        //  event.paginator = {};

       }
     }, (err: any) => {

     });
  }
}
